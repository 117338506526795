import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, FormHelperText, InputLabel, OutlinedInput, Typography} from '@mui/material'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {DetailsDataWrapper} from '@components/Publication/Form/PublicationForm/PublicationFormOverviewBlocks/PublicationFormDetails/PublicationFormDetails.styles'
import KeyWords from '@components/TextEditor/KeyWords/KeyWords'
import RegionsSelect from '@components/Publication/Form/Selectors/RegionsSelect/RegionsSelect'
import {Region} from '@graphql/types'
import {DatePickerWrapper} from '@components/Publication/Form/PublicationForm/PublicationFormOverviewBlocks/PublicationFormDetails/PublicationActionButton/PublicationDate/PublicationDate.styles'
import {StyledFormControl} from '../styled'
import {TitleLength} from '../ImageEditor/AnnounceImageModal/AnnounceImageModal.styles'
import {redColor} from '../../../@theme/vars'
import {BreakingNewsInputType, BreakingNewsModalFieldsProps} from './BreakingNewsModalFieldsProps'
import {BREAKING_NEWS_MODAL_FIELDS_CONSTS} from './BreakingNewsModalFields.consts'

const BreakingNewsModalFields: FC<BreakingNewsModalFieldsProps> = ({
  setDisabledFormState,
  setBreakingNewsModalFieldValues,
  breakingNewsModalFieldValues
}) => {
  const [timeRest, setTimeRest] = useState(0)
  const [hourForm, setHourForm] = useState(BREAKING_NEWS_MODAL_FIELDS_CONSTS.hourForms[0])
  const defaultValues = useMemo<BreakingNewsInputType>(
    () => ({
      text: breakingNewsModalFieldValues?.text || '',
      keywords: breakingNewsModalFieldValues?.keywords || '',
      regionUids: breakingNewsModalFieldValues?.regionUids || [],
      publishedAt: breakingNewsModalFieldValues?.publishedAt
    }),
    [breakingNewsModalFieldValues]
  )

  const [text, setText] = useState(defaultValues?.text || '')
  const [textDirty, setTextDirty] = useState(false)

  const {getValues, watch, control, setValue, handleSubmit, reset, ...methodsForm} = useForm({
    defaultValues: defaultValues
  })

  const fields = useWatch({control})

  const setRegions = useCallback(
    (regions: Region[]) => {
      const defaultRegion = regions?.find(value => value.title === 'Все регионы')
      setValue('regionUids', defaultRegion ? [defaultRegion?.uid] : [])
    },
    [setValue]
  )

  const [isEditDate, setIsEditDate] = useState(!!fields.publishedAt)
  const [dateTime, setDateTime] = useState<any>(new Date())

  const [defaultWords, setDefaultWords] = useState(BREAKING_NEWS_MODAL_FIELDS_CONSTS.keyWords)
  const [textError, setTextError] = useState('')

  useEffect(() => {
    if (text && text?.length > BREAKING_NEWS_MODAL_FIELDS_CONSTS.maxPublicationLength) {
      setTextError(BREAKING_NEWS_MODAL_FIELDS_CONSTS.characterLimitReachedMsg)
    } else {
      setTextError('')
    }
    if (!text && textDirty) {
      setTextError(BREAKING_NEWS_MODAL_FIELDS_CONSTS.fillRequiredFields)
    }
  }, [text, textDirty, setTextError])

  const getHourForm = useCallback(hour => {
    hour = Math.abs(hour) % 100
    const hour1 = hour % 10
    if (hour > 10 && hour < 20) {
      return setHourForm(BREAKING_NEWS_MODAL_FIELDS_CONSTS.hourForms[2])
    }
    if (hour1 > 1 && hour1 < 5) {
      return setHourForm(BREAKING_NEWS_MODAL_FIELDS_CONSTS.hourForms[1])
    }
    if (hour1 == 1) {
      return setHourForm(BREAKING_NEWS_MODAL_FIELDS_CONSTS.hourForms[0])
    }
    return setHourForm(BREAKING_NEWS_MODAL_FIELDS_CONSTS.hourForms[2])
  }, [])

  useEffect(() => {
    const newTimeRest = Math.ceil((+new Date(dateTime) - +new Date()) / (1000 * 60 * 60))

    if (new Date(dateTime) >= new Date()) {
      setTimeRest(newTimeRest)
      getHourForm(newTimeRest)
    }
    const interval = setInterval(() => {
      if (new Date(dateTime) >= new Date()) {
        getHourForm(newTimeRest)
      }
      setDateTime(new Date())
      setTimeRest(newTimeRest)
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [dateTime, getHourForm, timeRest, isEditDate])

  const saveDateToServer = useCallback(
    date => {
      setValue('publishedAt', date)
    },
    [setValue]
  )
  const changeKeyWords = useCallback(
    value => {
      setDefaultWords(value)
    },
    [setDefaultWords]
  )

  useEffect(() => {
    setDisabledFormState(!!textError || !text || !fields.regionUids?.length || !defaultWords)
  }, [setDisabledFormState, textError, dateTime, text, fields, defaultWords])

  useEffect(() => {
    setBreakingNewsModalFieldValues({
      title: '',
      text: text || '',
      keywords: defaultWords,
      regionUids: fields.regionUids || [],
      publishedAt: dateTime
    } as BreakingNewsInputType)
  }, [text, fields?.regionUids, defaultWords, dateTime, setBreakingNewsModalFieldValues])

  return (
    <Box display='flex' flexDirection={'column'}>
      <FormProvider
        {...{
          control,
          setValue,
          getValues,
          watch,
          reset,
          handleSubmit,
          ...methodsForm
        }}
      >
        <StyledFormControl width='430px' required error={!text && textDirty}>
          <div style={{flexDirection: 'row'}}>
            <InputLabel
              style={{
                width: '100%'
              }}
            >
              {BREAKING_NEWS_MODAL_FIELDS_CONSTS.textMsg}
            </InputLabel>
            <OutlinedInput
              label={BREAKING_NEWS_MODAL_FIELDS_CONSTS.textMsg}
              error={!!textError}
              multiline
              style={{
                float: 'left',
                width: '100%',
                minHeight: '147px',
                display: 'block'
              }}
              id='announce-image-modal-description'
              onChange={e => {
                setText(e.target.value)
                setTextDirty(true)
              }}
              value={text}
              required
            />
            <div>
              <TitleLength
                style={{
                  float: 'right',
                  color: !!textError ? redColor : 'inherit'
                }}
              >
                {`${text?.length || 0} / ${BREAKING_NEWS_MODAL_FIELDS_CONSTS.maxPublicationLength}`}
              </TitleLength>
              {!!textError && (
                <FormHelperText
                  style={{
                    float: 'left',
                    color: redColor
                  }}
                >
                  {textError}
                </FormHelperText>
              )}
            </div>
          </div>
        </StyledFormControl>
        <Box>
          <Box width={'100%'} style={{float: 'left'}}>
            <RegionsSelect width={'100%'} setRegions={setRegions} />
          </Box>
        </Box>
        <Box display='flex' flexDirection={'row'}>
          <DetailsDataWrapper>
            <DatePickerWrapper
              disableToolbar
              variant='inline'
              inputFormat='dd.MM.yyyy HH:mm'
              margin='normal'
              id='date-picker-inline'
              label={BREAKING_NEWS_MODAL_FIELDS_CONSTS.dateTimeMsg}
              value={dateTime}
              onChange={date => {
                setIsEditDate(true)
                setDateTime(date)
                saveDateToServer(date)
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              // disabled={getValues('cantEditPublication')}
            />
            <Typography color='secondary' component='span' style={{margin: '8px 0 0 12px'}}>
              {timeRest !== 0 && BREAKING_NEWS_MODAL_FIELDS_CONSTS.delayedPubl}
              {!isEditDate && BREAKING_NEWS_MODAL_FIELDS_CONSTS.publishAtNowMsg}
            </Typography>
          </DetailsDataWrapper>
        </Box>
        <KeyWords defaultWords={defaultWords} changeKeyWords={changeKeyWords} width={'100%'} />
      </FormProvider>
    </Box>
  )
}
export default BreakingNewsModalFields
