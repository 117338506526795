export const BLUE_COLOR = '#1964e7'

export const screenXl = '1360px'
export const screenM = '1024px'
export const screenS = '768px'
export const screenXs = '320px'
export const whiteColor = '#fff'
export const blackColor = '#000'
export const mainTextColor = '#222222'
export const secondTextColor = '#4e4e4e'
export const blueColor = '#1964e7'
export const lightblueColor = '#e6efff'
export const lightblueClr = '#F1F6FF'
export const lightBlueBackgroundClr = '#D9E6FF'
export const lightgrayColor = '#c4c4c4'
export const grayColor = '#707070'
export const dividerColor = '#dfdfdf'
export const redColor = '#c31e28'
export const lightRedColor = '#ff8a8a'
export const lightRedColor2 = '#f2dddd'
export const greyEditor = '#e8e8e8'
export const grey20 = '#C4C4C4'
export const grey35 = '#A9A9A9'
export const grey100 = '#4E4E4E'
export const greyBG = '#FAFAFA'
export const greenColor = '#398647'
export const yellowColor = '#ffdd00'
export const warnColor = '#FF8A00'
export const errorFieldColor = 'rgb(195, 30, 40)'
export const errorColor = '#EB3846'
export const lightGrayColor2 = '#E5E5E5'
export const lightGreenColor = '#E2FFE5'
export const robotoFont = 'Roboto'

// $font-main: roboto, 'Segoe UI', 'SF UI Display', sans-serif'
// $font-legacy: 'Segoe UI', Roboto, -apple-system, BlinkMacSystemFont, Oxygen,
//     Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif'
