import {Button} from '@mui/material'
import styled from 'styled-components'
import {errorColor} from '@theme/vars'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'

export const PublicationActionButtonWrapper = styled.div`
  position: fixed;
  background: #fafafa;
  box-shadow: 4px 0px 8px 0px rgba(84, 88, 91, 0.1);
  bottom: 0;
  left: 0;
  padding-top: 10px;
  margin: 0;
  z-index: 1;
  width: 100%;
`

export const PublicationActionButtonFields = styled.div`
  width: calc(100% - 20px);
  max-width: 1266px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;

  @media only screen and (max-width: 840px) {
    flex-direction: column;
  }
`

export const PublicationActionButtonFieldsDateAndStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PublicationActionButtonFieldsDateAndStatusBlock = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @media only screen and (max-width: 980px) {
    flex-direction: column;
    align-items: flex-start;
    /* width: 100%; */
  }
`

export const PublicationRemovedWrapper = styled.div`
  color: ${errorColor};
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-left: 24px;
  margin-right: 5px;
  margin-top: -13px;

  @media only screen and (max-width: 980px) {
    margin-bottom: 8px;
    margin-top: 16px;
    margin-left: 0px;
  }
`

export const PublicationActionButtonFieldsButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media only screen and (max-width: 840px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`

export const PublicationActionButtonBtn = styled(Button)`
  text-transform: initial;
  min-width: 210px;
  width: 210px;
  height: 42px;
  margin-bottom: 12px;
  margin-left: 12px;
  max-width: 256px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @media only screen and (max-width: 840px) {
    margin-left: 0px;
    margin-right: 12px;
  }
  @media only screen and (max-width: 540px) {
    min-width: 210px;
    max-width: 500px;
    width: calc(100% - 75px);
  }
`

export const PublicationActionButtonControlTooltipWrapper = styled(ControlTooltip)`
  width: auto;

  @media only screen and (max-width: 540px) {
    width: 100%;
  }
`
