import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useFormContext} from 'react-hook-form'
import {useKeycloak} from '@react-keycloak/web'
import {Checkbox} from '@mui/material'
import {useStore} from '@stores/rootStoreContext'
import {
  useOverviewBlocksByUrlOrNullQuery,
  useOverviewBlocksDraftByPublicationOrNullQuery
} from '@components/MainSelection/Overview/OverviewContent/gql/OverviewContent.generated'
import {PublicationStatus} from '@graphql/types'
import {useRegionsQuery} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {RegionOverviewBlock} from '../RegionOverviewBlock'
import {
  RegionsWithOverviewBlocksControllerWrapper,
  RegionsWithOverviewBlocksMenuItem,
  RegionsWithOverviewBlocksMenuItemTitle,
  RegionsWithOverviewBlocksStyledFormControl
} from '../RegionsWithOverviewsBlocksSelect.styles'
import {RegionOverviewBlockPlace} from '../RegionOverviewBlockPlace/RegionOverviewBlockPlace'
import {RegionPositionDirty} from '../RegionPositionDirty'
import {REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS} from './RegionsWithOverviewsBlocksSelectController.consts'
import {SelectedRegion} from './SelectedRegion'
import {RegionsWithOverviewsBlocksSelectControllerProps} from './RegionsWithOverviewsBlocksSelectControllerProps'

export const RegionsWithOverviewsBlocksSelectController: FC<RegionsWithOverviewsBlocksSelectControllerProps> = observer(
  ({publicationRegionsLoading, setHasOverviewBlock, refetchOverviewBlocks, setRegionUids, setCurRegions}) => {
    const {control, getValues, setValue} = useFormContext()
    const {keycloak} = useKeycloak()
    const {publicationStore, regionsWithOverviewBlocksStore} = useStore()
    const {publication} = publicationStore
    const {overviewBlocksCache, setOverviewBlocksCache} = regionsWithOverviewBlocksStore

    const {data: curRegions} = useRegionsQuery()

    useEffect(() => {
      setRegionUids(getValues('regionUids'))
    }, [getValues('regionUids'), setRegionUids])

    const {data: overviewBlocks, refetch} = useOverviewBlocksByUrlOrNullQuery({
      variables: {url: publication?.canonicalUrl || ''},
      notifyOnNetworkStatusChange: true
    })

    const {data: overviewBlocksDraft, refetch: refetchDraft} = useOverviewBlocksDraftByPublicationOrNullQuery({
      variables: {publicationId: publication?.id || ''},
      notifyOnNetworkStatusChange: true
    })

    const [selectedOverviewBlockId, setSelectedOverviewBlockId] = useState('')
    const [selectedOverviewBlocks, setSelectedOverviewBlocks] = useState<SelectedRegion[]>([])
    const [selectedOverviewBlocksDraft, setSelectedOverviewBlocksDraft] = useState<SelectedRegion[]>([])
    const [regions, setRegions] = useState<any>([])

    const [selectedRegionId, setSelectedRegionId] = useState('')
    // const [selectedPosition, setSelectedPosition] = useState('')
    const [allChecked, setAllChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isOpenMainPageModal, setIsOpenMainPageModal] = useState(false)

    const cantEditPublication = useMemo(
      () =>
        !(
          keycloak.hasResourceRole('edit-published', 'publications') ||
          keycloak.hasResourceRole('manage-main-page', 'main-page')
        ),

      [keycloak]
    )

    const setMainPlacePage = useCallback(
      async (position: number | null, regionOverviewBlocks: RegionOverviewBlock[] = []) => {
        setIsOpenMainPageModal(false)

        const overviewBlocksCacheCopy = JSON.parse(JSON.stringify(overviewBlocksCache))

        if (regionOverviewBlocks.length) {
          regionOverviewBlocks.forEach(rob => {
            const overviewBlocksCacheItemIndex = overviewBlocksCache?.findIndex(
              item => item.regionId.localeCompare(rob.regionId) === 0
            )

            if (overviewBlocksCacheItemIndex !== -1) {
              if (position != null) {
                overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].position = position
                overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].forDelete = false
              } else {
                if (!selectedOverviewBlockId) {
                  overviewBlocksCacheCopy.splice(overviewBlocksCacheItemIndex, 1)
                } else {
                  overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].position = null
                  overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].forDelete = true
                }
              }
            } else {
              // В черновиках старый блок не используем, он сам перезатрется новым
              const cacheOverviewBlockId =
                [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) && position
                  ? ''
                  : rob.overviewBlockId

              overviewBlocksCacheCopy.push({
                id: cacheOverviewBlockId,
                regionId: rob.regionId,
                url: publication?.url || '',
                figureVersionId: publication?.announceImage?.figureVersion?.id || '',
                title: publication?.overviewTitle || publication?.title || '',
                subtitle: publication?.overviewDescription || publication?.subtitle || '',
                position: position,
                forDelete: position ? false : true
              })
            }
          })
        } else {
          const overviewBlocksCacheItemIndex = overviewBlocksCache?.findIndex(
            item => item.regionId.localeCompare(selectedRegionId) === 0
          )

          if (overviewBlocksCacheItemIndex !== -1) {
            if (position != null) {
              overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].position = position
              overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].forDelete = false
            } else {
              if (!selectedOverviewBlockId) {
                overviewBlocksCacheCopy.splice(overviewBlocksCacheItemIndex, 1)
              } else {
                overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].position = null
                overviewBlocksCacheCopy[overviewBlocksCacheItemIndex].forDelete = true
              }
            }
          } else {
            overviewBlocksCacheCopy.push({
              id: selectedOverviewBlockId,
              regionId: selectedRegionId,
              url: publication?.url || '',
              figureVersionId: publication?.announceImage?.figureVersion?.id || '',
              title: publication?.overviewTitle || publication?.title || '',
              subtitle: publication?.overviewDescription || publication?.subtitle || '',
              position: position,
              forDelete: position ? false : true
            })
          }
        }

        setOverviewBlocksCache(overviewBlocksCacheCopy)
      },
      [
        setOverviewBlocksCache,
        setIsOpenMainPageModal,
        selectedRegionId,
        selectedOverviewBlockId,
        overviewBlocksCache,
        publication
      ]
    )

    const showPlaceBlockIndex = useCallback(
      (regionId: string, extended = true): RegionPositionDirty => {
        if (overviewBlocksCache && selectedOverviewBlocks) {
          const overviewBlocksCacheItem = overviewBlocksCache?.find(region => region.regionId === regionId)

          if (overviewBlocksCacheItem) {
            return {
              isDirty: true,
              position:
                overviewBlocksCacheItem?.position != null
                  ? overviewBlocksCacheItem?.position.toString()
                  : REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.dontShowMsg
            }
          }

          const selectedOverviewBlocksItem = selectedOverviewBlocks.find(region => region.regionId === regionId)
          if (selectedOverviewBlocksItem) {
            return {
              isDirty: false,
              position:
                selectedOverviewBlocksItem.position != null
                  ? selectedOverviewBlocksItem.position.toString()
                  : REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.dontShowMsg
            }
          }
        }
        return {
          isDirty: false,
          position: extended ? REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.dontShowMsg : ''
        }
      },
      [selectedOverviewBlocks, overviewBlocksCache]
    )

    const showPlaceBlockDraftIndex = useCallback(
      (regionId: string, extended = true): string => {
        if (overviewBlocksCache && selectedOverviewBlocksDraft) {
          const overviewBlocksCacheItem = overviewBlocksCache?.find(region => region.regionId === regionId)

          if (overviewBlocksCacheItem) {
            return overviewBlocksCacheItem?.position != null
              ? overviewBlocksCacheItem?.position.toString()
              : REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.dontShowMsg
          }

          const selectedOverviewBlocksItem = selectedOverviewBlocksDraft.find(region => region.regionId === regionId)

          if (selectedOverviewBlocksItem) {
            return selectedOverviewBlocksItem.position != null
              ? selectedOverviewBlocksItem.position.toString()
              : REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.dontShowMsg
          }
        }
        return extended ? REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.dontShowMsg : ''
      },
      [selectedOverviewBlocksDraft, overviewBlocksCache]
    )

    const setDefaultPosition = useCallback(
      (regionIds: string[]) => {
        // setSelectedRegionId(regionId)
        if (regionIds?.length) {
          const regionOverviewBlocks: RegionOverviewBlock[] = []

          regionIds.forEach(r => {
            let overviewBlockId = ''

            if (
              [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
              overviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull?.length
            ) {
              overviewBlockId =
                overviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull.find(item => item?.region.id === r)?.id ||
                ''
            } else if (overviewBlocks?.overviewBlocksByUrlOrNull?.length) {
              overviewBlockId = overviewBlocks?.overviewBlocksByUrlOrNull.find(item => item?.region.id === r)?.id || ''
            }

            const cacheOverviewBlockId = overviewBlocksCache.find(i => i?.regionId === r)?.id

            if (!overviewBlockId && !cacheOverviewBlockId) {
              regionOverviewBlocks.push({
                regionId: r,
                overviewBlockId: overviewBlockId
              })
            }
          })
          setMainPlacePage(1, regionOverviewBlocks)
        }
      },
      [overviewBlocks, overviewBlocksDraft, overviewBlocksCache, publication?.status, setMainPlacePage]
    )

    const handleClick = useCallback(
      (region, field) => {
        if (region?.uid) {
          setSelectedRegionId(region.id)
          if (field.value.includes(region?.uid)) {
            field.value = field.value.filter(fieldVal => fieldVal !== region?.uid)

            let overviewBlocksCacheItemForDelete

            if (
              [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
              overviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull
            ) {
              overviewBlocksCacheItemForDelete = overviewBlocksDraft.overviewBlocksDraftByPublicationOrNull.find(
                i => i?.region.id === region.id
              )

              if (overviewBlocksCacheItemForDelete) {
                const overviewBlocksCacheCopy = JSON.parse(JSON.stringify(overviewBlocksCache))
                overviewBlocksCacheCopy.push({
                  id: overviewBlocksCacheItemForDelete.id,
                  regionId: overviewBlocksCacheItemForDelete.region?.id,
                  url: overviewBlocksCacheItemForDelete.url || '',
                  figureVersionId: overviewBlocksCacheItemForDelete.figureVersionId || '',
                  title: overviewBlocksCacheItemForDelete.title || '',
                  subtitle: overviewBlocksCacheItemForDelete.subtitle || '',
                  position: overviewBlocksCacheItemForDelete.position,
                  forDelete: true
                })

                setOverviewBlocksCache(overviewBlocksCacheCopy)
              }
            }

            if (field.value.length !== regions.length) {
              setAllChecked(false)
            }
          } else {
            field.value.push(region?.uid)
            if (publication.typeSlug === 'articles') {
              setDefaultPosition([region?.id])
            }
            if (field.value.length === regions.length) {
              setAllChecked(true)
            }
          }
        }
        setRegionUids(field.value)
        setValue('regionUids', field.value)
      },
      [
        setValue,
        setRegionUids,
        regions,
        publication.typeSlug,
        publication.status,
        overviewBlocksCache,
        overviewBlocksDraft,
        setDefaultPosition,
        setOverviewBlocksCache
      ]
    )

    useEffect(() => {
      setLoading(publicationRegionsLoading)
    }, [publicationRegionsLoading, setLoading])

    useEffect(() => {
      if (regions?.length) {
        const regionsItems = regions.filter(i => getValues('regionUids').includes(i.uid))
        if (regionsItems?.length) {
          if (regionsItems.find(i => i.position > 0)) {
            setHasOverviewBlock(true)
          } else {
            if (publication.typeSlug === 'articles') {
              const allRegion = regionsItems.find(r =>
                r.title.includes(REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.allRegionsMsg)
              )

              if (allRegion) {
                const cacheOverviewBlockId = overviewBlocksCache.find(item => item?.regionId === allRegion.id)?.id
                const selectedOverviewBlock = [PublicationStatus.Draft, PublicationStatus.Review].includes(
                  publication?.status
                )
                  ? selectedOverviewBlocksDraft.find(item => item?.regionId === allRegion.id)
                  : selectedOverviewBlocks.find(item => item?.regionId === allRegion.id)
                const regionsLength = getValues('regionUids').length || 0

                if (
                  (!regionsLength || regionsLength === 1) &&
                  !selectedOverviewBlock &&
                  !cacheOverviewBlockId &&
                  !allRegion.position
                ) {
                  setDefaultPosition([allRegion.id])
                }
              }
            }
            setHasOverviewBlock(false)
          }
        } else {
          setHasOverviewBlock(false)
        }
      } else {
        setHasOverviewBlock(false)
      }
    }, [
      regions,
      overviewBlocksCache,
      selectedOverviewBlocks,
      selectedOverviewBlocksDraft,
      publication.status,
      publication.typeSlug,
      setDefaultPosition,
      setHasOverviewBlock,
      getValues
    ])

    const checkAll = useCallback(
      val => {
        setAllChecked(val)
        let regionsValues = []

        if (val) {
          regionsValues = (regions?.length && regions.map(r => r.uid)) || []
        } else {
          regionsValues =
            (regions?.length &&
              regions
                .filter(r => r.title === REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.allRegionsMsg)
                .map(r => r.uid)) ||
            []
        }
        setValue('regionUids', regionsValues)
        setRegionUids(regionsValues)

        if (val && publication.typeSlug === 'articles') {
          const regionIds: string[] = []
          regions.forEach(i => {
            const cacheOverviewBlockId = overviewBlocksCache.find(item => item?.regionId === i.id)?.id
            if (!i.position) {
              regionIds.push(i.id)
            }
          })

          setDefaultPosition(regionIds)
        }
      },
      [setAllChecked, setValue, setRegionUids, regions, overviewBlocksCache, publication.typeSlug, setDefaultPosition]
    )

    useEffect(() => {
      refetchOverviewBlocks.current = () => {
        if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
          refetchDraft()
        } else {
          refetch()
        }
        setOverviewBlocksCache([])
      }
      refetchOverviewBlocks.current()
    }, [publication.status, refetchOverviewBlocks, refetch, refetchDraft, setOverviewBlocksCache])

    useEffect(() => {
      if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
        if (overviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull?.length) {
          setSelectedOverviewBlocksDraft(
            overviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull?.map(item => {
              return {
                regionId: item?.region?.id || '',
                overviewBlockId: item?.id || '',
                position: item?.position != null ? item?.position : null
              }
            })
          )
        } else {
          setSelectedOverviewBlocksDraft([])
        }
      } else {
        if (overviewBlocks?.overviewBlocksByUrlOrNull?.length) {
          setSelectedOverviewBlocks(
            overviewBlocks?.overviewBlocksByUrlOrNull?.map(item => {
              return {
                regionId: item?.region.id || '',
                overviewBlockId: item?.id || '',
                position: item?.position != null ? item?.position + 1 : null
              }
            })
          )
        } else {
          setSelectedOverviewBlocks([])
        }
      }
    }, [
      publication.status,
      overviewBlocks,
      setSelectedOverviewBlocks,
      overviewBlocksDraft,
      setSelectedOverviewBlocksDraft
    ])

    // useEffect(() => {
    //   if (overviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull?.length) {
    //     setSelectedOverviewBlocksDraft(
    //       overviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull?.map(item => {
    //         return {
    //           regionId: item?.region.id || '',
    //           overviewBlockId: item?.id || '',
    //           position: item?.position != null ? item?.position + 1 : null
    //         }
    //       })
    //     )
    //   } else {
    //     setSelectedOverviewBlocksDraft([])
    //   }
    // }, [overviewBlocksDraft, setSelectedOverviewBlocksDraft])

    useEffect(() => {
      if (curRegions?.regions) {
        const extRegions = curRegions.regions?.map(r => {
          return {
            id: r.id,
            title: r.title,
            uid: r.uid,
            position: [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
              ? showPlaceBlockDraftIndex(r.id, false)
              : showPlaceBlockIndex(r.id, false)?.position
          }
        })
        setRegions(extRegions)
        setCurRegions(extRegions)
      }
    }, [
      curRegions,
      selectedOverviewBlocks,
      selectedOverviewBlocksDraft,
      overviewBlocksCache,
      publication.status,
      showPlaceBlockIndex,
      showPlaceBlockDraftIndex,
      getValues,
      setCurRegions
    ])

    return (
      <RegionsWithOverviewBlocksControllerWrapper
        name='regionUids'
        control={control}
        render={({field}) => (
          <RegionsWithOverviewBlocksStyledFormControl disabled={getValues('cantEditPublication')}>
            <RegionsWithOverviewBlocksMenuItem
              key={0}
              value={0}
              onClick={() => {
                if (!loading) {
                  checkAll(!allChecked)
                }
              }}
            >
              <Checkbox
                color={'primary'}
                id={`region-with-overview-blocks-check-all`}
                name={`region-with-overview-blocks-check-all`}
                checked={allChecked}
                disabled={loading}
                style={{marginLeft: '-12px'}}
              />
              {REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.checkAllMsg}
            </RegionsWithOverviewBlocksMenuItem>
            {regions &&
              regions.map(item => (
                <RegionsWithOverviewBlocksMenuItem key={item.uid} value={item.uid}>
                  <RegionsWithOverviewBlocksMenuItemTitle
                    onClick={() => {
                      if (
                        !loading &&
                        !item.title.includes(REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.allRegionsMsg)
                      ) {
                        handleClick(item, field)
                      }
                    }}
                  >
                    <Checkbox
                      disabled={
                        loading || item?.title === REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.allRegionsMsg
                      }
                      color={
                        item?.title === REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONTROLLER_CONSTS.allRegionsMsg
                          ? 'default'
                          : 'primary'
                      }
                      id={`region-with-overview-blocks-${item.uid}`}
                      name={`region-with-overview-blocks-${item.uid}`}
                      checked={getValues('regionUids').includes(item.uid)}
                    />
                    {item.title}
                  </RegionsWithOverviewBlocksMenuItemTitle>
                  <span>&nbsp;</span>
                  {!cantEditPublication && getValues('regionUids').includes(item.uid) && (
                    <RegionOverviewBlockPlace
                      loading={loading}
                      cantEdit={cantEditPublication}
                      overviewBlocks={overviewBlocks}
                      overviewBlocksDraft={overviewBlocksDraft}
                      // position={selectedPosition}
                      isOpenModal={isOpenMainPageModal}
                      region={item}
                      setMainPlacePage={setMainPlacePage}
                      setSelectedOverviewBlockId={setSelectedOverviewBlockId}
                      setSelectedRegionId={setSelectedRegionId}
                      showPlaceBlockDraftIndex={showPlaceBlockDraftIndex}
                      showPlaceBlockIndex={showPlaceBlockIndex}
                    />
                  )}
                </RegionsWithOverviewBlocksMenuItem>
              ))}
          </RegionsWithOverviewBlocksStyledFormControl>
        )}
      />
    )
  }
)
