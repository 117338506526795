import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import ReactQuill, {Quill} from 'react-quill'
import {observer} from 'mobx-react-lite'
import {debounce} from 'lodash'
import {useSnackbar} from 'notistack'
import {PublicationStatus} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {deleteElementById} from '@utils/deleteElementById'
import {useUpdatePublicationContentMutation} from '../../gql/TextEditor.generated'
import {EDITOR_REF_ID} from '../Editor.consts'
import {QUILL_EDITOR_CONSTS} from './QuillEditor.consts'
import {formats} from './QuillEditor.formats'
import {EditorWrapper, QuillEditorWrapper} from './QuillEditor.styles'
import {adaptQuillRequest, adaptQuillResponse} from './QuillEditor.adapters'
import type {QuillEditorProps} from './QuillEditor.types'

const Delta = Quill.import('delta')

const QuillEditor: FC<QuillEditorProps> = ({editorRef, modules, handleFocus, changeEditorTextLength}) => {
  const [html, setHtml] = useState('')
  const [isLoadingData, setIsLoadingData] = useState(false)
  const snackbar = useSnackbar()
  const [updateElements] = useUpdatePublicationContentMutation()
  const {publicationStore, editorStore} = useStore()
  const {publication, setPublicationContentLoading, setPublicationWithPrevState} = publicationStore
  const {setEditorRef, setEditorDirty, setEditorTextLength} = editorStore

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElementsMutation = useCallback(
    debounce(async (quill: Quill) => {
      if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
        const elements = adaptQuillRequest({quillChildren: [...quill.root.children]})

        setPublicationContentLoading(true)
        try {
          await updateElements({
            variables: {
              data: {
                publicationUid: publication.uid,
                elements
              }
            }
          })
        } catch {
        } finally {
          setPublicationContentLoading(false)
        }
      }
    }, 7500),
    // Нужно внемательно следить за зависимостями
    // react-hooks/exhaustive-deps не подсказывает, если функция обернута в debounce
    [publication.status, publication.uid, updateElements, setPublicationWithPrevState, setPublicationContentLoading]
  )

  const editorTextMaxLength = useMemo(() => {
    return publication.typeSlug === 'articles' ? 3500 : QUILL_EDITOR_CONSTS.maxPublicationLength
  }, [publication.typeSlug])

  const errorText = useMemo(() => {
    return publication.typeSlug === 'articles'
      ? QUILL_EDITOR_CONSTS.reachedCharacterLimitMsg
      : QUILL_EDITOR_CONSTS.mayBeThisArticleMsg
  }, [publication.typeSlug])

  useEffect(() => {
    const deleteElementEvent = (event: Event) => {
      deleteElementById(event?.detail?.id)
    }

    window.addEventListener('deleteImageEvent', event => deleteElementEvent(event))
    window.addEventListener('deleteVideoEvent', event => deleteElementEvent(event))
    window.addEventListener('deleteHtmlEvent', event => deleteElementEvent(event))
    window.addEventListener('deleteSideBarEvent', event => deleteElementEvent(event))

    setIsLoadingData(true)

    let quill: Quill | undefined
    if (editorRef.current !== null) {
      setEditorRef(editorRef)
      quill = editorRef.current.getEditor()
    }

    if (quill) {
      const ops = adaptQuillResponse({elements: publication.content.elements, quill})
      quill.setContents(new Delta({ops}))
      setIsLoadingData(false)
    }
  }, [editorRef, editorRef.current, publication.content.elements, publication.content.elements.length, setEditorRef])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showError = useCallback(
    debounce(() => {
      snackbar.enqueueSnackbar(errorText, {variant: 'error'})
    }, 1000),
    []
  )

  const handleChange = useCallback(
    (newHtml, delta, source) => {
      setHtml(newHtml)

      let quill: Quill | undefined

      if (editorRef.current) {
        quill = editorRef.current.getEditor()
      }

      if (quill) {
        const editorTextLength = quill.getLength() - 1

        changeEditorTextLength(editorTextLength)
        setEditorTextLength(editorTextLength)

        if (source === 'user' || source === 'link') {
          if (source === 'user') {
            setEditorDirty(true)
          }

          if (delta?.ops?.length == 2) {
            const selectionIndex =
              (delta.ops[0]?.retain || 0) + (delta.ops[1]?.insert?.length || delta.ops[1]?.delete?.length || 0)

            quill.focus()
            quill.setSelection(selectionIndex, 0)
          }

          if (editorTextLength >= editorTextMaxLength) {
            showError()
          }

          updateElementsMutation(quill)
        }
      }
    },
    [
      editorRef.current,
      isLoadingData,
      changeEditorTextLength,
      editorTextMaxLength,
      updateElementsMutation,
      showError,
      // @ts-ignore: Unreachable code error
      publication.announceImage?.latestVersion?.primaryProcessedImage?.url,
      // @ts-ignore: Unreachable code error
      publication.announceImage?.uid,
      publication.announceImage?.author,
      publication.announceImage?.description,
      handleFocus,
      setEditorDirty,
      setEditorTextLength,
      html
    ]
  )

  return (
    <QuillEditorWrapper style={{height: '556px'}}>
      <EditorWrapper>
        <ReactQuill
          id={EDITOR_REF_ID}
          ref={editorRef}
          formats={formats}
          modules={modules}
          value={html}
          onChange={handleChange}
        />
      </EditorWrapper>
    </QuillEditorWrapper>
  )
}

export default observer(QuillEditor)
