import {lightRedColor, mainTextColor} from '../../../../@theme/vars'
import {NnItem} from './NnItem'

export const TOOLBAR_SIZE_CONSTS = {
  sizeItems: [
    {
      title: 'Написать бэк',
      color: mainTextColor,
      size: 'background'
    } as NnItem,
    {
      title: 'Проверить иноагентов',
      color: mainTextColor,
      size: 'inoagent'
    } as NnItem,
    {
      title: 'Сгенерировать текст по теме',
      color: mainTextColor,
      size: 'generation'
    } as NnItem,
    {
      title: 'Переписать выделенный текст',
      color: mainTextColor,
      size: 'rerite'
    } as NnItem,
    {
      title: 'Рерайт',
      color: mainTextColor,
      size: 'testrerite'
    } as NnItem
  ],
  textSize: 'Воспользоваться нейросетью',
  textLoading: 'Запрос в обработке'
}
