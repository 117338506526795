import React, {FC, useState, useCallback, useEffect} from 'react'
import {Quill} from 'quill'
import {Quill as ReactQuill} from 'react-quill'
import {CustomHtmlElement} from '@graphql/types'
import {HTMLBlot} from '../../Blots/Html'
import {Html} from '../../Blots'
import HtmlEditorModal from '../../../UI/Html/HtmlEditorModal'
import {TOOLBAR_HTML_CONSTS} from './ToolbarHtml.consts'
import {ToolbarHtmlProps} from './ToolbarHtmlProps'

const Delta = ReactQuill.import('delta')

const ToolbarHtml: FC<ToolbarHtmlProps> = ({editorRef, isOpenHtmlModal, closeDialogHtml}) => {
  const [isOpenHtmlModalVal, setIsOpenHtmlModalVal] = useState(false)
  const [currentHtml, setCurrentHtml] = useState({} as CustomHtmlElement)

  const toggleHtmlModal = useCallback(() => {
    closeDialogHtml()
    setIsOpenHtmlModalVal(prev => {
      setCurrentHtml({} as CustomHtmlElement)
      return !prev
    })
  }, [])

  useEffect(() => {
    setIsOpenHtmlModalVal(isOpenHtmlModal)
    setCurrentHtml({} as CustomHtmlElement)
  }, [isOpenHtmlModal])

  const openModalHTML = e => {
    if (e?.detail.htmlBlot) {
      setCurrentHtml(e?.detail.htmlBlot as CustomHtmlElement)
    }
    setIsOpenHtmlModalVal(true)
  }

  useEffect(() => {
    window.addEventListener('openModalHTML', e => openModalHTML(e))

    return () => {
      window.removeEventListener('openModalHTML', e => openModalHTML(e))
    }
  }, [])

  const setHtmlText = useCallback(
    (htmlBlot: HTMLBlot) => {
      let quill: Quill | undefined
      if (editorRef.current !== null) {
        quill = editorRef.current.getEditor()
      }

      if (quill) {
        let isNew = true
        const contents = quill.getContents()

        const ops = contents.map(operation => {
          if (
            operation.insert[Html.blotName] &&
            operation.insert[Html.blotName].id &&
            operation.insert[Html.blotName].id === Html.id
          ) {
            isNew = false

            return {
              insert: {
                [Html.blotName]: htmlBlot
              }
            }
          }

          return operation
        })

        if (isNew) {
          const length = quill.getLength()
          const {index} = quill.getSelection(true)

          quill.insertEmbed(index, Html.blotName, htmlBlot, 'user')

          if (length < 1 + index) {
            quill.insertText(index + 1, '\n', 'user')
          }

          return
        }

        quill.setContents(
          new Delta({
            ops
          }),
          'user'
        )
      }
    },
    [editorRef]
  )

  return (
    <>
      <HtmlEditorModal
        defaultHtml={currentHtml}
        open={isOpenHtmlModalVal}
        setCustomHtml={setHtmlText}
        onClose={toggleHtmlModal}
        okLabel={currentHtml?.uid ? TOOLBAR_HTML_CONSTS.changeMsg : TOOLBAR_HTML_CONSTS.pasteMsg}
        onNo={toggleHtmlModal}
        title={TOOLBAR_HTML_CONSTS.insertingHtmlMsg}
        text=''
      />
    </>
  )
}
export default ToolbarHtml
