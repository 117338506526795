import {Menu} from '@mui/material'
import styled from 'styled-components'

function filterProps(...args: string[]) {
    return p => args && !args.includes(p)
}
interface ToolbarButtonProps {
    ql: string

    content: string
}

export const StyledMenu = styled(Menu)
    .withConfig({
        shouldForwardProp: filterProps('ql')
    })
    .attrs<Pick<ToolbarButtonProps, 'ql'>>(({ql, className}) => ({
        className: [`ql-${ql}`, className].join(' ').trim()
    }))<{$width?: string; ql?: string}>`
    margin-left: 12px;
    &.MuiInput-underline:before {
        display: none;
    }
`
