import {Quill} from 'react-quill'
// @ts-ignore
global.Quill = Quill
import Link from './Link'
import Html from './Html'
import Video from './Video'
import ImageBlot from './ImageBlot/ImageBlot'
import SideBar from './SideBar'
import SubHeader from './SubHeader'
import TextRssBlock from './TextRss'
import OnlineTranslationDividerBlot from './OnlineTranslationDividerBlot'
import BlockquoteBlot from './BlockquoteBlot'

const Inline = Quill.import('blots/inline')

function registerBlots(type: string, blots: any[]) {
  const registerObject = blots.reduce((acc, blot) => ({...acc, [`${type}/${blot.blotName}`]: blot}), {})
  Quill.register(registerObject)
}
Quill.debug('error')
registerBlots('formats', [
  Link,
  Html,
  Video,
  ImageBlot,
  SideBar,
  SubHeader,
  Inline,
  TextRssBlock,
  OnlineTranslationDividerBlot,
  BlockquoteBlot
])

export {Link, Html, Video, ImageBlot, SideBar, SubHeader, TextRssBlock, OnlineTranslationDividerBlot, BlockquoteBlot}
