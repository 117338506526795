import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Quill} from 'quill'
import {Quill as ReactQuill} from 'react-quill'
import {CropInput, Figure} from '@graphql/types'
import ImageEditorModal from '@components/UI/ImageEditor/ImageEditorModal/ImageEditorModal'
import {deleteElementById} from '@utils/deleteElementById'
import {FiguresCacheContext} from '@components/Publication/Form/PublicationForm/FiguresCacheContext'
import {useStore} from '@stores/rootStoreContext'
import {useGetFigureEditorLazyQuery} from '../../gql/TextEditor.generated'
import {ImageBlotProps} from '../../Blots/ImageBlot/ImageBlotProps'
import {ImageBlot} from '../../Blots'
import {TOOLBAR_IMAGE_CONSTS} from './ToolbarImage.consts'
import {ToolbarImageProps} from './ToolbarImageProps'

const Delta = ReactQuill.import('delta')

const ToolbarImage: FC<ToolbarImageProps> = ({editorRef}) => {
  // const [isOpenDialogImageVal, setIsOpenDialogImageVal] = useState(false)
  const [isAnnouncePhoto, setIsAnnouncePhoto] = useState(false)
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [imageModalId, setImageModalId] = useState('')
  const [currentFigure, setCurrentFigure] = useState({} as Figure)
  const {figuresCache} = useContext(FiguresCacheContext)
  const {publicationStore} = useStore()
  const {imageModalStore} = publicationStore
  const {isOpen, imageDetail, setIsOpen} = imageModalStore

  const closeDialogImage = useCallback(() => {
    setIsAnnouncePhoto(false)
    setImageModalId('')
    setIsOpen(false)
  }, [setIsOpen])

  useEffect(() => {
    // setIsOpenDialogImageVal(isOpenDialogImage)
    setCurrentFigure({} as Figure)
  }, [isOpen])

  const openModalImage = useCallback(
    (detail: ImageBlotProps) => {
      setIsAnnouncePhoto(detail.isannouncingphoto || false)
      if (detail?.figureUid) {
        const figuresCacheItem = figuresCache.find(item => item.figureUid === detail.figureUid)
        if (figuresCacheItem) {
          setCurrentFigure({
            alt: figuresCacheItem.alt || '',
            author: figuresCacheItem.author || '',
            cropperPreview: {
              url: figuresCacheItem.cropperPreviewUrl || ''
            },
            description: figuresCacheItem.description || '',
            hasWatermark: figuresCacheItem.hasWatermark || false,
            hasDiagonalWatermark: figuresCacheItem.hasDiagonalWatermark || false,
            id: figuresCacheItem.figureId,
            latestVersion: {
              id: detail.id,
              crop: figuresCacheItem.crop as CropInput
            },
            src: figuresCacheItem.src || '',
            uid: figuresCacheItem.figureUid || ''
          } as Figure)
        } else {
          setCurrentFigure({
            alt: detail.alt || '',
            author: detail.author || '',
            cropperPreview: {
              url: detail.cropperPreviewUrl || '',
              size: {
                width: detail.crop.width,
                height: detail.crop.height
              }
            },
            description: detail.description || '',
            hasWatermark: detail.hasWatermark || false,
            hasDiagonalWatermark: detail.hasDiagonalWatermark || false,
            id: detail.figureId,
            latestVersion: {
              id: detail.figureVersionId,
              crop: detail.crop as CropInput
            },
            src: detail.src || '',
            uid: detail.figureUid || ''
          } as Figure)
        }
      }
      setImageModalId(detail.id || detail.figureUid)
      setIsFullWidth(detail.isFullWidth || false)
      setIsOpen(true)
    },
    [figuresCache, setIsOpen]
  )

  useEffect(() => {
    if (isOpen) {
      openModalImage(imageDetail)
    }
  }, [isOpen, imageDetail, openModalImage])

  const setImage = useCallback(
    (imageBlot: ImageBlotProps) => {
      let quill: Quill | undefined
      if (editorRef.current !== null) {
        quill = editorRef.current.getEditor()
      }

      if (quill) {
        let isNew = true
        const contents = quill.getContents()

        const ops = contents.map(operation => {
          if (
            operation.insert[ImageBlot.blotName] &&
            operation.insert[ImageBlot.blotName].id &&
            operation.insert[ImageBlot.blotName].id === imageBlot.id
          ) {
            isNew = false

            return {
              insert: {
                [ImageBlot.blotName]: imageBlot
              }
            }
          }

          return operation
        })

        if (isNew) {
          const {index} = quill.getSelection(true)
          quill.insertEmbed(index, ImageBlot.blotName, imageBlot, 'user')
          quill.insertText(index + 1, '\n', 'user')

          return
        }

        quill.setContents(
          new Delta({
            ops
          }),
          'user'
        )
      }
    },
    [editorRef]
  )

  const updateCacheFigures = useCallback((data: ImageBlotProps) => {
    const figuresCacheItemIndex = figuresCache.findIndex(item => item.figureId === data.figureId)
    if (figuresCacheItemIndex !== -1) {
      figuresCache[figuresCacheItemIndex] = data
    } else {
      figuresCache.push(data)
    }
  }, [])

  return (
    <ImageEditorModal
      ratio={25 / 17}
      defaultFigure={currentFigure}
      modalId={imageModalId}
      open={isOpen}
      onClose={closeDialogImage}
      onNo={() => {
        setImageModalId('')
      }}
      title={TOOLBAR_IMAGE_CONSTS.insertingImageMsg}
      text=''
      okLabel={currentFigure?.uid ? TOOLBAR_IMAGE_CONSTS.changeMsg : TOOLBAR_IMAGE_CONSTS.pasteMsg}
      noLabel={
        currentFigure?.uid ? (isAnnouncePhoto ? '' : TOOLBAR_IMAGE_CONSTS.deleteMsg) : TOOLBAR_IMAGE_CONSTS.cancelMsg
      }
      addFigure={setImage}
      deleteFigure={
        isAnnouncePhoto
          ? () => {
              setImageModalId('')
            }
          : deleteElementById
      }
      figureTypeSlug='publication'
      needMoreDescription
      isCustomRatio={!isAnnouncePhoto}
      fullWidth={isFullWidth}
      updateCacheFigures={updateCacheFigures}
    />
  )
}
export default observer(ToolbarImage)
