import {Quill} from 'quill'
import {Quill as ReactQuill} from 'react-quill'
import OnlineTranslationDividerBlot from '@components/TextEditor/Blots/OnlineTranslationDividerBlot'

const pushToOnlineTranslation = (text: string, quill: Quill) => {
  const createAt = new Date().toISOString()
  const localeDate = new Date(createAt).toLocaleString('ru-Ru')
  const [_date, time] = localeDate.split(', ')

  const length = quill.getLength()

  const Delta = ReactQuill.import('delta')

  quill.updateContents(
    new Delta({
      ops: [
        {
          retain: length
        },
        {
          insert: {
            [OnlineTranslationDividerBlot.blotName]: {
              createAt
            }
          }
        },
        {insert: `${time.slice(0, -3)}`, attributes: {class: 'online_time'}},
        {insert: ` ${text}`}
      ]
    }),
    'user'
  )
}

export {pushToOnlineTranslation}
