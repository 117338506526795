import {ETitlesField} from './PublicationFormTitles.types'
import type {ITitleFieldEntries} from './PublicationFormTitles.types'

export const PUBLICATION_FORM_TITLES_CONSTS = {
  articleTitleMaxLength: 65,
  titleMaxLength: 110,
  subtitleMaxLength: 90,
  yandexTitleMaxLength: 80,
  yandexDzenTitleMaxWarningLength: 80,
  yandexDzenTitleMaxLength: 140,
  seoTitleMaxLength: 105,

  yandexTitleDoesNotMatchMsg: 'Заголовок не соответствует правилам заголовков для ЯндексНовостей'
}

export const TitleFieldEntries: Record<ETitlesField, ITitleFieldEntries> = {
  [ETitlesField.Title]: {
    name: 'Заголовок',
    maxErrorLength: 110,
    firstSentenceMaxLength: 65,
    titleMaxWordsCount: 15,
    maxWarningLength: 0
  },
  [ETitlesField.Subtitle]: {name: 'Подзаголовок', maxErrorLength: 90, maxWarningLength: 0},
  [ETitlesField.YandexTitle]: {name: 'Заголовок для Яндекс.Новостей', maxErrorLength: 80, maxWarningLength: 0},
  [ETitlesField.YandexDzenTitle]: {name: 'Заголовок для Дзен.Блогов', maxErrorLength: 140, maxWarningLength: 80},
  [ETitlesField.SeoTitle]: {name: 'Заголовок для SEO', maxErrorLength: 105, maxWarningLength: 0}
}

export const SENTENCE_SEPARATOR_REG_EX = /[\.\!\?]\s/gi
