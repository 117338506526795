export type Maybe<T> = T | null
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: any
  Time: any
  UUID: any
  join__FieldSet: any
  /** The sole purpose of this scalar is to make this file non-empty. */
  stub_publications_schema_authors_mutations: any
  /** The sole purpose of this scalar is to make this file non-empty. */
  stub_publications_schema_experiments_mutations: any
  /** The sole purpose of this scalar is to make this file non-empty. */
  stub_publications_schema_insides_mutations: any
  /** The sole purpose of this scalar is to make this file non-empty. */
  stub_publications_schema_national_projects_mutations: any
  /** The sole purpose of this scalar is to make this file non-empty. */
  stub_publications_schema_subscription_blocks_mutations: any
}

/** Уровень доступа */
export type AccessLevel = {
  __typename?: 'AccessLevel'
  /** INTERNAL */
  id: Scalars['UUID']
  /** INTERNAL */
  name?: Maybe<Scalars['String']>
  /** INTERNAL */
  uid: Scalars['UUID']
}

export type ActivateAdPublicationInput = {
  publicationId: Scalars['UUID']
}

export type ActivateAdPublicationPayload = {
  __typename?: 'ActivateAdPublicationPayload'
  status: Scalars['Boolean']
}

export type ActivateCollectionInput = {
  collectionUid: Scalars['UUID']
}

export type ActivateCollectionPayload = {
  __typename?: 'ActivateCollectionPayload'
  collection?: Maybe<Collection>
  status: Scalars['Boolean']
}

export type ActivateStoryInput = {
  storyUid: Scalars['UUID']
}

export type ActivateStoryPayload = {
  __typename?: 'ActivateStoryPayload'
  status: Scalars['Boolean']
}

export type ActivateTagInput = {
  tagUid: Scalars['UUID']
}

export type ActivateTagPayload = {
  __typename?: 'ActivateTagPayload'
  status: Scalars['Boolean']
  tag?: Maybe<Tag>
}

export type AdPublicationParameters = {
  __typename?: 'AdPublicationParameters'
  createdAt: Scalars['DateTime']
  creator: User
  hidesAt: Scalars['Time']
  isActive: Scalars['Boolean']
  showsAt: Scalars['Time']
  weight: Scalars['Int']
}

export type AdPublicationsFilter = {
  /** Дата добвления записи в список рекламных публикаций. */
  adParametersCreatedAtDate?: Maybe<Scalars['Date']>
  /** ID пользователя, добавившего запись в список рекламных публикаций. */
  adParametersCreatorId?: Maybe<Scalars['UUID']>
  /** Фильтр по заголовкам публикаций. */
  search?: Maybe<Scalars['String']>
}

export type AddAutomoderatorDictionaryEntryInput = {
  dictionaryName: Scalars['String']
  word: Scalars['String']
}

export type AddAutomoderatorDictionaryEntryPayload = {
  __typename?: 'AddAutomoderatorDictionaryEntryPayload'
  entry?: Maybe<AutomoderatorDictionaryEntry>
  status: Scalars['Boolean']
}

export type AddPublicationToOverviewFromPublicationsListInput = {
  figureVersionId: Scalars['UUID']
  position: Scalars['Int']
  publicationId: Scalars['UUID']
  regionId: Scalars['UUID']
  subtitle: Scalars['String']
  title: Scalars['String']
}

export type AddPublicationToOverviewFromPublicationsListPayload = {
  __typename?: 'AddPublicationToOverviewFromPublicationsListPayload'
  block?: Maybe<OverviewBlock>
  status: Scalars['Boolean']
}

export type AddPublicationToTopNewsFromPublicationsListInput = {
  figureVersionId: Scalars['UUID']
  isExclusive: Scalars['Boolean']
  position: Scalars['Int']
  publicationId: Scalars['UUID']
  regionId: Scalars['UUID']
  title: Scalars['String']
}

export type AddPublicationToTopNewsFromPublicationsListPayload = {
  __typename?: 'AddPublicationToTopNewsFromPublicationsListPayload'
  block?: Maybe<TopNewsBlock>
  status: Scalars['Boolean']
}

export type AddPublicationsToCollectionInput = {
  collectionUid: Scalars['UUID']
  publicationUids: Array<Scalars['UUID']>
}

export type AddPublicationsToCollectionPayload = {
  __typename?: 'AddPublicationsToCollectionPayload'
  collection?: Maybe<Collection>
  status: Scalars['Boolean']
}

export type AddPublicationsToExperimentInput = {
  experimentUid: Scalars['UUID']
  publicationUids: Array<Scalars['UUID']>
}

export type AddPublicationsToExperimentPayload = {
  __typename?: 'AddPublicationsToExperimentPayload'
  experiment?: Maybe<Experiment>
  status: Scalars['Boolean']
}

export type AddPublicationsToNationalProjectInput = {
  nationalProjectUid: Scalars['UUID']
  publicationUids: Array<Scalars['UUID']>
}

export type AddPublicationsToNationalProjectPayload = {
  __typename?: 'AddPublicationsToNationalProjectPayload'
  nationalProject?: Maybe<NationalProject>
  status: Scalars['Boolean']
}

export type AddPublicationsToStoryInput = {
  publicationUids: Array<Scalars['UUID']>
  storyUid: Scalars['UUID']
}

export type AddPublicationsToStoryPayload = {
  __typename?: 'AddPublicationsToStoryPayload'
  status: Scalars['Boolean']
  story?: Maybe<Story>
}

export type AddPublicationsToTagInput = {
  publicationUids: Array<Scalars['UUID']>
  tagUid: Scalars['UUID']
}

export type AddPublicationsToTagPayload = {
  __typename?: 'AddPublicationsToTagPayload'
  status: Scalars['Boolean']
  tag?: Maybe<Tag>
}

/** Анонсирующая картинка публикации. */
export type AnnounceImage = {
  __typename?: 'AnnounceImage'
  /**
   * Текстовое описание того, что изображено на картинке.
   * Может отбражаться вместо картинки или зачитываться вслух специальными браузерами.
   */
  alt: Scalars['String']
  /** Автор картинки (фотограф или источник, откуда взяли картинку). */
  author: Scalars['String']
  /** Подпись под картинкой. */
  description: Scalars['String']
  figureVersion?: Maybe<FigureVersion>
  id: Scalars['ID']
  /** Отображается во всю ширину контентной области новости? */
  isFullWidth: Scalars['Boolean']
  updatedAnnounce?: Maybe<Scalars['DateTime']>
}

export type AnnounceImageInput = {
  /**
   * Текстовое описание того, что изображено на картинке.
   * Может отбражаться вместо картинки или зачитываться вслух специальными браузерами.
   */
  alt: Scalars['String']
  /** Автор картинки (фотограф или источник, откуда взяли картинку). */
  author: Scalars['String']
  /** Подпись под картинкой. */
  description: Scalars['String']
  figureVersionId: Scalars['UUID']
  /** Отображается во всю ширину контентной области новости? */
  isFullWidth: Scalars['Boolean']
}

export type AnnounceimageVersion = {
  __typename?: 'AnnounceimageVersion'
  actionOnModel?: Maybe<Scalars['String']>
  changed?: Maybe<Scalars['DateTime']>
  data?: Maybe<Datalist>
  dataToString?: Maybe<Scalars['String']>
  ipaddress?: Maybe<Scalars['String']>
  recordId?: Maybe<Scalars['UUID']>
  uid?: Maybe<Scalars['UUID']>
  user?: Maybe<Scalars['String']>
}

export type AnyPublication = ExternalPublication | Publication

export type Author = {
  __typename?: 'Author'
  avatar?: Maybe<AuthorAvatar>
  deprecatedId: Scalars['Int']
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  publications?: Maybe<PublicationsConnection>
  uid: Scalars['UUID']
  user?: Maybe<User>
  userUid?: Maybe<Scalars['UUID']>
}

export type AuthorPublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type AuthorAvatar = {
  __typename?: 'AuthorAvatar'
  url: Scalars['String']
}

export type AutoModeratorResult = {
  __typename?: 'AutoModeratorResult'
  /**
   * INTERNAL<br>
   * Имя автора комментария с отмеченными "плохими" словами в формате text/html.
   */
  authorName: Scalars['String']
  /**
   * INTERNAL<br>
   * Текст комментария с отмеченными "плохими" словами в формате text/html.
   */
  text: Scalars['String']
}

export type AutomoderatorDictionaryEntriesConnection = {
  __typename?: 'AutomoderatorDictionaryEntriesConnection'
  edges?: Maybe<Array<Maybe<AutomoderatorDictionaryEntriesEdge>>>
  pageInfo: PageInfo
}

export type AutomoderatorDictionaryEntriesEdge = {
  __typename?: 'AutomoderatorDictionaryEntriesEdge'
  cursor: Scalars['String']
  node?: Maybe<AutomoderatorDictionaryEntry>
}

export type AutomoderatorDictionaryEntriesFilter = {
  dictionaryName: Scalars['String']
}

export enum AutomoderatorDictionaryEntriesSort {
  IdAsc = 'ID_ASC'
}

export type AutomoderatorDictionaryEntry = {
  __typename?: 'AutomoderatorDictionaryEntry'
  id: Scalars['ID']
  word: Scalars['String']
}

export type BadWords = {
  __typename?: 'BadWords'
  id: Scalars['UUID']
  verbal_expression?: Maybe<Scalars['String']>
}

export type Banner = {
  __typename?: 'Banner'
  /** INTERNAL */
  createdAt: Scalars['DateTime']
  /** PUBLIC */
  description: Scalars['String']
  /** PUBLIC */
  figure: Figure
  /** PUBLIC */
  id: Scalars['ID']
  /** PUBLIC */
  region: Region
  /** PUBLIC */
  title: Scalars['String']
  /** PUBLIC */
  uid: Scalars['UUID']
  /** INTERNAL */
  updatedAt: Scalars['DateTime']
  /** PUBLIC */
  url: Scalars['String']
}

export type CloneFigureInput = {
  /** Если требуется фигура другого типа, иначе null. */
  figureTypeSlug?: Maybe<Scalars['String']>
  figureUid: Scalars['UUID']
}

export type CloneFigurePayload = {
  __typename?: 'CloneFigurePayload'
  figure?: Maybe<Figure>
  status: Scalars['Boolean']
}

export type Collection = {
  __typename?: 'Collection'
  createdAt: Scalars['DateTime']
  deprecatedId?: Maybe<Scalars['Int']>
  description: Scalars['String']
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  latestPublication?: Maybe<Publication>
  publications?: Maybe<PublicationsConnection>
  publicationsCount: Scalars['Int']
  title: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type CollectionPublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type CollectionsConnection = {
  __typename?: 'CollectionsConnection'
  edges: Array<Maybe<CollectionsEdge>>
  pageInfo: PageInfo
}

export type CollectionsEdge = {
  __typename?: 'CollectionsEdge'
  cursor: Scalars['String']
  node?: Maybe<Collection>
}

export type CollectionsFilter = {
  isActive?: Scalars['Boolean']
  /** Поиск по заголовку. При поиске параметр сортировки игнорируется. */
  search?: Maybe<Scalars['String']>
}

export enum CollectionsSort {
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Comment = {
  __typename?: 'Comment'
  /**
   * PUBLIC<br>
   * Информация об авторе комментария.
   */
  author: CommentAuthor
  /**
   * INTERNAL<br>
   * null, если комментарий еще не прошел автомодерацию, иначе результат.
   */
  autoModeratorResult?: Maybe<AutoModeratorResult>
  /**
   * INTERNAL<br>
   * Дата и время создания комментария.
   */
  createdAt: Scalars['DateTime']
  /** PUBLIC */
  id: Scalars['ID']
  /**
   * INTERNAL<br>
   * Дата и время модерации комментария или null, если комментарий еще не отмодерирован.
   */
  moderatedAt?: Maybe<Scalars['DateTime']>
  /**
   * PUBLIC<br>
   * Родительский комментарий или null для комментариев верхнего уровня.
   */
  parentComment?: Maybe<Comment>
  /**
   * PUBLIC<br>
   * Публикация для которой написан комментарий.
   */
  publication: Publication
  /**
   * INTERNAL<br>
   * Статус комментария.
   */
  status: CommentStatus
  /**
   * PUBLIC<br>
   * Текст комментария.
   */
  text: Scalars['String']
  /** PUBLIC */
  uid: Scalars['UUID']
  /**
   * INTERNAL<br>
   * Дата и время последнего обновления комментария.
   */
  updatedAt: Scalars['DateTime']
}

export type CommentAuthor = {
  __typename?: 'CommentAuthor'
  /**
   * INTERNAL<br>
   * IP адрес с которого был оставлен комментарий.
   */
  ipAddress: Scalars['String']
  /**
   * PUBLIC<br>
   * Имя автора комментария в формате text/plain.
   */
  name: Scalars['String']
  /**
   * INTERNAL<br>
   * ID сессии пользователя, оставившего комментарий.
   */
  sessionId: Scalars['String']
  /**
   * INTERNAL<br>
   * Профиль пользователя, если он аутентифицирован, иначе null.
   */
  user?: Maybe<User>
}

export enum CommentStatus {
  /** На автомодерации */
  AutoModeration = 'AUTO_MODERATION',
  /** Опубликован */
  Published = 'PUBLISHED',
  /** Заблокирован */
  Rejected = 'REJECTED',
  /** На рассмотрении */
  Review = 'REVIEW'
}

export type CommentsConnection = {
  __typename?: 'CommentsConnection'
  edges: Array<Maybe<CommentsEdge>>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentsEdge = {
  __typename?: 'CommentsEdge'
  cursor: Scalars['String']
  node?: Maybe<Comment>
}

export type CommentsFilter = {
  moderatedAtDate?: Maybe<Scalars['Date']>
  status: CommentStatus
}

export enum CommentsSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModeratedAtDesc = 'MODERATED_AT_DESC'
}

/** Компания */
export type Company = {
  __typename?: 'Company'
  /** INTERNAL */
  id: Scalars['UUID']
  /** INTERNAL */
  name?: Maybe<Scalars['String']>
  /** INTERNAL */
  uid: Scalars['UUID']
}

export type ConvertPublicationToArticleInput = {
  publicationUid: Scalars['UUID']
}

export type ConvertPublicationToArticlePayload = {
  __typename?: 'ConvertPublicationToArticlePayload'
  publication?: Maybe<Publication>
  status: Scalars['Boolean']
}

export type CreateBadWordInput = {
  seoTitle: Scalars['String']
  title: Scalars['String']
}

export type CreateBadWordInput1 = {
  word: Scalars['String']
}

export type CreateCollectionInput = {
  description: Scalars['String']
  title: Scalars['String']
}

export type CreateCollectionPayload = {
  __typename?: 'CreateCollectionPayload'
  collection?: Maybe<Collection>
  status: Scalars['Boolean']
}

export type CreateCommentInput = {
  /** Имя автора, произвольная строка типа text/plain. */
  author: Scalars['String']
  /** null для комментариев верхнего уровня, id родительского комментария для ответа. */
  parentUid?: Maybe<Scalars['UUID']>
  /** Публикация, для которой создается комментарий. */
  publicationUid: Scalars['UUID']
  /** Текст комментария, произвольная строка типа text/plain. */
  text: Scalars['String']
}

export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload'
  /** Созданный комментарий. */
  comment?: Maybe<Comment>
  status: Scalars['Boolean']
}

export type CreateFigureInput = {
  alt?: Maybe<Scalars['String']>
  author?: Maybe<Scalars['String']>
  crop?: Maybe<CropInput>
  description?: Maybe<Scalars['String']>
  figureTypeSlug: Scalars['String']
  hasDiagonalWatermark?: Maybe<Scalars['Boolean']>
  hasWatermark?: Maybe<Scalars['Boolean']>
  src: Scalars['String']
}

export type CreateFigurePayload = {
  __typename?: 'CreateFigurePayload'
  figure?: Maybe<Figure>
  status: Scalars['Boolean']
}

export type CreateFigureVersionInput = {
  crop: CropInput
  figureId: Scalars['UUID']
  hasDiagonalWatermark: Scalars['Boolean']
  hasWatermark: Scalars['Boolean']
}

export type CreateFigureVersionPayload = {
  __typename?: 'CreateFigureVersionPayload'
  figureVersion?: Maybe<FigureVersion>
  status: Scalars['Boolean']
}

export type CreateOfficialWordInput = {
  word: Scalars['String']
}

export type CreateOverviewBlockDraftInput = {
  position: Scalars['Int']
  publicationId: Scalars['UUID']
  regionId: Scalars['UUID']
}

export type CreateOverviewBlockInput = {
  figureVersionId: Scalars['UUID']
  regionId: Scalars['UUID']
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
  url: Scalars['String']
}

export type CreateOverviewBlockPayload = {
  __typename?: 'CreateOverviewBlockPayload'
  /** Созданный блок */
  block?: Maybe<OverviewBlock>
  /** Первая страница (18 штук) блоков для того же региона, что и у созданного блока. */
  blocks?: Maybe<Array<OverviewBlock>>
  status: Scalars['Boolean']
}

export type CreatePublicationDraftInput = {
  typeSlug: Scalars['String']
}

export type CreatePublicationDraftPayload = {
  __typename?: 'CreatePublicationDraftPayload'
  publication?: Maybe<Publication>
  status: Scalars['Boolean']
}

export type CreateRelatedLinkInput = {
  publicationId: Scalars['UUID']
  text: Scalars['String']
  url: Scalars['String']
}

export type CreateRelatedLinkPayload = {
  __typename?: 'CreateRelatedLinkPayload'
  link?: Maybe<RelatedLink>
  status: Scalars['Boolean']
}

export type CreateSocialMediaFigureVersionInput = {
  /**
   * Коэффициент выравнивания по вертикали.
   * <ul>
   *     <li>0.0 - по верхнему краю</li>
   *     <li>0.5 - по центру</li>
   *     <li>1.0 - по нижнему краю</li>
   * </ul>
   */
  alignY: Scalars['Float']
  /** ID версии фигуры анонсирующей картинки. */
  announceFigureVersionId: Scalars['UUID']
  /** Заголовок, который отображается на картинке. */
  title: Scalars['String']
}

export type CreateSocialMediaFigureVersionPayload = {
  __typename?: 'CreateSocialMediaFigureVersionPayload'
  socialMediaFigureVersion?: Maybe<SocialMediaFigureVersion>
  status: Scalars['Boolean']
}

export type CreateStoryInput = {
  description: Scalars['String']
  figureUid?: Maybe<Scalars['UUID']>
  title: Scalars['String']
}

export type CreateStoryPayload = {
  __typename?: 'CreateStoryPayload'
  status: Scalars['Boolean']
  story?: Maybe<Story>
}

export type CreateTagInput = {
  description: Scalars['String']
  figureUid?: Maybe<Scalars['UUID']>
  priority?: Scalars['Int']
  relatedTagUids?: Maybe<Array<Scalars['UUID']>>
  title: Scalars['String']
}

export type CreateTagPayload = {
  __typename?: 'CreateTagPayload'
  status: Scalars['Boolean']
  tag?: Maybe<Tag>
}

export type Crop = {
  __typename?: 'Crop'
  height?: Maybe<Scalars['Int']>
  left?: Maybe<Scalars['Int']>
  top?: Maybe<Scalars['Int']>
  width?: Maybe<Scalars['Int']>
}

export type CropInput = {
  height: Scalars['Int']
  left: Scalars['Int']
  top: Scalars['Int']
  width: Scalars['Int']
}

/** HTML, вставленный в контент публикации. */
export type CustomHtmlElement = Element & {
  __typename?: 'CustomHTMLElement'
  html: Scalars['String']
  id: Scalars['ID']
  uid: Scalars['UUID']
}

export type CustomHtmlElementInput = {
  html: Scalars['String']
}

export type DataSet = {
  __typename?: 'DataSet'
  data?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Datalist = {
  __typename?: 'Datalist'
  data?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DayTheme = {
  __typename?: 'DayTheme'
  /** PUBLIC */
  figure: Figure
  /** PUBLIC */
  id: Scalars['ID']
  /** PUBLIC */
  region: Region
  /** PUBLIC */
  story: Story
  /** PUBLIC */
  title: Scalars['String']
  /** PUBLIC */
  uid: Scalars['UUID']
}

export type DeactivateAdPublicationInput = {
  publicationId: Scalars['UUID']
}

export type DeactivateAdPublicationPayload = {
  __typename?: 'DeactivateAdPublicationPayload'
  status: Scalars['Boolean']
}

export type DeactivateCollectionInput = {
  collectionUid: Scalars['UUID']
}

export type DeactivateCollectionPayload = {
  __typename?: 'DeactivateCollectionPayload'
  collection?: Maybe<Collection>
  status: Scalars['Boolean']
}

export type DeactivateStoryInput = {
  storyUid: Scalars['UUID']
}

export type DeactivateStoryPayload = {
  __typename?: 'DeactivateStoryPayload'
  status: Scalars['Boolean']
}

export type DeactivateTagInput = {
  tagUid: Scalars['UUID']
}

export type DeactivateTagPayload = {
  __typename?: 'DeactivateTagPayload'
  status: Scalars['Boolean']
  tag?: Maybe<Tag>
}

export type DeleteHotNewsBlockInput = {
  regionUid: Scalars['UUID']
}

export type DeleteHotNewsBlockPayload = {
  __typename?: 'DeleteHotNewsBlockPayload'
  status: Scalars['Boolean']
}

export type DeleteRelatedLinkInput = {
  linkId: Scalars['UUID']
}

export type DeleteRelatedLinkPayload = {
  __typename?: 'DeleteRelatedLinkPayload'
  linkId?: Maybe<Scalars['UUID']>
  status: Scalars['Boolean']
}

/** Отдел */
export type Department = {
  __typename?: 'Department'
  /** INTERNAL */
  id: Scalars['UUID']
  /** INTERNAL */
  name?: Maybe<Scalars['String']>
  /** INTERNAL */
  uid: Scalars['UUID']
}

/** Место экспорта, например Яндекс Дзен или Яндекс Новости. */
export type Destination = {
  __typename?: 'Destination'
  /** INTERNAL */
  createdAt?: Maybe<Scalars['DateTime']>
  /** INTERNAL */
  id: Scalars['ID']
  /** INTERNAL */
  slug?: Maybe<Scalars['String']>
  /** INTERNAL */
  title?: Maybe<Scalars['String']>
  /** INTERNAL */
  uid: Scalars['UUID']
  /** INTERNAL */
  updatedAt?: Maybe<Scalars['DateTime']>
}

/** Базовый тип для всех элементов контента публикации. */
export type Element = {
  id: Scalars['ID']
  uid: Scalars['UUID']
}

export type ElementUnionInput = {
  html?: Maybe<CustomHtmlElementInput>
  iframe?: Maybe<IFrameElementInput>
  image?: Maybe<ImageElementInput>
  online?: Maybe<OnlineBroadcastElementInput>
  text?: Maybe<TextElementInput>
}

export type Experiment = {
  __typename?: 'Experiment'
  createdAt: Scalars['DateTime']
  deprecatedId: Scalars['Int']
  description: Scalars['String']
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  publications?: Maybe<PublicationsConnection>
  title: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type ExperimentPublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type ExperimentsConnection = {
  __typename?: 'ExperimentsConnection'
  edges: Array<Maybe<ExperimentsEdge>>
  pageInfo: PageInfo
}

export type ExperimentsEdge = {
  __typename?: 'ExperimentsEdge'
  cursor: Scalars['String']
  node?: Maybe<Experiment>
}

export type ExperimentsFilter = {
  isActive?: Scalars['Boolean']
  /** Поиск по заголовку. При поиске параметр сортировки игнорируется. */
  search?: Maybe<Scalars['String']>
}

export enum ExperimentsSort {
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export type ExternalPublication = {
  __typename?: 'ExternalPublication'
  announcingImageUrl?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type FastNewsInput = {
  announcingFigureId: Scalars['UUID']
  announcingFigureVersionUid?: Maybe<Scalars['UUID']>
  keywords?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['DateTime']>
  regionUids?: Maybe<Array<Scalars['UUID']>>
  rubricId?: Maybe<Scalars['UUID']>
  subrubricId?: Maybe<Scalars['UUID']>
  text: Scalars['String']
  title: Scalars['String']
}

export enum FieldAccessModifier {
  /** Только для внутренних нужд компании. Требует назначения пользователю дополнительных прав доступа. */
  Internal = 'INTERNAL',
  /** Только для сервер-серверных взаимодействий. Не должен показываться пользователям. */
  MachineOnly = 'MACHINE_ONLY',
  /** Публично доступено, даже без аутентификации. */
  Public = 'PUBLIC',
  /** Публично доступено, но только зарегистрированным и аутентифицированным пользователям. */
  PublicRegistered = 'PUBLIC_REGISTERED'
}

export type Figure = {
  __typename?: 'Figure'
  alt: Scalars['String']
  author: Scalars['String']
  createdAt: Scalars['DateTime']
  cropperPreview: Image
  description: Scalars['String']
  hasDiagonalWatermark: Scalars['Boolean']
  hasWatermark: Scalars['Boolean']
  id: Scalars['ID']
  latestVersion: FigureVersion
  sourceImage: Image
  src: Scalars['String']
  type: FigureType
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type FigureType = {
  __typename?: 'FigureType'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  /** Изображение с этим размером будет достпуно в FigureVersion.primaryProcessedImage. */
  primaryImageCode: ImageCode
  slug: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type FigureVersion = {
  __typename?: 'FigureVersion'
  createdAt: Scalars['DateTime']
  crop: Crop
  figure: Figure
  hasDiagonalWatermark: Scalars['Boolean']
  hasWatermark: Scalars['Boolean']
  id: Scalars['ID']
  /** Основое обработанное изображение с кодом, указанным в FigureType.primaryImageCode. */
  primaryProcessedImage?: Maybe<Image>
  /** Все обработанные изображения. */
  processedImages: Array<Image>
  uid: Scalars['UUID']
}

export type HotNewsBlock = {
  __typename?: 'HotNewsBlock'
  /** INTERNAL */
  clickCount: Scalars['Int']
  /** INTERNAL */
  createdAt: Scalars['DateTime']
  /** PUBLIC */
  id: Scalars['ID']
  /** PUBLIC */
  region: Region
  /** PUBLIC */
  subtitle: Scalars['String']
  /** PUBLIC */
  title: Scalars['String']
  /** PUBLIC */
  uid: Scalars['UUID']
  /** INTERNAL */
  updatedAt: Scalars['DateTime']
  /** PUBLIC */
  url: Scalars['String']
}

/** <iframe> элемент, вставленный в контент публикации. */
export type IFrameElement = Element & {
  __typename?: 'IFrameElement'
  allow?: Maybe<Scalars['String']>
  allowFullscreen?: Maybe<Scalars['Boolean']>
  frameborder?: Maybe<Scalars['Boolean']>
  height?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  src: Scalars['String']
  style?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uid: Scalars['UUID']
  width?: Maybe<Scalars['Int']>
}

export type IFrameElementInput = {
  allow?: Maybe<Scalars['String']>
  allowFullscreen?: Maybe<Scalars['Boolean']>
  frameborder?: Maybe<Scalars['Boolean']>
  height?: Maybe<Scalars['Int']>
  src: Scalars['String']
  style?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

export type Image = {
  __typename?: 'Image'
  code: ImageCode
  size: ImageSize
  url: Scalars['String']
}

export enum ImageCode {
  CropperPreview = 'CROPPER_PREVIEW',
  L = 'L',
  M = 'M',
  S = 'S',
  Sl = 'SL',
  Sm = 'SM',
  Social = 'SOCIAL',
  Source = 'SOURCE'
}

/** Картинка вставленная в контент публикации. */
export type ImageElement = Element & {
  __typename?: 'ImageElement'
  /**
   * Текстовое описание того, что изображено на картинке.
   * Может отбражаться вместо картинки или зачитываться вслух специальными браузерами.
   */
  alt: Scalars['String']
  /** Автор картинки (фотограф или источник, откуда взяли картинку). */
  author: Scalars['String']
  /** Подпись под картинкой. */
  description: Scalars['String']
  figureVersion?: Maybe<FigureVersion>
  id: Scalars['ID']
  /** Отображается во всю ширину контентной области новости? */
  isFullWidth: Scalars['Boolean']
  uid: Scalars['UUID']
}

export type ImageElementInput = {
  /**
   * Текстовое описание того, что изображено на картинке.
   * Может отбражаться вместо картинки или зачитываться вслух специальными браузерами.
   */
  alt: Scalars['String']
  /** Автор картинки (фотограф или источник, откуда взяли картинку). */
  author: Scalars['String']
  /** Подпись под картинкой. */
  description: Scalars['String']
  figureVersionId: Scalars['UUID']
  /** Отображается во всю ширину контентной области новости? */
  isFullWidth: Scalars['Boolean']
}

export type ImageSize = {
  __typename?: 'ImageSize'
  height: Scalars['Int']
  width: Scalars['Int']
}

export type InsideCard = {
  __typename?: 'InsideCard'
  figure?: Maybe<Figure>
  gradientColor: Scalars['String']
  publication: Publication
  subtitle: Scalars['String']
  title: Scalars['String']
}

export type InsideCardTemplate = {
  __typename?: 'InsideCardTemplate'
  createdAt: Scalars['DateTime']
  figure?: Maybe<Figure>
  gradientColor: Scalars['String']
  id: Scalars['ID']
  region: Region
  subtitleExample: Scalars['String']
  titleExample: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type LentaConnection = {
  __typename?: 'LentaConnection'
  edges: Array<LentaEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type LentaEdge = {
  __typename?: 'LentaEdge'
  cursor: Scalars['String']
  node: LentaItem
}

export type LentaFilter = {
  regionId?: Maybe<Scalars['UUID']>
}

export type LentaItem = {
  __typename?: 'LentaItem'
  /** ID элемента ленты */
  id: Scalars['ID']
  /** Публикация помечена как важная? */
  isImportant: Scalars['Boolean']
  /** Дата публикации */
  publishedAt: Scalars['DateTime']
  /** Источник, откуда взята публикация, например "momenty", "uraru", "uraru-old" */
  source: Scalars['String']
  /** Заголовок публикации */
  title: Scalars['String']
  /** URL публикации */
  url: Scalars['String']
}

export enum LentaSort {
  PublishedAtDesc = 'PUBLISHED_AT_DESC'
}

export type ModerateCommentsInput = {
  /** Список id "хороших" комментариев, которые нужно опубликовать. */
  commentUidsToPublish?: Array<Scalars['UUID']>
  /** Список id "плохих" комментариев, которые нужно заблокировать. */
  commentUidsToReject?: Array<Scalars['UUID']>
}

export type ModerateCommentsPayload = {
  __typename?: 'ModerateCommentsPayload'
  status: Scalars['Boolean']
}

export type MoveOverviewBlockInput = {
  /** ID блока */
  overviewBlockId: Scalars['UUID']
  /**
   * Смещение, относительно вершины стека.
   * У вершины 0, у следующего элемета 1 и т.д.
   */
  position: Scalars['Int']
}

export type MoveOverviewBlockPayload = {
  __typename?: 'MoveOverviewBlockPayload'
  blocks?: Maybe<Array<OverviewBlock>>
  status: Scalars['Boolean']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Включить отображение рекламной новости на сайте. */
  activateAdPublication?: Maybe<ActivateAdPublicationPayload>
  /** Снять с коллекции пометку "удалена". */
  activateCollection?: Maybe<ActivateCollectionPayload>
  /** Включить отображение на сайте. */
  activateStory?: Maybe<ActivateStoryPayload>
  /** Включить отображение на сайте. */
  activateTag?: Maybe<ActivateTagPayload>
  /** INTERNAL */
  addAutomoderatorDictionaryEntry?: Maybe<AddAutomoderatorDictionaryEntryPayload>
  /** Добавить публикацию в "Картину дня" из списка новостей. */
  addPublicationToOverviewFromPublicationsList?: Maybe<AddPublicationToOverviewFromPublicationsListPayload>
  /** Добавить публикацию в "Главное за день" из списка новостей. */
  addPublicationToTopNewsFromPublicationsList?: Maybe<AddPublicationToTopNewsFromPublicationsListPayload>
  /**
   * Добавить публикации в коллекцию.
   * Повторное добавление ранее добавленной публикации не приводит к ошибке.
   */
  addPublicationsToCollection?: Maybe<AddPublicationsToCollectionPayload>
  /**
   * Добавить публикации в эксперимент.
   * Повторное добавление ранее добавленной публикации не приводит к ошибке.
   */
  addPublicationsToExperiment?: Maybe<AddPublicationsToExperimentPayload>
  /**
   * Добавить публикации в национальный проект.
   * Повторное добавление ранее добавленной публикации не приводит к ошибке.
   */
  addPublicationsToNationalProject?: Maybe<AddPublicationsToNationalProjectPayload>
  /**
   * Добавить публикации в сюжет.
   * Повторное добавление ранее добавленной публикации не приводит к ошибке.
   */
  addPublicationsToStory?: Maybe<AddPublicationsToStoryPayload>
  /**
   * Добавить публикации в тег.
   * Повторное добавление ранее добавленной публикации не приводит к ошибке.
   */
  addPublicationsToTag?: Maybe<AddPublicationsToTagPayload>
  cloneFigure?: Maybe<CloneFigurePayload>
  /**
   * INTERNAL<br>
   * Сменить тип публикации на "статья".
   */
  convertPublicationToArticle?: Maybe<ConvertPublicationToArticlePayload>
  createBadWord?: Maybe<BadWords>
  /** Создать коллекцию. */
  createCollection?: Maybe<CreateCollectionPayload>
  /**
   * PUBLIC<br>
   * Создать коментарий.
   */
  createComment?: Maybe<CreateCommentPayload>
  createFastNews?: Maybe<Publication>
  createFigure?: Maybe<CreateFigurePayload>
  createFigureVersion?: Maybe<CreateFigureVersionPayload>
  createOfficialWord?: Maybe<OfficialWord>
  /**
   * Создать блок картины дня.
   * Созданный блок добавляется на вершину стека для указанного региона.
   */
  createOverviewBlock?: Maybe<CreateOverviewBlockPayload>
  createOverviewBlockDraft?: Maybe<OverviewBlockDraft>
  /** Создать черновик публикации. Возвращает пустую публикацию с ее id и userId. */
  createPublicationDraft?: Maybe<CreatePublicationDraftPayload>
  /** Создать ссылку на связанную публикацию. */
  createRelatedLink?: Maybe<CreateRelatedLinkPayload>
  createSocialMediaFigureVersion?: Maybe<CreateSocialMediaFigureVersionPayload>
  /**
   * Создать сюжет.
   * Созданный сюжет сразу же отображается на сайте.
   */
  createStory?: Maybe<CreateStoryPayload>
  /** Создать тег. */
  createTag?: Maybe<CreateTagPayload>
  createTitleExample?: Maybe<TitleExample>
  /** Выключить отображение рекламной новости на сайте. */
  deactivateAdPublication?: Maybe<DeactivateAdPublicationPayload>
  /** Пометить коллекцию как удаленную. */
  deactivateCollection?: Maybe<DeactivateCollectionPayload>
  /** Выключить отображение на сайте. */
  deactivateStory?: Maybe<DeactivateStoryPayload>
  /** Выключить отображение на сайте. */
  deactivateTag?: Maybe<DeactivateTagPayload>
  deleteBadWord?: Maybe<BadWords>
  /**
   * INTERNAL<br>
   * Убрать блок горячей новости для одного региона.
   */
  deleteHotNewsBlock?: Maybe<DeleteHotNewsBlockPayload>
  deleteOfficialWord?: Maybe<OfficialWord>
  deleteOverviewBlockDraft: Scalars['Boolean']
  deleteOverviewBlocksDraftByPublicationId: Scalars['Boolean']
  /** Удалить ссылку на связанную публикацию. */
  deleteRelatedLink?: Maybe<DeleteRelatedLinkPayload>
  deleteTitleExample?: Maybe<TitleExample>
  /**
   * INTERNAL<br>
   * Пометить комментарии как отмодерированные.
   */
  moderateComments?: Maybe<ModerateCommentsPayload>
  /** Переместить блок картины дня на номер относительно вершины стека. */
  moveOverviewBlock?: Maybe<MoveOverviewBlockPayload>
  publishOverviewBlocksDraft?: Maybe<Array<Maybe<OverviewBlock>>>
  /** Установить статус публикации в "опубликована". */
  publishPublication?: Maybe<PublishPublicationPayload>
  /** INTERNAL */
  removeAutomoderatorDictionaryEntry?: Maybe<RemoveAutomoderatorDictionaryEntryPayload>
  /** Убрать блок картины дня. */
  removeOverviewBlock?: Maybe<RemoveOverviewBlockPayload>
  /**
   * Убрать публикации из коллекции.
   * Попытка убрать из коллекции публикацию, которая в ней отсутствует,
   * не приводит к ошибке.
   */
  removePublicationsFromCollection?: Maybe<RemovePublicationsFromCollectionPayload>
  /**
   * Убрать публикации из эксперимента.
   * Попытка убрать из эксперимента публикацию, которая в нем отсутствует,
   * не приводит к ошибке.
   */
  removePublicationsFromExperiment?: Maybe<RemovePublicationsFromExperimentPayload>
  /**
   * Убрать публикации из национального проекта.
   * Попытка убрать из национального проекта публикацию, которая в нем отсутствует,
   * не приводит к ошибке.
   */
  removePublicationsFromNationalProject?: Maybe<RemovePublicationsFromNationalProjectPayload>
  /**
   * Убрать публикации из сюжета.
   * Попытка убрать из сюжета публикацию, которая в нем отсутствует,
   * не приводит к ошибке.
   */
  removePublicationsFromStory?: Maybe<RemovePublicationsFromStoryPayload>
  /**
   * Убрать публикации из тега.
   * Попытка убрать из тега публикацию, которая в нем отсутствует,
   * не приводит к ошибке.
   */
  removePublicationsFromTag?: Maybe<RemovePublicationsFromTagPayload>
  /** Сбросить рекламные параметры для публикации. */
  resetAdPublicationParameters?: Maybe<ResetAdPublicationParametersPayload>
  /** Создать или обновить блок для раздела "Главное за день" главной страницы. */
  saveTopNewsBlock?: Maybe<SaveTopNewsBlockPayload>
  /** Установить статус публикации в "на проверку". */
  sendPublicationForReview?: Maybe<SendPublicationForReviewPayload>
  /** Установить рекламные параметры для публикации. */
  setAdPublicationParameters?: Maybe<SetAdPublicationParametersPayload>
  setCheckEditor?: Maybe<Publication>
  /** Установить статус проверки бильд-редактором */
  setCheckingPhotoEditor?: Maybe<Publication>
  /** Установить статус публикации в "снята с публикации". */
  unpublishPublication?: Maybe<UnpublishPublicationPayload>
  /**
   * INTERNAL<br>
   * Обновить банер для одного региона.
   */
  updateBanner?: Maybe<UpdateBannerPayload>
  /**
   * INTERNAL<br>
   * Обновить банер для всех регионов.
   */
  updateBannerForAllRegions?: Maybe<UpdateBannerForAllRegionsPayload>
  /** Обновить коллекцию. */
  updateCollection?: Maybe<UpdateCollectionPayload>
  /**
   * INTERNAL<br>
   * Установить сюжет как тему дня. Убрать тему дня, если передан null.
   */
  updateDayTheme?: Maybe<UpdateDayThemePayload>
  /** @deprecated Используйте createFigureVersion */
  updateFigure?: Maybe<UpdateFigurePayload>
  /**
   * INTERNAL<br>
   * Установить или обновить блок горячей новости для одного региона.
   *
   * Если url изменилась по сравнению со старой версией, то создается
   * новый блок с нулевым числом кликов.
   */
  updateHotNewsBlock?: Maybe<UpdateHotNewsBlockPayload>
  /**
   * INTERNAL<br>
   * Установить или обновить блок горячей новости для всех регионов.
   *
   * Если url изменилась по сравнению со старой версией, то создаются
   * новые блоки с нулевым числом кликов.
   */
  updateHotNewsBlockForAllRegions?: Maybe<UpdateHotNewsBlockForAllRegionsPayload>
  /** Обновить блок картины дня. */
  updateOverviewBlock?: Maybe<UpdateOverviewBlockPayload>
  /** Обновить параметры публикации с правой панели. Все поля в инпуте опциональны. */
  updatePublication?: Maybe<UpdatePublicationPayload>
  /**
   * Обновить контент (список элементов) публикации.
   * На вход требется полный список всех элементов.
   */
  updatePublicationContent?: Maybe<UpdatePublicationContentPayload>
  /** INTERNAL */
  updatePublicationExport?: Maybe<UpdatePublicationExportPayload>
  /** Обновить сюжет (возможно частично). */
  updateStory?: Maybe<UpdateStoryPayload>
  /** Обновить тег. */
  updateTag?: Maybe<UpdateTagPayload>
  /** Обновить подборку "Главное за день" для указанного региона. */
  updateTopNewsSelection?: Maybe<UpdateTopNewsSelectionPayload>
}

export type MutationActivateAdPublicationArgs = {
  data: ActivateAdPublicationInput
}

export type MutationActivateCollectionArgs = {
  data: ActivateCollectionInput
}

export type MutationActivateStoryArgs = {
  data: ActivateStoryInput
}

export type MutationActivateTagArgs = {
  data: ActivateTagInput
}

export type MutationAddAutomoderatorDictionaryEntryArgs = {
  data: AddAutomoderatorDictionaryEntryInput
}

export type MutationAddPublicationToOverviewFromPublicationsListArgs = {
  data: AddPublicationToOverviewFromPublicationsListInput
}

export type MutationAddPublicationToTopNewsFromPublicationsListArgs = {
  data: AddPublicationToTopNewsFromPublicationsListInput
}

export type MutationAddPublicationsToCollectionArgs = {
  data: AddPublicationsToCollectionInput
}

export type MutationAddPublicationsToExperimentArgs = {
  data: AddPublicationsToExperimentInput
}

export type MutationAddPublicationsToNationalProjectArgs = {
  data: AddPublicationsToNationalProjectInput
}

export type MutationAddPublicationsToStoryArgs = {
  data: AddPublicationsToStoryInput
}

export type MutationAddPublicationsToTagArgs = {
  data: AddPublicationsToTagInput
}

export type MutationCloneFigureArgs = {
  data: CloneFigureInput
}

export type MutationConvertPublicationToArticleArgs = {
  data: ConvertPublicationToArticleInput
}

export type MutationCreateBadWordArgs = {
  data: CreateBadWordInput1
}

export type MutationCreateCollectionArgs = {
  data: CreateCollectionInput
}

export type MutationCreateCommentArgs = {
  data: CreateCommentInput
}

export type MutationCreateFastNewsArgs = {
  data: FastNewsInput
}

export type MutationCreateFigureArgs = {
  data: CreateFigureInput
}

export type MutationCreateFigureVersionArgs = {
  data: CreateFigureVersionInput
}

export type MutationCreateOfficialWordArgs = {
  data: CreateOfficialWordInput
}

export type MutationCreateOverviewBlockArgs = {
  data: CreateOverviewBlockInput
}

export type MutationCreateOverviewBlockDraftArgs = {
  data: CreateOverviewBlockDraftInput
}

export type MutationCreatePublicationDraftArgs = {
  data: CreatePublicationDraftInput
}

export type MutationCreateRelatedLinkArgs = {
  data: CreateRelatedLinkInput
}

export type MutationCreateSocialMediaFigureVersionArgs = {
  data: CreateSocialMediaFigureVersionInput
}

export type MutationCreateStoryArgs = {
  data: CreateStoryInput
}

export type MutationCreateTagArgs = {
  data: CreateTagInput
}

export type MutationCreateTitleExampleArgs = {
  data: CreateBadWordInput
}

export type MutationDeactivateAdPublicationArgs = {
  data: DeactivateAdPublicationInput
}

export type MutationDeactivateCollectionArgs = {
  data?: Maybe<DeactivateCollectionInput>
}

export type MutationDeactivateStoryArgs = {
  data: DeactivateStoryInput
}

export type MutationDeactivateTagArgs = {
  data: DeactivateTagInput
}

export type MutationDeleteBadWordArgs = {
  id: Scalars['UUID']
}

export type MutationDeleteHotNewsBlockArgs = {
  data: DeleteHotNewsBlockInput
}

export type MutationDeleteOfficialWordArgs = {
  id: Scalars['UUID']
}

export type MutationDeleteOverviewBlockDraftArgs = {
  id: Scalars['UUID']
}

export type MutationDeleteOverviewBlocksDraftByPublicationIdArgs = {
  publicationId: Scalars['UUID']
}

export type MutationDeleteRelatedLinkArgs = {
  data: DeleteRelatedLinkInput
}

export type MutationDeleteTitleExampleArgs = {
  id: Scalars['UUID']
}

export type MutationModerateCommentsArgs = {
  data: ModerateCommentsInput
}

export type MutationMoveOverviewBlockArgs = {
  data: MoveOverviewBlockInput
}

export type MutationPublishOverviewBlocksDraftArgs = {
  data: PublishOverviewBlocksDraftInput
}

export type MutationPublishPublicationArgs = {
  data: PublishPublicationInput
}

export type MutationRemoveAutomoderatorDictionaryEntryArgs = {
  data: RemoveAutomoderatorDictionaryEntryInput
}

export type MutationRemoveOverviewBlockArgs = {
  data: RemoveOverviewBlockInput
}

export type MutationRemovePublicationsFromCollectionArgs = {
  data: RemovePublicationsFromCollectionInput
}

export type MutationRemovePublicationsFromExperimentArgs = {
  data: RemovePublicationsFromExperimentInput
}

export type MutationRemovePublicationsFromNationalProjectArgs = {
  data: RemovePublicationsFromNationalProjectInput
}

export type MutationRemovePublicationsFromStoryArgs = {
  data: RemovePublicationsFromStoryInput
}

export type MutationRemovePublicationsFromTagArgs = {
  data: RemovePublicationsFromTagInput
}

export type MutationResetAdPublicationParametersArgs = {
  data: ResetAdPublicationParametersInput
}

export type MutationSaveTopNewsBlockArgs = {
  data: SaveTopNewsBlockInput
}

export type MutationSendPublicationForReviewArgs = {
  data: SendPublicationForReviewInput
}

export type MutationSetAdPublicationParametersArgs = {
  data: SetAdPublicationParametersInput
}

export type MutationSetCheckEditorArgs = {
  data?: Maybe<SetCheckEditInput>
}

export type MutationSetCheckingPhotoEditorArgs = {
  data: SetCheckingPhotoEditorInput
}

export type MutationUnpublishPublicationArgs = {
  data: UnpublishPublicationInput
}

export type MutationUpdateBannerArgs = {
  data: UpdateBannerInput
}

export type MutationUpdateBannerForAllRegionsArgs = {
  data: UpdateBannerForAllRegionsInput
}

export type MutationUpdateCollectionArgs = {
  data: UpdateCollectionInput
}

export type MutationUpdateDayThemeArgs = {
  data: UpdateDayThemeInput
}

export type MutationUpdateFigureArgs = {
  data: UpdateFigureInput
}

export type MutationUpdateHotNewsBlockArgs = {
  data: UpdateHotNewsBlockInput
}

export type MutationUpdateHotNewsBlockForAllRegionsArgs = {
  data: UpdateHotNewsBlockForAllRegionsInput
}

export type MutationUpdateOverviewBlockArgs = {
  data: UpdateOverviewBlockInput
}

export type MutationUpdatePublicationArgs = {
  data: UpdatePublicationInput
}

export type MutationUpdatePublicationContentArgs = {
  data: UpdatePublicationContentInput
}

export type MutationUpdatePublicationExportArgs = {
  data: UpdatePublicationExportInput
}

export type MutationUpdateStoryArgs = {
  data: UpdateStoryInput
}

export type MutationUpdateTagArgs = {
  data: UpdateTagInput
}

export type MutationUpdateTopNewsSelectionArgs = {
  data: UpdateTopNewsSelectionInput
}

export type NationalProject = {
  __typename?: 'NationalProject'
  createdAt: Scalars['DateTime']
  deprecatedId: Scalars['Int']
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  publications?: Maybe<PublicationsConnection>
  title: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type NationalProjectPublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type NationalProjectFilter = {
  isActive?: Scalars['Boolean']
  /** Поиск по заголовку. При поиске параметр сортировки игнорируется. */
  search?: Maybe<Scalars['String']>
}

export enum NationalProjectSort {
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export type NationalProjectsConnection = {
  __typename?: 'NationalProjectsConnection'
  edges: Array<Maybe<NationalProjectsEdge>>
  pageInfo: PageInfo
}

export type NationalProjectsEdge = {
  __typename?: 'NationalProjectsEdge'
  cursor: Scalars['String']
  node?: Maybe<NationalProject>
}

export type OfficialWord = {
  __typename?: 'OfficialWord'
  id: Scalars['UUID']
  officialWord?: Maybe<Scalars['String']>
}

/** Дополнительный текст онлайн трансляции вставленный в контент публикации. */
export type OnlineBroadcastElement = Element & {
  __typename?: 'OnlineBroadcastElement'
  id: Scalars['ID']
  text: Scalars['String']
  time: Scalars['DateTime']
  uid: Scalars['UUID']
}

export type OnlineBroadcastElementInput = {
  text: Scalars['String']
  time: Scalars['DateTime']
}

export type OverviewBlock = {
  __typename?: 'OverviewBlock'
  clickCount: Scalars['Int']
  createdAt: Scalars['DateTime']
  figureVersion?: Maybe<FigureVersion>
  id: Scalars['ID']
  position: Scalars['Int']
  region: Region
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
  url: Scalars['String']
}

export type OverviewBlockDraft = {
  __typename?: 'OverviewBlockDraft'
  createdAt: Scalars['DateTime']
  id: Scalars['UUID']
  position: Scalars['Int']
  publication: Publication
  region: Region
  updatedAt: Scalars['DateTime']
}

export type OverviewBlockHistory = {
  __typename?: 'OverviewBlockHistory'
  action?: Maybe<Scalars['String']>
  author?: Maybe<Scalars['String']>
  block_id?: Maybe<Scalars['UUID']>
  figure_id?: Maybe<Scalars['UUID']>
  figure_version_id?: Maybe<Scalars['UUID']>
  id?: Maybe<Scalars['UUID']>
  position?: Maybe<Scalars['Int']>
  region_id?: Maybe<Scalars['UUID']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  url?: Maybe<Scalars['String']>
}

export type OverviewBlocksConnection = {
  __typename?: 'OverviewBlocksConnection'
  edges: Array<OverviewBlocksEdge>
  pageInfo: PageInfo
}

export type OverviewBlocksEdge = {
  __typename?: 'OverviewBlocksEdge'
  cursor: Scalars['String']
  node?: Maybe<OverviewBlock>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

/** Должность */
export type Position = {
  __typename?: 'Position'
  /** INTERNAL */
  id: Scalars['UUID']
  /** INTERNAL */
  name?: Maybe<Scalars['String']>
  /** INTERNAL */
  uid: Scalars['UUID']
}

export type Publication = {
  __typename?: 'Publication'
  adParameters?: Maybe<AdPublicationParameters>
  ampUrl?: Maybe<Scalars['String']>
  announceImage: AnnounceImage
  /** Список авторов, в том виде, в котором он отображается для читателей. */
  authors: Array<Author>
  badwords?: Maybe<Scalars['Boolean']>
  canonicalUrl?: Maybe<Scalars['String']>
  checkEditor?: Maybe<Scalars['Boolean']>
  checkingPhotoEditor?: Maybe<Scalars['Boolean']>
  collections?: Maybe<Array<Maybe<Collection>>>
  /** PUBLIC */
  comments: Array<Comment>
  /** PUBLIC */
  commentsCount: Scalars['Int']
  content: PublicationContent
  createdAt?: Maybe<Scalars['DateTime']>
  /** Пользователь, создавший публикацию. */
  creator: User
  deprecatedId?: Maybe<Scalars['Int']>
  /** INTERNAL */
  destinations?: Maybe<Array<Maybe<Destination>>>
  experiment?: Maybe<Experiment>
  hasOverviewBlock: Scalars['Boolean']
  hasTopNewsBlock?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  /**
   * Для инсайда приходит список карточек (возможно пустой).
   * Для не инсайда приходит null.
   */
  insidesCarousel?: Maybe<Array<InsideCard>>
  isAutomaticCommentsDisabled?: Maybe<Scalars['Boolean']>
  isCommentsEnabled?: Maybe<Scalars['Boolean']>
  isImportant?: Maybe<Scalars['Boolean']>
  isInside: Scalars['Boolean']
  isInvert?: Maybe<Scalars['Boolean']>
  isOnlineBroadcast?: Maybe<Scalars['Boolean']>
  keywords: Scalars['String']
  nationalProject?: Maybe<NationalProject>
  ogDescription?: Maybe<Scalars['String']>
  ogTitle?: Maybe<Scalars['String']>
  oldAdminUrl?: Maybe<Scalars['String']>
  overviewDescription?: Maybe<Scalars['String']>
  overviewFigure?: Maybe<FigureVersion>
  overviewFigureId?: Maybe<Scalars['UUID']>
  overviewFigureVersionId?: Maybe<Scalars['UUID']>
  overviewTitle?: Maybe<Scalars['String']>
  pdaUrl?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedAtDate?: Maybe<Scalars['Date']>
  redirectUrl?: Maybe<Scalars['String']>
  regions?: Maybe<Array<Maybe<Region>>>
  /** Ссылки на связанные публикации */
  relatedLinks: Array<RelatedLink>
  rubric?: Maybe<Rubric>
  /** SEO заголовок. null, если не задан. */
  seoTitle?: Maybe<Scalars['String']>
  /** Картинка для соцсетей. */
  socialMediaImage: SocialMediaImage
  specProjectUrl?: Maybe<Scalars['String']>
  status: PublicationStatus
  story?: Maybe<Story>
  subrubric?: Maybe<Subrubric>
  subscriptionBlock?: Maybe<SubscriptionBlock>
  subtitle?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Tag>>>
  title?: Maybe<Scalars['String']>
  type?: Maybe<PublicationType>
  typeSlug?: Maybe<Scalars['String']>
  uid: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
  /** @deprecated Use canonicalUrl instead */
  url?: Maybe<Scalars['String']>
  /** Дзен заголовок */
  yandexDzenTitle?: Maybe<Scalars['String']>
  /** INTERNAL */
  yandexNewsVideos: Array<PublicationYandexNewsVideo>
  yandexTitle?: Maybe<Scalars['String']>
  /** INTERNAL */
  yandexZenCategories: Array<YandexZenCategory>
}

export type PublicationContent = {
  __typename?: 'PublicationContent'
  elements: Array<Element>
}

export enum PublicationStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Review = 'REVIEW',
  Unpublished = 'UNPUBLISHED'
}

export type PublicationType = {
  __typename?: 'PublicationType'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  publications: PublicationsConnection
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uid: Scalars['UUID']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type PublicationTypePublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type PublicationVersion = {
  __typename?: 'PublicationVersion'
  actionOnModel?: Maybe<Scalars['String']>
  changed?: Maybe<Scalars['DateTime']>
  data?: Maybe<DataSet>
  dataToString?: Maybe<Scalars['String']>
  ipaddress?: Maybe<Scalars['String']>
  recordId?: Maybe<Scalars['UUID']>
  uid?: Maybe<Scalars['UUID']>
  user?: Maybe<Scalars['String']>
}

/** Видео для Яндекс Новостей. */
export type PublicationYandexNewsVideo = {
  __typename?: 'PublicationYandexNewsVideo'
  /** INTERNAL */
  id: Scalars['ID']
  /** INTERNAL */
  thumbnail: Figure
  /** INTERNAL */
  title: Scalars['String']
  /** INTERNAL */
  uid: Scalars['UUID']
  /** INTERNAL */
  uri: Scalars['String']
}

export type PublicationYandexNewsVideoInput = {
  thumbnailUid: Scalars['UUID']
  title: Scalars['String']
  uri: Scalars['String']
}

export type PublicationsConnection = {
  __typename?: 'PublicationsConnection'
  edges: Array<Maybe<PublicationsEdge>>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type PublicationsEdge = {
  __typename?: 'PublicationsEdge'
  cursor: Scalars['String']
  node?: Maybe<Publication>
}

export type PublicationsFilter = {
  authorUid?: Maybe<Scalars['UUID']>
  checkEditor?: Maybe<Scalars['Boolean']>
  checkPhotoEditor?: Maybe<Scalars['Boolean']>
  collectionUid?: Maybe<Scalars['UUID']>
  datePublished?: Maybe<Scalars['DateTime']>
  datePublishedFrom?: Maybe<Scalars['DateTime']>
  datePublishedTo?: Maybe<Scalars['DateTime']>
  experimentUid?: Maybe<Scalars['UUID']>
  nationalProjectUid?: Maybe<Scalars['UUID']>
  photoFromOutPhotobank?: Maybe<Scalars['Boolean']>
  regionUid?: Maybe<Scalars['UUID']>
  rubric?: Maybe<Array<Maybe<Scalars['UUID']>>>
  /** Поиск по заголовку */
  search?: Maybe<Scalars['String']>
  status?: Maybe<PublicationStatus>
  storyUid?: Maybe<Scalars['UUID']>
  subrubric?: Maybe<Array<Maybe<Scalars['UUID']>>>
  tagUid?: Maybe<Scalars['UUID']>
  typeSlug?: Maybe<Scalars['String']>
  typeUids?: Array<Scalars['UUID']>
}

export enum PublicationsSort {
  AuthorAtAsc = 'AUTHOR_AT_ASC',
  AuthorAtDesc = 'AUTHOR_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeprecatedIdAtAsc = 'DEPRECATED_ID_AT_ASC',
  DeprecatedIdAtDesc = 'DEPRECATED_ID_AT_DESC',
  PublishedAtAsc = 'PUBLISHED_AT_ASC',
  PublishedAtDesc = 'PUBLISHED_AT_DESC',
  RubricAtAsc = 'RUBRIC_AT_ASC',
  RubricAtDesc = 'RUBRIC_AT_DESC',
  SeoTitleAtAsc = 'SEO_TITLE_AT_ASC',
  SeoTitleAtDesc = 'SEO_TITLE_AT_DESC',
  TitleAtAsc = 'TITLE_AT_ASC',
  TitleAtDesc = 'TITLE_AT_DESC'
}

export type PublishOverviewBlocksDraftInput = {
  figureVersionId: Scalars['UUID']
  publicationId: Scalars['UUID']
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
  url: Scalars['String']
}

export type PublishPublicationInput = {
  publicationUid: Scalars['UUID']
  /**
   * Дата публикации
   * undefined | null - если дата и время публикации не были ранее установлены,
   *     то опубликовать с текущими датой и временем,
   *     иначе опубликовать с ранее установленными датой и временем
   * ISO8601 - опубликовать с указанными датой и временем
   */
  publishedAt?: Maybe<Scalars['DateTime']>
}

export type PublishPublicationPayload = {
  __typename?: 'PublishPublicationPayload'
  publication?: Maybe<Publication>
  status: Scalars['Boolean']
}

export type Query = {
  __typename?: 'Query'
  /** INTERNAL */
  accessLevels?: Maybe<Array<Maybe<AccessLevel>>>
  /** Рекламные публикации. */
  adPublications?: Maybe<Array<Publication>>
  /** PUBLIC */
  announceimageVersion?: Maybe<Array<Maybe<AnnounceimageVersion>>>
  author?: Maybe<Author>
  authors?: Maybe<Array<Author>>
  /**
   * INTERNAL<br>
   * Список слов из словаря автомодерации.
   */
  automoderatorDictionaryEntries?: Maybe<AutomoderatorDictionaryEntriesConnection>
  badword?: Maybe<BadWords>
  badwords?: Maybe<Array<BadWords>>
  /**
   * PUBLIC<br>
   * Банер для указанного региона.
   */
  banner?: Maybe<Banner>
  collection?: Maybe<Collection>
  collections?: Maybe<CollectionsConnection>
  /**
   * INTERNAL<br>
   * Список комментариев для модерации.
   */
  comments?: Maybe<CommentsConnection>
  /** INTERNAL */
  companies?: Maybe<Array<Maybe<Company>>>
  /**
   * PUBLIC<br>
   * Тема дня или null, если не установлена.
   */
  dayTheme?: Maybe<DayTheme>
  /** INTERNAL */
  departments?: Maybe<Array<Maybe<Department>>>
  /** INTERNAL */
  destination?: Maybe<Destination>
  /** INTERNAL */
  destinations?: Maybe<Array<Maybe<Destination>>>
  element?: Maybe<Element>
  experiment?: Maybe<Experiment>
  experiments: ExperimentsConnection
  figure?: Maybe<Figure>
  figureTypes?: Maybe<Array<FigureType>>
  figures?: Maybe<Array<Figure>>
  /**
   * PUBLIC<br>
   * Блок раздела "Горячая новость" для указанного региона или null,
   * если новость не показывается.
   */
  hotNewsBlock?: Maybe<HotNewsBlock>
  /** Список всех шаблонов карточек инсайдов, упорядоченный по регионам. */
  insideCardTemplates?: Maybe<Array<InsideCardTemplate>>
  /** Лента новостей. */
  lenta?: Maybe<LentaConnection>
  /** INTERNAL */
  listPublicationDestinations?: Maybe<Array<Maybe<Destination>>>
  nationalProject?: Maybe<NationalProject>
  nationalProjects: NationalProjectsConnection
  officialWord?: Maybe<OfficialWord>
  officialWords?: Maybe<Array<OfficialWord>>
  overviewBlockDraftOrNull?: Maybe<OverviewBlockDraft>
  overviewBlockHistory?: Maybe<Array<Maybe<OverviewBlockHistory>>>
  overviewBlockOrNull?: Maybe<OverviewBlock>
  /**
   * Блоки картины дня для указанного региона.
   * При null в after возвращает первую страницу (18 штук).
   * Для получения второй страницы передайте endCursor предыдущего
   * запроса в after.
   */
  overviewBlocks?: Maybe<OverviewBlocksConnection>
  overviewBlocksByUrlOrNull?: Maybe<Array<Maybe<OverviewBlock>>>
  overviewBlocksDraftByPublicationOrNull?: Maybe<Array<Maybe<OverviewBlockDraft>>>
  /** INTERNAL */
  positions?: Maybe<Array<Maybe<Position>>>
  publication?: Maybe<Publication>
  publicationByDeprecatedId?: Maybe<Publication>
  /**
   * Получить публикацию с указанным url.
   * Если ссылка не ведет на публикацию, то возвращает null.
   */
  publicationByUrl?: Maybe<AnyPublication>
  /** PUBLIC */
  publicationVersion?: Maybe<Array<Maybe<PublicationVersion>>>
  publications?: Maybe<PublicationsConnection>
  randomTitleExamle?: Maybe<TitleExample>
  /** PUBLIC */
  region?: Maybe<Region>
  /** PUBLIC */
  regions?: Maybe<Array<Region>>
  rubrics?: Maybe<Array<Rubric>>
  socialMediaFigureVersion?: Maybe<SocialMediaFigureVersion>
  /** INTERNAL */
  staff?: Maybe<UsersConnection>
  stories?: Maybe<StoriesConnection>
  story?: Maybe<Story>
  subrubrics?: Maybe<Array<Subrubric>>
  subscriptionBlocks?: Maybe<Array<SubscriptionBlock>>
  tag?: Maybe<Tag>
  tags: TagsConnection
  titleExample?: Maybe<TitleExample>
  titleExamples?: Maybe<Array<TitleExample>>
  /** Получение блока "Главное за день" по id  */
  topNewsBlockById?: Maybe<TopNewsBlock>
  /** Список изменений блока раздела "Главное за день", упорадоченный по дате. */
  topNewsBlockHistory?: Maybe<Array<TopNewsBlockChange>>
  /**
   * Одна подборка раздела "Главное за день" для указанного региона.
   * Блоки упорядочены по их полю position.
   */
  topNewsSelection?: Maybe<Array<TopNewsBlock>>
  /**
   * Все блоки раздела "Главное за день", которые хоть сколько-нибудь
   * показывались в указанную дату.
   * Дата по часовому поясу ЕКБ (UTC+05:00).
   */
  topNewsSelectionHistory?: Maybe<Array<TopNewsBlock>>
  /** PUBLIC_REGISTERED */
  user?: Maybe<User>
  videoEmbedUrl?: Maybe<Scalars['String']>
  /** INTERNAL */
  yandexZenCategories?: Maybe<Array<YandexZenCategory>>
}

export type QueryAdPublicationsArgs = {
  filter?: Maybe<AdPublicationsFilter>
}

export type QueryAnnounceimageVersionArgs = {
  uid: Scalars['UUID']
}

export type QueryAuthorArgs = {
  uid: Scalars['UUID']
}

export type QueryAutomoderatorDictionaryEntriesArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<AutomoderatorDictionaryEntriesFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<AutomoderatorDictionaryEntriesSort>
}

export type QueryBadwordArgs = {
  uid: Scalars['UUID']
}

export type QueryBannerArgs = {
  regionUid: Scalars['UUID']
}

export type QueryCollectionArgs = {
  uid: Scalars['UUID']
}

export type QueryCollectionsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<CollectionsFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<CollectionsSort>
}

export type QueryCommentsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<CommentsFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<CommentsSort>
}

export type QueryDayThemeArgs = {
  regionUid: Scalars['UUID']
}

export type QueryDestinationArgs = {
  uid: Scalars['UUID']
}

export type QueryElementArgs = {
  uid: Scalars['UUID']
}

export type QueryExperimentArgs = {
  uid: Scalars['UUID']
}

export type QueryExperimentsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<ExperimentsFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ExperimentsSort>
}

export type QueryFigureArgs = {
  uid: Scalars['UUID']
}

export type QueryFiguresArgs = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

export type QueryHotNewsBlockArgs = {
  regionUid: Scalars['UUID']
}

export type QueryLentaArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<LentaFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<LentaSort>
}

export type QueryListPublicationDestinationsArgs = {
  publicationUid: Scalars['UUID']
}

export type QueryNationalProjectArgs = {
  uid: Scalars['UUID']
}

export type QueryNationalProjectsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<NationalProjectFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<NationalProjectSort>
}

export type QueryOfficialWordArgs = {
  uid: Scalars['UUID']
}

export type QueryOverviewBlockDraftOrNullArgs = {
  id: Scalars['UUID']
}

export type QueryOverviewBlockHistoryArgs = {
  url: Scalars['String']
}

export type QueryOverviewBlockOrNullArgs = {
  id: Scalars['UUID']
}

export type QueryOverviewBlocksArgs = {
  after?: Maybe<Scalars['String']>
  regionId: Scalars['UUID']
}

export type QueryOverviewBlocksByUrlOrNullArgs = {
  url: Scalars['String']
}

export type QueryOverviewBlocksDraftByPublicationOrNullArgs = {
  publicationId: Scalars['UUID']
}

export type QueryPublicationArgs = {
  uid: Scalars['UUID']
}

export type QueryPublicationByDeprecatedIdArgs = {
  deprecatedId: Scalars['Int']
}

export type QueryPublicationByUrlArgs = {
  url: Scalars['String']
}

export type QueryPublicationVersionArgs = {
  uid: Scalars['UUID']
}

export type QueryPublicationsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<PublicationsFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type QueryRegionArgs = {
  uid: Scalars['UUID']
}

export type QuerySocialMediaFigureVersionArgs = {
  id: Scalars['UUID']
}

export type QueryStaffArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<UsersFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<UsersSort>
}

export type QueryStoriesArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<StoriesFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<StoriesSort>
}

export type QueryStoryArgs = {
  uid: Scalars['UUID']
}

export type QueryTagArgs = {
  uid: Scalars['UUID']
}

export type QueryTagsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  filter?: Maybe<TagsFilter>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<TagsSort>
}

export type QueryTitleExampleArgs = {
  uid: Scalars['UUID']
}

export type QueryTopNewsBlockByIdArgs = {
  blockId: Scalars['UUID']
}

export type QueryTopNewsBlockHistoryArgs = {
  topNewsBlockId: Scalars['UUID']
}

export type QueryTopNewsSelectionArgs = {
  regionId: Scalars['UUID']
}

export type QueryTopNewsSelectionHistoryArgs = {
  date: Scalars['Date']
  regionId: Scalars['UUID']
}

export type QueryUserArgs = {
  uid: Scalars['UUID']
}

export type QueryVideoEmbedUrlArgs = {
  url: Scalars['String']
}

export type Region = {
  __typename?: 'Region'
  /** INTERNAL */
  createdAt?: Maybe<Scalars['DateTime']>
  /** PUBLIC */
  deprecatedId: Scalars['Int']
  /** PUBLIC */
  description?: Maybe<Scalars['String']>
  /** PUBLIC */
  id: Scalars['ID']
  /** PUBLIC */
  position: Scalars['Int']
  publications?: Maybe<PublicationsConnection>
  requiredCountOfTopNewsBlocks: Scalars['Int']
  /** PUBLIC */
  slug: Scalars['String']
  /** PUBLIC */
  title?: Maybe<Scalars['String']>
  /** PUBLIC */
  uid: Scalars['UUID']
  /** INTERNAL */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type RegionPublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type RelatedLink = {
  __typename?: 'RelatedLink'
  id: Scalars['UUID']
  text: Scalars['String']
  url: Scalars['String']
}

export type RemoveAutomoderatorDictionaryEntryInput = {
  id: Scalars['ID']
}

export type RemoveAutomoderatorDictionaryEntryPayload = {
  __typename?: 'RemoveAutomoderatorDictionaryEntryPayload'
  status: Scalars['Boolean']
}

export type RemoveOverviewBlockInput = {
  /** ID блока */
  overviewBlockId: Scalars['UUID']
}

export type RemoveOverviewBlockPayload = {
  __typename?: 'RemoveOverviewBlockPayload'
  blocks?: Maybe<Array<OverviewBlock>>
  status: Scalars['Boolean']
}

export type RemovePublicationsFromCollectionInput = {
  collectionUid: Scalars['UUID']
  publicationUids: Array<Scalars['UUID']>
}

export type RemovePublicationsFromCollectionPayload = {
  __typename?: 'RemovePublicationsFromCollectionPayload'
  collection?: Maybe<Collection>
  status: Scalars['Boolean']
}

export type RemovePublicationsFromExperimentInput = {
  experimentUid: Scalars['UUID']
  publicationUids: Array<Scalars['UUID']>
}

export type RemovePublicationsFromExperimentPayload = {
  __typename?: 'RemovePublicationsFromExperimentPayload'
  experiment?: Maybe<Experiment>
  status: Scalars['Boolean']
}

export type RemovePublicationsFromNationalProjectInput = {
  nationalProjectUid: Scalars['UUID']
  publicationUids: Array<Scalars['UUID']>
}

export type RemovePublicationsFromNationalProjectPayload = {
  __typename?: 'RemovePublicationsFromNationalProjectPayload'
  nationalProject?: Maybe<NationalProject>
  status: Scalars['Boolean']
}

export type RemovePublicationsFromStoryInput = {
  publicationUids: Array<Scalars['UUID']>
  storyUid: Scalars['UUID']
}

export type RemovePublicationsFromStoryPayload = {
  __typename?: 'RemovePublicationsFromStoryPayload'
  status: Scalars['Boolean']
  story?: Maybe<Story>
}

export type RemovePublicationsFromTagInput = {
  publicationUids: Array<Scalars['UUID']>
  tagUid: Scalars['UUID']
}

export type RemovePublicationsFromTagPayload = {
  __typename?: 'RemovePublicationsFromTagPayload'
  status: Scalars['Boolean']
  tag?: Maybe<Tag>
}

export type ResetAdPublicationParametersInput = {
  publicationId: Scalars['UUID']
}

export type ResetAdPublicationParametersPayload = {
  __typename?: 'ResetAdPublicationParametersPayload'
  status: Scalars['Boolean']
}

export type Rubric = {
  __typename?: 'Rubric'
  id: Scalars['UUID']
  subrubrics?: Maybe<Array<Subrubric>>
  title: Scalars['String']
}

export type SaveTopNewsBlockInput = {
  /**
   * ID актуальной версии фигуры с изображением.
   * Обязателен при создании, опционален при обновлении
   * - undefined | null - ранее установленное изображение не изменяется
   * - UUID - будет установлена указанная версия изображения
   */
  figureVersionId?: Maybe<Scalars['UUID']>
  /**
   * Галочка "Эксклюзив". Всегда опционально, при создании по умолчанию false
   * - undefined | null - установить в false при создании, не изменять при обновлении
   * - true | false - установить указанное значение
   */
  isExclusive?: Maybe<Scalars['Boolean']>
  /**
   * Заголовок блока, обычно заголовок публикации.
   * Обязателен при создании, опционален при обновлении
   * - undefined | null - ранее сохраненное значение не изменяется
   * - URL - устанавливается указанное значение
   */
  title?: Maybe<Scalars['String']>
  /**
   * ID блока "Главное за день"
   * - undefined | null - будет создан новый блок
   * - UUID - будет обновлен указанный блок
   */
  topNewsBlockId?: Maybe<Scalars['UUID']>
  /**
   * URL публикации. Обязателен при создании, опционален при обновлении
   * - undefined | null - ранее сохраненное значение не изменяется
   * - URL - устанавливается указанное значение
   */
  url?: Maybe<Scalars['String']>
}

export type SaveTopNewsBlockPayload = {
  __typename?: 'SaveTopNewsBlockPayload'
  block?: Maybe<TopNewsBlock>
  status: Scalars['Boolean']
}

export type SendPublicationForReviewInput = {
  publicationUid: Scalars['UUID']
}

export type SendPublicationForReviewPayload = {
  __typename?: 'SendPublicationForReviewPayload'
  publication?: Maybe<Publication>
  status: Scalars['Boolean']
}

export type SetAdPublicationParametersInput = {
  hidesAt: Scalars['Time']
  publicationId: Scalars['UUID']
  showsAt: Scalars['Time']
  weight: Scalars['Int']
}

export type SetAdPublicationParametersPayload = {
  __typename?: 'SetAdPublicationParametersPayload'
  status: Scalars['Boolean']
}

export type SetCheckEditInput = {
  publicationUid: Scalars['UUID']
  status: Scalars['Boolean']
}

export type SetCheckingPhotoEditorInput = {
  publicationUid: Scalars['UUID']
  status: Scalars['Boolean']
}

export type SocialMediaFigureVersion = {
  __typename?: 'SocialMediaFigureVersion'
  /**
   * Коэффициент выравнивания по вертикали.
   * <ul>
   *     <li>0.0 - по верхнему краю</li>
   *     <li>0.5 - по центру</li>
   *     <li>1.0 - по нижнему краю</li>
   * </ul>
   */
  alignY: Scalars['Float']
  /** Версия фигуры анонсирующей картинки. */
  announceFigureVersion: FigureVersion
  id: Scalars['ID']
  /** Обработанное изображение. */
  processedImage?: Maybe<Image>
  /** Заголовок, который отображается на картинке. */
  title: Scalars['String']
  /** @deprecated Используйте поле id */
  uid: Scalars['UUID']
}

/** Картинка для соцсетей */
export type SocialMediaImage = {
  __typename?: 'SocialMediaImage'
  id: Scalars['ID']
  socialMediaFigureVersion?: Maybe<SocialMediaFigureVersion>
  uid: Scalars['UUID']
  updatedSocial?: Maybe<Scalars['DateTime']>
}

export type SocialMediaImageInput = {
  /**
   * Коэффициент выравнивания по вертикали.
   * <ul>
   *     <li>0.0 - по верхнему краю</li>
   *     <li>0.5 - по центру</li>
   *     <li>1.0 - по нижнему краю</li>
   * </ul>
   * <pre>ay = oh / (hi/wi*wv - hv)</pre>
   */
  alignY: Scalars['Float']
}

export type StoriesConnection = {
  __typename?: 'StoriesConnection'
  edges: Array<Maybe<StoriesEdge>>
  pageInfo: PageInfo
}

export type StoriesEdge = {
  __typename?: 'StoriesEdge'
  cursor: Scalars['String']
  node?: Maybe<Story>
}

export type StoriesFilter = {
  dateUpdatedFrom?: Maybe<Scalars['DateTime']>
  dateUpdatedTo?: Maybe<Scalars['DateTime']>
  isActive?: Scalars['Boolean']
  /** Поиск по заголовку. При поиске параметр сортировки игнорируется. */
  search?: Maybe<Scalars['String']>
}

export enum StoriesSort {
  DeprecatedIdAsc = 'DEPRECATED_ID_ASC',
  DeprecatedIdDesc = 'DEPRECATED_ID_DESC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Story = {
  __typename?: 'Story'
  canonicalUrl?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  /** INTERNAL */
  dayThemeRegions: Array<Region>
  deprecatedId?: Maybe<Scalars['Int']>
  description: Scalars['String']
  figure?: Maybe<Figure>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  latestPublication?: Maybe<Publication>
  publications?: Maybe<PublicationsConnection>
  publicationsCount: Scalars['Int']
  title: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type StoryPublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type Subrubric = {
  __typename?: 'Subrubric'
  id: Scalars['UUID']
  rubric?: Maybe<Rubric>
  title: Scalars['String']
}

export type SubscriptionBlock = {
  __typename?: 'SubscriptionBlock'
  content: Scalars['String']
  id: Scalars['ID']
  position: Scalars['Int']
  title: Scalars['String']
  uid: Scalars['UUID']
}

export type Tag = {
  __typename?: 'Tag'
  createdAt: Scalars['DateTime']
  description: Scalars['String']
  figure?: Maybe<Figure>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  priority: Scalars['Int']
  publications?: Maybe<PublicationsConnection>
  relatedTags: Array<Tag>
  title: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
}

export type TagPublicationsArgs = {
  filter?: Maybe<PublicationsFilter>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<PublicationsSort>
}

export type TagsConnection = {
  __typename?: 'TagsConnection'
  edges: Array<Maybe<TagsEdge>>
  pageInfo: PageInfo
}

export type TagsEdge = {
  __typename?: 'TagsEdge'
  cursor: Scalars['String']
  node?: Maybe<Tag>
}

export type TagsFilter = {
  excludeTagUids?: Maybe<Array<Scalars['UUID']>>
  isActive?: Scalars['Boolean']
  /** Поиск по заголовку. При поиске параметр сортировки игнорируется. */
  search?: Maybe<Scalars['String']>
}

export enum TagsSort {
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

/** Один абзац текста. */
export type TextElement = Element & {
  __typename?: 'TextElement'
  content: Scalars['String']
  id: Scalars['ID']
  uid: Scalars['UUID']
}

export type TextElementInput = {
  content: Scalars['String']
}

export type TitleExample = {
  __typename?: 'TitleExample'
  id: Scalars['UUID']
  seoTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type TopNewsBlock = {
  __typename?: 'TopNewsBlock'
  addedAt?: Maybe<Scalars['DateTime']>
  clickCount: Scalars['Int']
  createdAt: Scalars['DateTime']
  figureVersion?: Maybe<FigureVersion>
  id: Scalars['ID']
  isAd: Scalars['Boolean']
  isExclusive: Scalars['Boolean']
  position?: Maybe<Scalars['Int']>
  region?: Maybe<Region>
  removedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  uid: Scalars['UUID']
  updatedAt: Scalars['DateTime']
  url: Scalars['String']
}

export type TopNewsBlockChange = {
  __typename?: 'TopNewsBlockChange'
  block: TopNewsBlock
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  isFigureVersionChanged: Scalars['Boolean']
  isIsExclusiveChanged: Scalars['Boolean']
  isPositionChanged: Scalars['Boolean']
  isRegionChanged: Scalars['Boolean']
  isTitleChanged: Scalars['Boolean']
  isUrlChanged: Scalars['Boolean']
  newFigureVersion?: Maybe<FigureVersion>
  newIsExclusive?: Maybe<Scalars['Boolean']>
  newPosition?: Maybe<Scalars['Int']>
  newRegion?: Maybe<Region>
  newTitle?: Maybe<Scalars['String']>
  newUrl?: Maybe<Scalars['String']>
  uid: Scalars['UUID']
  user: User
}

export type UnpublishPublicationInput = {
  publicationUid: Scalars['UUID']
  redirectUrl?: Maybe<Scalars['String']>
}

export type UnpublishPublicationPayload = {
  __typename?: 'UnpublishPublicationPayload'
  publication?: Maybe<Publication>
  status: Scalars['Boolean']
}

export type UpdateBannerForAllRegionsInput = {
  description?: Maybe<Scalars['String']>
  figureUid?: Maybe<Scalars['UUID']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UpdateBannerForAllRegionsPayload = {
  __typename?: 'UpdateBannerForAllRegionsPayload'
  banners?: Maybe<Array<Banner>>
  status: Scalars['Boolean']
}

export type UpdateBannerInput = {
  description?: Maybe<Scalars['String']>
  figureUid?: Maybe<Scalars['UUID']>
  regionUid: Scalars['UUID']
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UpdateBannerPayload = {
  __typename?: 'UpdateBannerPayload'
  banner?: Maybe<Banner>
  status: Scalars['Boolean']
}

export type UpdateCollectionInput = {
  collectionUid: Scalars['UUID']
  deprecatedId?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type UpdateCollectionPayload = {
  __typename?: 'UpdateCollectionPayload'
  collection?: Maybe<Collection>
  status: Scalars['Boolean']
}

export type UpdateDayThemeInput = {
  regionUid: Scalars['UUID']
  /** ID сюжета, чтобы установить или null, чтобы убрать. */
  storyUid?: Maybe<Scalars['UUID']>
}

export type UpdateDayThemePayload = {
  __typename?: 'UpdateDayThemePayload'
  status: Scalars['Boolean']
}

export type UpdateFigureInput = {
  alt?: Maybe<Scalars['String']>
  author: Scalars['String']
  crop?: Maybe<CropInput>
  description?: Maybe<Scalars['String']>
  figureUid: Scalars['UUID']
  hasDiagonalWatermark?: Maybe<Scalars['Boolean']>
  hasWatermark?: Maybe<Scalars['Boolean']>
}

export type UpdateFigurePayload = {
  __typename?: 'UpdateFigurePayload'
  figure?: Maybe<Figure>
  status: Scalars['Boolean']
}

export type UpdateHotNewsBlockForAllRegionsInput = {
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UpdateHotNewsBlockForAllRegionsPayload = {
  __typename?: 'UpdateHotNewsBlockForAllRegionsPayload'
  blocks?: Maybe<Array<HotNewsBlock>>
  status: Scalars['Boolean']
}

export type UpdateHotNewsBlockInput = {
  regionUid: Scalars['UUID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UpdateHotNewsBlockPayload = {
  __typename?: 'UpdateHotNewsBlockPayload'
  block?: Maybe<HotNewsBlock>
  status: Scalars['Boolean']
}

export type UpdateOverviewBlockInput = {
  figureVersionId?: Maybe<Scalars['UUID']>
  overviewBlockId: Scalars['UUID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type UpdateOverviewBlockPayload = {
  __typename?: 'UpdateOverviewBlockPayload'
  /** Первая страница (18 штук) блоков для того же региона, что и у обновленного блока. */
  blocks?: Maybe<Array<OverviewBlock>>
  status: Scalars['Boolean']
}

export type UpdatePublicationContentInput = {
  /**
   * Полный список всех элементов публикации.
   * Для каждого элемента должно быть заполнено одно и только одно поле.
   */
  elements: Array<ElementUnionInput>
  publicationUid: Scalars['UUID']
}

export type UpdatePublicationContentPayload = {
  __typename?: 'UpdatePublicationContentPayload'
  content?: Maybe<PublicationContent>
  status: Scalars['Boolean']
}

export type UpdatePublicationExportInput = {
  destinationSlugs?: Maybe<Array<Scalars['String']>>
  publicationUid: Scalars['UUID']
  yandexNewsVideos?: Maybe<Array<PublicationYandexNewsVideoInput>>
  yandexZenCategoryUids?: Maybe<Array<Scalars['UUID']>>
}

export type UpdatePublicationExportPayload = {
  __typename?: 'UpdatePublicationExportPayload'
  publication?: Maybe<Publication>
  status: Scalars['Boolean']
}

export type UpdatePublicationInput = {
  announceImage?: Maybe<AnnounceImageInput>
  /** Список ID авторов публикации. */
  authors?: Maybe<Array<Scalars['UUID']>>
  collectionUids?: Maybe<Array<Scalars['UUID']>>
  deprecatedId?: Maybe<Scalars['Int']>
  experimentUid?: Maybe<Scalars['UUID']>
  isAutomaticCommentsDisabled?: Maybe<Scalars['Boolean']>
  isCommentsEnabled?: Maybe<Scalars['Boolean']>
  isImportant?: Maybe<Scalars['Boolean']>
  isInside?: Maybe<Scalars['Boolean']>
  isInvert?: Maybe<Scalars['Boolean']>
  isOnlineBroadcast?: Maybe<Scalars['Boolean']>
  /**
   * - undefined или null - значение не изменяется
   * - string - значение заменяется на указанное (возможно пустое для пустой строки)
   */
  keywords?: Maybe<Scalars['String']>
  nationalProjectUid?: Maybe<Scalars['UUID']>
  ogDescription?: Maybe<Scalars['String']>
  ogTitle?: Maybe<Scalars['String']>
  overviewDescription?: Maybe<Scalars['String']>
  overviewFigureId?: Maybe<Scalars['UUID']>
  overviewFigureVersionId?: Maybe<Scalars['UUID']>
  overviewTitle?: Maybe<Scalars['String']>
  publicationUid: Scalars['UUID']
  regionUids?: Maybe<Array<Scalars['UUID']>>
  rubricId?: Maybe<Scalars['UUID']>
  seoTitle?: Maybe<Scalars['String']>
  socialMediaImage?: Maybe<SocialMediaImageInput>
  specProjectUrl?: Maybe<Scalars['String']>
  storyUid?: Maybe<Scalars['UUID']>
  subrubricId?: Maybe<Scalars['UUID']>
  subscriptionBlockUid?: Maybe<Scalars['UUID']>
  subtitle?: Maybe<Scalars['String']>
  tagUids?: Maybe<Array<Scalars['UUID']>>
  title?: Maybe<Scalars['String']>
  yandexDzenTitle?: Maybe<Scalars['String']>
  yandexTitle?: Maybe<Scalars['String']>
}

export type UpdatePublicationPayload = {
  __typename?: 'UpdatePublicationPayload'
  publication?: Maybe<Publication>
  status: Scalars['Boolean']
}

export type UpdateStoryInput = {
  deprecatedId?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  figureUid?: Maybe<Scalars['UUID']>
  storyUid: Scalars['UUID']
  title?: Maybe<Scalars['String']>
}

export type UpdateStoryPayload = {
  __typename?: 'UpdateStoryPayload'
  status: Scalars['Boolean']
  story?: Maybe<Story>
}

export type UpdateTagInput = {
  description?: Maybe<Scalars['String']>
  figureUid?: Maybe<Scalars['UUID']>
  priority?: Maybe<Scalars['Int']>
  relatedTagUids?: Maybe<Array<Scalars['UUID']>>
  tagUid: Scalars['UUID']
  title?: Maybe<Scalars['String']>
}

export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload'
  status: Scalars['Boolean']
  tag?: Maybe<Tag>
}

export type UpdateTopNewsSelectionInput = {
  blockIds: Array<Scalars['UUID']>
  regionId: Scalars['UUID']
}

export type UpdateTopNewsSelectionPayload = {
  __typename?: 'UpdateTopNewsSelectionPayload'
  /** Добавленные блоки. */
  addedBlocks?: Maybe<Array<TopNewsBlock>>
  /** Все измененные блоки. */
  blocks?: Maybe<Array<TopNewsBlock>>
  /** Удаленные блоки. */
  removedBlocks?: Maybe<Array<TopNewsBlock>>
  status: Scalars['Boolean']
  /** Измененные блоки без удаленных и добавленных. */
  updatedBlocks?: Maybe<Array<TopNewsBlock>>
}

export type User = {
  __typename?: 'User'
  /** INTERNAL */
  accessLevel?: Maybe<AccessLevel>
  /** INTERNAL */
  avatarUrl?: Maybe<Scalars['String']>
  /** INTERNAL */
  birthDate?: Maybe<Scalars['DateTime']>
  /** INTERNAL */
  company?: Maybe<Company>
  /** INTERNAL */
  department?: Maybe<Department>
  /** PUBLIC_REGISTERED */
  email?: Maybe<Scalars['String']>
  /** INTERNAL */
  employmentDate?: Maybe<Scalars['DateTime']>
  /** PUBLIC_REGISTERED */
  firstname?: Maybe<Scalars['String']>
  /** PUBLIC_REGISTERED */
  id: Scalars['ID']
  /** INTERNAL */
  isFired?: Maybe<Scalars['Boolean']>
  /** PUBLIC_REGISTERED */
  lastname?: Maybe<Scalars['String']>
  /** INTERNAL */
  notes?: Maybe<Scalars['String']>
  /** INTERNAL */
  phone?: Maybe<Scalars['String']>
  /** INTERNAL */
  position?: Maybe<Position>
  /** PUBLIC_REGISTERED */
  uid: Scalars['UUID']
  /** PUBLIC_REGISTERED */
  username?: Maybe<Scalars['String']>
}

export type UsersConnection = {
  __typename?: 'UsersConnection'
  edges: Array<Maybe<UsersEdge>>
  pageInfo: PageInfo
}

export type UsersEdge = {
  __typename?: 'UsersEdge'
  cursor: Scalars['String']
  node?: Maybe<User>
}

export type UsersFilter = {
  departmentUid?: Maybe<Scalars['UUID']>
  /** true - только уволенные, false - только действующие, null - не проверять в фильтре */
  isFired?: Maybe<Scalars['Boolean']>
  /** Поиск по имени, фамилии и email. */
  search?: Maybe<Scalars['String']>
}

export enum UsersSort {
  FirstnameAscLastnameAsc = 'FIRSTNAME_ASC_LASTNAME_ASC',
  LastnameAscFirstnameAsc = 'LASTNAME_ASC_FIRSTNAME_ASC',
  UsernameAsc = 'USERNAME_ASC'
}

/** Категория для Яндекс Дзен. */
export type YandexZenCategory = {
  __typename?: 'YandexZenCategory'
  /** INTERNAL */
  createdAt?: Maybe<Scalars['DateTime']>
  /** INTERNAL */
  deprecatedId: Scalars['Int']
  /** INTERNAL */
  id: Scalars['ID']
  /** INTERNAL */
  title: Scalars['String']
  /** INTERNAL */
  uid: Scalars['UUID']
  /** INTERNAL */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export enum Join__Graph {
  Comments = 'COMMENTS',
  Export = 'EXPORT',
  Figures = 'FIGURES',
  Lenta = 'LENTA',
  MainPage = 'MAIN_PAGE',
  Publications = 'PUBLICATIONS',
  Regions = 'REGIONS',
  Users = 'USERS'
}
