export class TypographReplacer {
  openQuotesCount = 0
  previousSymbol = ''

  getTextVal = (node: Node, resStr: string): string => {
    if (node.nodeName.match(/^(P|PRE)$/)) {
      this.openQuotesCount = 0
      this.previousSymbol = ''
    }
    if (node.nodeName === '#text') {
      let newTextStr = this.quotesReplacer(node.textContent || '')

      // замена дефисов на тире
      newTextStr = this.dashReplacerHook(newTextStr)

      // // замена пробелов на неразрывные для предлогов
      // newTextStr = this.prepositionReplacer(newTextStr)

      node.textContent = newTextStr

      if (newTextStr?.length) {
        this.previousSymbol = newTextStr[newTextStr?.length - 1] || ''
      }
      return resStr + newTextStr
    }
    let resParts = ''
    node.childNodes.forEach(childNode => {
      resParts += this.getTextVal(childNode, resStr)
    })

    return resStr + resParts
  }

  prepositions: string[] = [
    'в',
    'без',
    'до',
    'из',
    'к',
    'на',
    'по',
    'о',
    'от',
    'перед',
    'при',
    'через',
    'для',
    'с',
    'у',
    'за',
    'над',
    'об',
    'под',
    'про',

    'и',
    'о',
    'да',
    'ни',
    'тоже',
    'РФ'
  ]

  prepositionReplacer = (sourceStr: string): string => {
    let resStr = ''
    if (sourceStr.match(/\s/)) {
      const sourceStrArr = sourceStr.split(/\s/)

      for (let i = 0; i < sourceStrArr.length; i++) {
        if (this.prepositions.includes(sourceStrArr[i].toLocaleLowerCase())) {
          resStr += sourceStrArr[i] + '\u00A0'
        } else {
          resStr += sourceStrArr[i] + ' '
        }
      }
      return resStr
    }
    return sourceStr
  }

  quotesReplacer = (sourceSentence: string): string => {
    let resStr = ''
    const regExp = /\»|\«|\"|\＂|\„|\“|\‟|\”|\‚|\‘|\‛|\’|\❝|\❞/

    if (sourceSentence.length) {
      for (let i = 0; i < sourceSentence.length; i++) {
        if (sourceSentence[i].match(/\s/)) {
          if ((i === 0 && this.previousSymbol.match(regExp)) || (i > 0 && sourceSentence[i - 1].match(regExp))) {
            resStr = resStr.slice(0, -1) + '» '
          } else {
            resStr += sourceSentence[i]
          }
        } else if (sourceSentence[i].match(regExp)) {
          if ((i === 0 && this.previousSymbol.match(regExp)) || (i > 0 && sourceSentence[i - 1].match(regExp))) {
            if ((i === 0 && this.previousSymbol.match(/\«/)) || (i > 0 && resStr[resStr.length - 1].match(/\«/))) {
              resStr = resStr.slice(0, -1) + '««'
            } else {
              resStr = resStr.slice(0, -1) + '»»'
            }
          } else {
            if ((i === 0 && this.previousSymbol.match(/\S/)) || (i > 0 && sourceSentence[i - 1].match(/\S/))) {
              resStr = resStr + '»'
            } else {
              resStr += '«'
            }
          }
        } else {
          resStr += sourceSentence[i]
        }
      }
    }

    let sentence = ''

    if (resStr.length) {
      for (let index = 0; index < resStr.length; index++) {
        if (resStr[index] === '«') {
          this.openQuotesCount++

          sentence += this.openQuotesCount > 2 ? '‚' : this.openQuotesCount > 1 ? '„' : '«'
        } else if (resStr[index] === '»') {
          sentence += this.openQuotesCount > 2 ? '‘' : this.openQuotesCount > 1 ? '“' : '»'
          this.openQuotesCount--
        } else {
          sentence += resStr[index]
        }
      }
    }

    return sentence
  }

  dashReplacerHook = (sourceStr: string): string => {
    let sourceSentence = sourceStr
    sourceSentence = sourceSentence.replace(
      /[\s][\-|\—|\–|\‒|\⸻|\⸺|\〜|\┅|\〰|\⹃|\╍|\╌|\⫘|\┉|\┈|\⑈|\╏|\┄|\⥫|\⥬|\⥭|\⩝|\⥪|\⫦\‐|\‑|\﹣|\⁃|\-|\᠆|\⊝|\֊|\⸗|\⸚|\⹀|\゠][\s]/g,
      ' — '
    )
    return sourceSentence
  }

  runTypograph = (sourceObj: Document): Document => {
    this.getTextVal(sourceObj, '')

    return sourceObj
  }

  runTypographForString = (sourceObj: string): string => {
    let resStr = this.quotesReplacer(sourceObj)
    resStr = this.dashReplacerHook(resStr)
    resStr = this.prepositionReplacer(resStr)

    return resStr
  }
}
