export type helpTextType = {
  text: string
  url: string
}

export const HELP_INFO_MAIL: helpTextType = {
  text:
    'Не допускается использовать в заголовках просторечия ' +
    'и жаргон, слова-усилители, оценочных суждений и цитат ' +
    'без кавычек и/или указания источника.',
  url: 'https://help.mail.ru/feed/policy'
}

export const HELP_INFO_YA_ZEN: helpTextType = {
  text:
    'В Дзене запрещены новости с откровенным контентом, кликбейтом ' +
    '(обман в заголовке) и содержащие описание и/или изображение: жертв катастроф ' +
    'и насильственных действий, трупов, болезней и уродств, интимных частей тела и ' +
    'процесса родов, травм, заболеваний, насекомых и животных, чей вид вызывает отвращение.',
  url: 'https://yandex.ru/support/zen/requirements/rules.html'
}

export const HELP_INFO_ZEN_NEWS: helpTextType = {
  text:
    'В Дзене запрещены новости с откровенным контентом, кликбейтом ' +
    '(обман в заголовке) и содержащие описание и/или изображение: жертв катастроф ' +
    'и насильственных действий, трупов, болезней и уродств, интимных частей тела и ' +
    'процесса родов, травм, заболеваний, насекомых и животных, чей вид вызывает отвращение.',
  url: 'https://yandex.ru/support/zen/requirements/rules.html'
}
