import React, {FC, useState, MouseEvent} from 'react'
import {Menu, MenuItem} from '@mui/material'
import {useLocation} from 'react-router-dom'
import {IMenuItem} from '../menu-items'

import {StyledNavLink} from '../Navbar'
import {ArrowDropDown} from '@mui/icons-material'
import {useKeycloak} from '@react-keycloak/web'
import {StyledButton, LinkWrapper} from './MenuButton.styles'

const MenuButton: FC<IMenuItem> = props => {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {keycloak} = useKeycloak()

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <StyledButton $active={location.pathname.includes(props.route)} onClick={handleClick}>
        {props.title}
        <ArrowDropDown />
      </StyledButton>

      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {props.menuList?.map((item, ind) => (
          <div key={item.route + item.title + ind}>
            {item.disabled && (
              <MenuItem
                hidden={!keycloak.hasResourceRole(item.permission, item.resource)}
                onClick={handleClose}
                disabled={item.disabled}
                key={'menu-button-item' + item.title}
              >
                {item.title}
              </MenuItem>
            )}
            {!item.disabled && item.route === '/contacts' ? (
              <MenuItem>
                <LinkWrapper key={'menu-button-link' + item.title} href='https://contacts.ura.news/' target='_blank'>
                  {item.title}
                </LinkWrapper>
              </MenuItem>
            ) : (
              <StyledNavLink
                to={item.route}
                key={'menu-button-nav-link' + item.title}
                hidden={!keycloak.hasResourceRole(item.permission, item.resource)}
              >
                <MenuItem onClick={handleClose}>{item.title}</MenuItem>
              </StyledNavLink>
            )}
          </div>
        ))}
      </Menu>
    </div>
  )
}

export default MenuButton
