import {mainTextColor} from '../../../../@theme/vars'
import {NnItem} from './NnItem'

export const TOOLBAR_SIZE_CONSTS = {
  sizeItems: [
    {
      title: 'Написать бэк',
      color: mainTextColor,
      size: 'background'
    } as NnItem,
    // {
    //   title: 'Написать бэк (вектор)',
    //   color: mainTextColor,
    //   size: 'background'
    // } as NnItem,
    {
      title: 'Проверить иноагентов и экстремистов',
      color: mainTextColor,
      size: 'inoagent'
    } as NnItem,
    {
      title: 'Сгенерировать текст по теме',
      color: mainTextColor,
      size: 'generation'
    } as NnItem,
    {
      title: 'Переписать выделенный текст',
      color: mainTextColor,
      size: 'rerite'
    } as NnItem,
    {
      title: 'Тэгирование',
      color: mainTextColor,
      size: 'taging'
    } as NnItem,
    {
      title: 'Рерайт',
      color: mainTextColor,
      size: 'testrerite'
    } as NnItem
  ],
  textSize: 'Воспользоваться нейросетью',
  textLoading: 'Запрос в обработке'
}
