import {makeAutoObservable} from 'mobx'
import ReactQuill from 'react-quill'
import {MutableRefObject} from 'react'
import IEditorStore from './IEditorStore'
import {
  checkIsOnlineTranslationReversed,
  generateOnlineTranslationChunks,
  pushToOnlineTranslation,
  reverseOnlineTranslation
} from './utils'

class EditorStore implements IEditorStore {
  editorRef: MutableRefObject<ReactQuill | null> = {current: null}
  hasOnlineTranslation = false
  editorDirty = false
  editorTextLength = 0

  constructor() {
    makeAutoObservable(this)
  }

  setEditorRef = (ref: MutableRefObject<ReactQuill | null>): void => {
    this.editorRef = ref
  }

  setEditorDirty = (value: boolean): void => {
    this.editorDirty = value
  }

  setEditorTextLength = (value: number): void => {
    this.editorTextLength = value
  }

  pushToOnlineTranslation = (value: string): void => {
    if (this.editorRef.current === null) {
      return
    }

    const quill = this.editorRef.current.getEditor()
    const isOnlineTranslationReversed = checkIsOnlineTranslationReversed(quill)

    pushToOnlineTranslation(value, quill)

    if (isOnlineTranslationReversed) {
      const publicationChunks = generateOnlineTranslationChunks(quill)
      reverseOnlineTranslation(quill, isOnlineTranslationReversed, publicationChunks)
    }
  }

  reverseOnlineTranslation = (): void => {
    if (this.editorRef.current === null) {
      return
    }

    const quill = this.editorRef.current.getEditor()
    const isOnlineTranslationReversed = !checkIsOnlineTranslationReversed(quill)

    const publicationChunks = generateOnlineTranslationChunks(quill)
    reverseOnlineTranslation(quill, isOnlineTranslationReversed, publicationChunks)
  }

  toggleOnlineTranslation = (value?: boolean): void => {
    this.hasOnlineTranslation = value === undefined ? !this.hasOnlineTranslation : value
  }
}

export default EditorStore
