import React, {FC} from 'react'

const TrashIcon: FC<any> = () => {
    return (
        <svg
            width='12'
            height='16'
            viewBox='0 0 12 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.76602 6.725L5.99935 8.49167L4.22435 6.725L3.04935 7.9L4.82435 9.66667L3.05768 11.4333L4.23268 12.6083L5.99935 10.8417L7.76602 12.6083L8.94102 11.4333L7.17435 9.66667L8.94102 7.9L7.76602 6.725ZM8.91602 1.33333L8.08268 0.5H3.91602L3.08268 1.33333H0.166016V3H11.8327V1.33333H8.91602ZM0.999349 13.8333C0.999349 14.75 1.74935 15.5 2.66602 15.5H9.33268C10.2494 15.5 10.9994 14.75 10.9994 13.8333V3.83333H0.999349V13.8333ZM2.66602 5.5H9.33268V13.8333H2.66602V5.5Z'
                fill='#C4C4C4'
            />
        </svg>
    )
}

export default TrashIcon
