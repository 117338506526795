import styled from 'styled-components'
import {Accordion, AccordionDetails, AccordionSummary, Button} from '@mui/material'
import {blueColor, grey35, mainTextColor, whiteColor} from '../../../../@theme/vars'

export const AnnounceImageModalImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;

  @media screen and (max-width: 654px) {
    flex-direction: column;
  }
`

export const SocialImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;

  @media screen and (max-width: 654px) {
    display: column;
    margin-top: 24px;
  }
`

export const AccordionWrapper = styled(Accordion)`
  border: none;
  box-shadow: none;
  background-color: inherit;
`

export const AccordionSummaryWrapper = styled(AccordionSummary)`
  color: ${blueColor};
  display: flex;
  flex-direction: row;
  /* border-bottom: 1px solid ${grey35}; */
  /* background-color: #f1f6ff; */
  /* border-radius: 6px 6px 0px 0px; */
  opacity: 1;

  &.MuiAccordionSummary-root {
    padding: 0;
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px !important;
  }

  /* &div.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0px !important;
  } */

  &.Mui-disabled {
    opacity: 1;
  }
  /* &.MuiAccordionSummary-content {
    margin: 12px 0px !important;
  } */
  &div.MuiAccordionDetails-root {
    padding: 0;
  }
`

export const AccordionSummaryTitleWrapper = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  height: 17px;
  color: ${mainTextColor};
`

export const ArrowDropDownIconWrapper = styled.div`
  height: 17px;
  margin-top: -4px;
  color: ${mainTextColor};
`

export const AccordionDetailsWrapper = styled(AccordionDetails)`
  background-color: ${whiteColor};
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const DetailRowWrapper = styled.div`
  display: flex;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${blueColor};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: -10px;
  margin-bottom: 16px;
  /* letter-spacing: 0px; */
`
