export interface IMenuItem {
  route: string
  title: string
  class: string
  permission: string
  type?: 'menu' | 'button'
  menuList?: IMenuItem[]
  resource?: string
  disabled?: boolean
}

export const items: (IMenuItem & {icon?: string})[] = [
  {
    route: '/news',
    title: 'Новости',
    class: 'news',
    permission: 'manage-publications',
    resource: 'publications'
  },
  {
    route: '/articles',
    title: 'Статьи',
    class: 'articles',
    permission: 'manage-publications',
    resource: 'publications'
  },
  {
    route: '/stories',
    title: 'Сюжеты',
    class: 'stories',
    permission: 'manage-stories',
    resource: 'publications'
  },
  {
    route: '/main-page',
    title: 'Главная сайта',
    disabled: true,
    class: '',
    permission: 'manage-main-page',
    resource: 'main-page',
    type: 'menu',
    menuList: [
      {
        route: '/main-page/hot-news',
        title: 'Горячая новость',
        disabled: false,
        class: 'hot-news',
        permission: 'manage-main-page',
        resource: 'main-page'
      },
      {
        route: '/main-page/top-news',
        title: 'Главное за день',
        disabled: false,
        class: 'top-day',
        permission: 'manage-main-page',
        resource: 'main-page'
      },
      {
        route: '/main-page/overview',
        title: 'Картина дня',
        disabled: true,
        class: 'picture-day',
        permission: ''
      },
      {
        route: '/main-page/banner',
        title: 'Баннер',
        disabled: true,
        class: 'banner',
        permission: ''
      }
    ]
  },
  // {
  //   route: '/collections',
  //   title: 'Коллекции',
  //   class: 'collections',
  //   permission: 'manage-collections',
  //   resource: 'publications'
  // },
  // {
  //   route: '/tags',
  //   title: 'Тэги',
  //   class: 'tags',
  //   permission: 'manage-tags',
  //   resource: 'publications'
  // },
  {
    route: '/more',
    title: 'Еще',
    class: '',
    permission: 'manage-inside-card-templates',
    resource: 'publications',
    type: 'menu',
    menuList: [
      // {
      //   route: '/insiders',
      //   title: 'Карточки инсайдов',
      //   class: 'insides',
      //   permission: 'manage-inside-card-templates',
      //   resource: 'publications'
      // },
      {
        route: '/dictionaries',
        title: 'Словари',
        class: 'dictionaries',
        permission: ''
      },
      {
        route: '/staff',
        title: 'Сотрудники',
        class: 'staff',
        permission: 'publications_edit_publications'
      },
      // {
      //   route: '/smm-tools',
      //   title: 'Инструменты SMM',
      //   disabled: true,
      //   class: 'smm-tools',
      //   permission: 'publications_edit_publications'
      // },
      {
        route: '/more',
        title: 'Чистка кэша',
        disabled: true,
        class: 'service-cache',
        permission: ''
      },
      // {
      //   route: '/more',
      //   title: 'Сокращатель ссылок',
      //   disabled: true,
      //   class: 'service-links',
      //   permission: ''
      // },
      // {
      //   route: '/ad-publications',
      //   title: 'Рекламные публикации',
      //   disabled: true,
      //   class: 'ad-publications',
      //   permission: ''
      // },
      {
        route: '/bild-check-list',
        title: 'Проверка бильдредакторов',
        class: 'bild-check-list',
        resource: 'publications',
        permission: 'check-bildeditor'
      },
      {
        route: '/reports',
        title: 'Отчеты',
        class: 'reports',
        resource: 'publications',
        permission: 'manage-publications'
      },

      {
        route: '/people',
        title: 'Персоны',
        class: 'persons',
        resource: 'publications',
        permission: 'edit-persons'
      },
      {
        route: '/contacts',
        title: 'Телефонный справочник',
        class: 'contacts',
        permission: ''
      }
    ]
  },
  {
    route: '/news?fast-news=true',
    title: 'Новость-молния',
    class: 'news',
    icon: 'lighting',
    permission: 'manage-publications',
    resource: 'publications'
  }
]

export const personal: (IMenuItem & {icon: string})[] = [
  {
    route: '/reference',
    title: 'Справка',
    class: 'help',
    icon: 'help_outline',
    permission: ''
  },
  {
    route: '/logout',
    title: 'Выйти из приложения',
    class: 'logout',
    icon: 'logout',
    permission: ''
  }
]

export type NewPublicationItemType = {
  mainButton: IMenuItem & {slug: string}
  secondButton: (IMenuItem & {slug: string})[]
}

export const newPublicationItem: NewPublicationItemType = {
  mainButton: {
    route: '/editor',
    title: 'Новость',
    class: '',
    permission: 'manage-publications',
    resource: 'publications',
    slug: 'news'
  },
  secondButton: [
    {
      route: '/editor',
      title: 'Статья',
      class: '',
      permission: 'manage-publications',
      resource: 'publications',
      slug: 'articles'
    },
    {
      route: '/story/editor',
      title: 'Сюжет',
      disabled: false,
      class: '',
      permission: 'manage-stories',
      resource: 'publications',
      slug: 'story'
    }
  ]
}
