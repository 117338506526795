import React, {FC} from 'react'
import {Box, Popover, Typography, IconButton} from '@mui/material'
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state'
import {InfoOutlined} from '@mui/icons-material'
import {helpTextType} from './TextInfo'
import styled from 'styled-components'
import {secondTextColor} from '../../../@theme/vars'

type PopoverPopupStateProp = {
  info: helpTextType
}

const Link = styled.a`
  margin-top: 6px;
  color: ${secondTextColor};
  text-decoration: underline;
`

const PopoverPopupState: FC<PopoverPopupStateProp> = ({info}) => {
  return (
    <PopupState variant='popover' popupId='demo-popup-popover'>
      {popupState => (
        <div>
          <IconButton size='small' {...bindTrigger(popupState)}>
            <InfoOutlined
              htmlColor='#EB3846'
              style={{
                transform: 'scale(0.8)'
              }}
            />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Box maxWidth='360px' padding='10px'>
              <Typography color='secondary'>{info.text}</Typography>
              <Typography>
                <Link href={info.url} target='_blank'>
                  Полный текст правил
                </Link>
              </Typography>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

export default PopoverPopupState
