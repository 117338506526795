import {usePublishOverviewBlocksDraftMutation} from '@components/MainSelection/Overview/OverviewContent/gql/OverviewContent.generated'
import {
  usePublishPublicationMutation,
  useSendPublicationForReviewMutation,
  useUnpublishPublicationMutation,
  useUpdatePublicationMutation
} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {UNPUBLISHED_NEWS_PICKER_CONSTS} from '@components/Publication/Form/Pickers/UnpublishedNewsPicker/UnpublishedNewsPicker.consts'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {PublicationLoading} from '@components/Publication/Form/PublicationLoading/PublicationLoading'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'
import {PublicationStatus, UpdatePublicationInput} from '@graphql/types'
import {TypographReplacer} from '@hooks/typographReplacer'
import {CircularProgress} from '@mui/material'
import {useKeycloak} from '@react-keycloak/web'
import {useStore} from '@stores/rootStoreContext'
import {format} from 'date-fns'
import {isNull} from 'lodash'
import {observer} from 'mobx-react-lite'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useFormContext, useWatch} from 'react-hook-form'
import {toast} from 'react-toastify'
import {PUBLICATION_ACTION_BUTTON_CONSTS} from './PublicationActionButton.consts'
import {
  PublicationActionButtonBtn,
  PublicationActionButtonControlTooltipWrapper,
  PublicationActionButtonFields,
  PublicationActionButtonFieldsButtons,
  PublicationActionButtonFieldsDateAndStatus,
  PublicationActionButtonFieldsDateAndStatusBlock,
  PublicationActionButtonWrapper,
  PublicationRemovedWrapper
} from './PublicationActionButton.styles'
import {PublicationActionButtonProps} from './PublicationActionButtonProps'
import {PublicationDate} from './PublicationDate/PublicationDate'

const PublicationActionButton: FC<PublicationActionButtonProps> = ({hasOverviewBlock}) => {
  const {publicationStore, editorStore, regionsWithOverviewBlocksStore} = useStore()
  const {
    publication,
    publicationLoading,
    publicationContentLoading,
    setPublication,
    setPublicationWithPrevState,
    destinationsDirty,
    rubricAndSubrubricStore,
    hasTitlesError,
    publicationFormDirty,
    checkYandexDzenTitleValidation
  } = publicationStore
  const {editorDirty, editorTextLength, editorRef} = editorStore
  const {overviewBlocksLoading} = regionsWithOverviewBlocksStore
  const {rubricIdCache, subrubricIdCache} = rubricAndSubrubricStore
  const {control} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})

  const messageForToast =
    fields.publishedAt < new Date() || isNaN(fields.publishedAt) || isNull(fields.publishedAt)
      ? PUBLICATION_ACTION_BUTTON_CONSTS.publishedMsg
      : PUBLICATION_ACTION_BUTTON_CONSTS.willPublishMsg + format(fields.publishedAt, 'dd.MM.yyyy HH:mm')

  const {keycloak} = useKeycloak()
  const [sendPublicationForReview] = useSendPublicationForReviewMutation()
  const [updatePublication, {loading: updatePubliationLoading}] = useUpdatePublicationMutation()
  const [publishPublication, {loading: publishLoading}] = usePublishPublicationMutation()
  const [publishOverviewBlocksDraft, {loading: publishOverviewBlocksDraftLoading}] =
    usePublishOverviewBlocksDraftMutation()
  const [unpublishPublication, {loading: unpublishLoading}] = useUnpublishPublicationMutation()

  const sendPublicationForReviewAction = useCallback(async () => {
    try {
      const newPublicationShema = await sendPublicationForReview({
        variables: {
          data: {
            publicationUid: publication?.uid
          }
        }
      })
      if (newPublicationShema.data?.sendPublicationForReview?.publication?.status) {
        const newPublication = publication
        newPublication.status = newPublicationShema.data?.sendPublicationForReview?.publication?.status
        setPublication(newPublication)
      }
      toast.success(PUBLICATION_ACTION_BUTTON_CONSTS.savedMsg, {
        autoClose: false,
        hideProgressBar: true,
        containerId: 'success'
      })
    } catch {
      console.error(PUBLICATION_ACTION_BUTTON_CONSTS.errorMsg)
    }
  }, [publication, sendPublicationForReview, setPublication])

  const publishPublicationAction = useCallback(async () => {
    let quill
    if (editorRef.current !== null) {
      quill = editorRef.current.getEditor()
    }
    if (quill) {
      const quotesReplacer = new TypographReplacer()
      quotesReplacer.runTypograph(quill.container)
    }

    try {
      let isFullWidthValue = true
      if (!fields.announceImage?.figureVersion?.id) {
        isFullWidthValue = publication.typeSlug === 'articles' ? true : false
      } else {
        isFullWidthValue =
          fields.announceImage?.figureVersion?.isFullWidth || publication.announceImage?.isFullWidth || false
      }
      const variablesData: UpdatePublicationInput = {
        publicationUid: publication.uid,
        title: fields.title,
        yandexTitle: fields.yandexTitle,
        yandexDzenTitle: fields.yandexDzenTitle || '',
        subtitle: fields.subtitle,
        isCommentsEnabled: fields.isCommentsDisabled == true ? false : true,
        isAutomaticCommentsDisabled: fields.isAutomaticCommentsDisabled == true ? true : false,
        isImportant: fields.isImportant === true ? true : false,
        specProjectUrl: fields.specProjectUrl || '',
        regionUids: fields.regionUids,
        overviewTitle: fields.overviewTitle || '',
        overviewDescription: fields.overviewDescription || '',
        isInside: fields.isInside,
        tagUids: fields.tagsUids,
        collectionUids: fields.collectionUids,
        storyUid: fields.storyUid?.length ? fields.storyUid : null,
        rubricId: rubricIdCache?.length ? rubricIdCache : null,
        subrubricId: subrubricIdCache?.length ? subrubricIdCache : null,
        subscriptionBlockUid: fields.subscriptionBlockUid?.length ? fields.subscriptionBlockUid : null,
        authors: fields.authors?.filter(author => author),
        keywords: fields.keywords,
        announceImage: {
          figureVersionId:
            fields.announceImage?.figureVersion?.id || publication.announceImage?.figureVersion?.id || '',
          isFullWidth: isFullWidthValue,
          description: fields.announceImage?.description || publication.announceImage?.description || '',
          author: fields.announceImage?.author || publication.announceImage?.author || '',
          alt: fields.announceImage?.alt || publication.announceImage?.alt || ''
        }
      }

      if (fields.seoTitle?.length) {
        variablesData.seoTitle = fields.seoTitle
      }
      if (fields.overviewFigureVersionId?.length) {
        variablesData.overviewFigureVersionId = fields.overviewFigureVersionId
      }
      if (fields.overviewFigureId?.length) {
        variablesData.overviewFigureId = fields.overviewFigureId
      }
      if (fields.ogTitle?.length) {
        variablesData.ogTitle = fields.ogTitle
      }
      if (fields.ogDescription?.length) {
        variablesData.ogDescription = fields.ogDescription
      }
      const updatePublicationData = await updatePublication({
        variables: {
          data: variablesData
        }
      })
      if (!updatePublicationData?.errors?.length) {
        const newPublicationShema = await publishPublication({
          variables: {
            data: {
              publicationUid: publication?.uid,
              publishedAt: fields.publishedAt
            }
          }
        })
        // const newPublication = JSON.parse(JSON.stringify(publication))

        if (!newPublicationShema?.errors?.length) {
          if (newPublicationShema.data?.publishPublication?.publication?.status) {
            // const newPublication = publication
            // newPublication.status = newPublicationShema.data?.publishPublication?.publication?.status
            // newPublication.status = newPublicationShema.data?.publishPublication?.publication?.status

            const publishOverviewBlocksDraftData = await publishOverviewBlocksDraft({
              variables: {
                data: {
                  publicationId: publication?.id,
                  url:
                    publication.canonicalUrl ||
                    newPublicationShema.data?.publishPublication?.publication?.canonicalUrl ||
                    publication.url ||
                    '',
                  figureVersionId:
                    publication?.overviewFigureVersionId || publication?.announceImage?.figureVersion?.id,
                  title: publication?.overviewTitle || publication?.title || '',
                  subtitle: publication?.overviewDescription || publication?.subtitle || ''
                }
              }
            })

            setPublicationWithPrevState(prev => ({
              ...prev,
              status: newPublicationShema.data?.publishPublication?.publication?.status || PublicationStatus.Published,
              canonicalUrl: newPublicationShema.data?.publishPublication?.publication?.canonicalUrl || ''
            }))
          }

          toast.success(messageForToast, {
            autoClose: false,
            hideProgressBar: true,
            containerId: 'success'
          })
        }
      }
    } catch (e) {
      console.error(e)
      console.error(PUBLICATION_ACTION_BUTTON_CONSTS.errorMsg)
    }
  }, [
    editorRef,
    fields.announceImage?.figureVersion?.id,
    fields.announceImage?.figureVersion?.isFullWidth,
    fields.announceImage?.description,
    fields.announceImage?.author,
    fields.announceImage?.alt,
    fields.title,
    fields.yandexTitle,
    fields.yandexDzenTitle,
    fields.subtitle,
    fields.isCommentsDisabled,
    fields.isAutomaticCommentsDisabled,
    fields.isImportant,
    fields.specProjectUrl,
    fields.regionUids,
    fields.overviewTitle,
    fields.overviewDescription,
    fields.isInside,
    fields.tagsUids,
    fields.collectionUids,
    fields.storyUid,
    fields.subscriptionBlockUid,
    fields.authors,
    fields.keywords,
    fields.seoTitle,
    fields.overviewFigureVersionId,
    fields.overviewFigureId,
    fields.ogTitle,
    fields.ogDescription,
    fields.publishedAt,
    publication.uid,
    publication.announceImage?.figureVersion?.id,
    publication.announceImage?.description,
    publication.announceImage?.author,
    publication.announceImage?.alt,
    publication.announceImage?.isFullWidth,
    publication.typeSlug,
    publication?.id,
    publication?.canonicalUrl,
    publication?.url,
    publication?.overviewFigureVersionId,
    publication?.overviewTitle,
    publication?.title,
    publication?.overviewDescription,
    publication?.subtitle,
    rubricIdCache,
    subrubricIdCache,
    updatePublication,
    publishPublication,
    publishOverviewBlocksDraft,
    setPublicationWithPrevState,
    fields,
    rubricIdCache,
    subrubricIdCache
  ])

  const unpublishPublicationAction = useCallback(async () => {
    try {
      const unpublishPublicationResult = await unpublishPublication({
        variables: {
          data: {
            publicationUid: publication?.uid,
            redirectUrl: UNPUBLISHED_NEWS_PICKER_CONSTS.homePage
          }
        }
      })

      if (!unpublishPublicationResult.errors?.length) {
        setPublicationWithPrevState(prev => ({
          ...prev,
          status:
            unpublishPublicationResult.data?.unpublishPublication?.publication?.status || PublicationStatus.Unpublished,
          redirectUrl:
            unpublishPublicationResult.data?.unpublishPublication?.publication?.redirectUrl ||
            UNPUBLISHED_NEWS_PICKER_CONSTS.homePage
        }))
      }

      toast.success(PUBLICATION_ACTION_BUTTON_CONSTS.savedMsg, {
        autoClose: false,
        hideProgressBar: true,
        containerId: 'success'
      })
    } catch {
      console.error(PUBLICATION_ACTION_BUTTON_CONSTS.errorMsg)
    }
  }, [publication?.uid, setPublicationWithPrevState, unpublishPublication])

  const closePublicationAction = useCallback(() => {
    window.location.href = '/news'
  }, [])

  const buttonDisabledTooltipMessage = useMemo(() => {
    const errors: string[] = []
    let actionType = [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
      ? PUBLICATION_ACTION_BUTTON_CONSTS.publishMsg.toLowerCase()
      : PUBLICATION_ACTION_BUTTON_CONSTS.saveMsg.toLowerCase()

    if (!keycloak.hasResourceRole('publish', 'publications')) {
      actionType = PUBLICATION_ACTION_BUTTON_CONSTS.sendToReviewMsg
    }
    if (!fields.title) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.titleMsg)
    }
    if (!fields.seoTitle) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.seoTitleMsg)
    }
    if (!fields.regionUids || fields.regionUids?.length < 2) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.regionMsg)
    }
    if (!rubricIdCache) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.rubricMsg)
    }
    if (!fields.keywords?.length) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.keywordsMsg)
    }
    if (!fields.authors?.length) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.authorMsg)
    }
    if (!editorTextLength) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.textMsg)
    }
    if (!fields.announceFigureVersionId && !fields?.announceImage?.figureVersion?.id) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.announcePhotoMSg)
    }
    if (checkYandexDzenTitleValidation && !fields.yandexDzenTitle) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.yandexDzenTitle)
    }
    if (
      !editorDirty &&
      !publicationFormDirty &&
      !destinationsDirty &&
      ![PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
    ) {
      return PUBLICATION_ACTION_BUTTON_CONSTS.needSomeChangesMSg
    }
    if (
      publication?.status === 'PUBLISHED' &&
      publication.typeSlug === 'news' &&
      hasOverviewBlock &&
      !fields.overviewTitle
    ) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.overviewTitleMsg)
    }
    if (publication?.status === 'PUBLISHED' && hasOverviewBlock && !fields.overviewFigureVersionId) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.overviewFigureMsg)
    }
    if (errors?.length) {
      return `Чтобы ${actionType} материал ${errors.length > 1 ? 'нужны' : 'нужно заполнить поле'} ${errors.join(', ')}`
    }
    return ''
  }, [
    fields,
    publication,
    editorDirty,
    publicationFormDirty,
    destinationsDirty,
    editorTextLength,
    hasOverviewBlock,
    rubricIdCache,
    checkYandexDzenTitleValidation
  ])

  const [disableForm, setDisableForm] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const hasFormErrors = Object.keys(control._formState.errors).length > 0

    setDisableForm(
      hasFormErrors ||
        !fields.title ||
        !fields.seoTitle ||
        !rubricIdCache ||
        !fields.authors?.length ||
        !fields.regionUids ||
        fields.regionUids?.length < 2 ||
        !fields.keywords?.length ||
        (!fields.announceFigureVersionId && !fields?.announceImage?.figureVersion?.id) ||
        (publication.typeSlug === 'news' && hasOverviewBlock && !fields.overviewTitle) ||
        (keycloak.hasResourceRole('publish', 'publications') && hasOverviewBlock && !fields.overviewFigureVersionId) ||
        !editorTextLength ||
        overviewBlocksLoading ||
        (checkYandexDzenTitleValidation && !fields.yandexDzenTitle) ||
        publicationLoading
    )

    const newVal = editorDirty || publicationFormDirty || destinationsDirty
    setIsDirty(newVal)
  }, [
    control._formState.errors,
    fields,
    rubricIdCache,
    editorTextLength,
    publication?.status,
    publication?.typeSlug,
    publication.destinations,
    overviewBlocksLoading,
    publicationLoading,
    hasOverviewBlock,
    editorDirty,
    publicationFormDirty,
    destinationsDirty,
    checkYandexDzenTitleValidation
  ])

  const [loading, setLoading] = useState(
    publicationLoading || publicationContentLoading || overviewBlocksLoading || false
  )

  useEffect(() => {
    setLoading(updatePubliationLoading || publicationLoading || publicationContentLoading || overviewBlocksLoading)
  }, [updatePubliationLoading, publicationLoading, publicationContentLoading, overviewBlocksLoading])

  return (
    <PublicationActionButtonWrapper>
      <PublicationActionButtonFields>
        <PublicationActionButtonFieldsDateAndStatus>
          <PublicationActionButtonFieldsDateAndStatusBlock>
            {publication?.status === 'UNPUBLISHED' && (
              <PublicationRemovedWrapper>
                {UNPUBLISHED_NEWS_PICKER_CONSTS.publicationHasBeenRemoved}
              </PublicationRemovedWrapper>
            )}
            <PublicationDate publication={publication} />
          </PublicationActionButtonFieldsDateAndStatusBlock>
          <PublicationLoading publicationStatus={publication?.status} publicationLoading={loading} />
        </PublicationActionButtonFieldsDateAndStatus>
        <PublicationActionButtonFieldsButtons>
          {([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) ||
            publication?.status === 'UNPUBLISHED') &&
            !keycloak.hasResourceRole('publish', 'publications') && (
              <PublicationActionButtonControlTooltipWrapper
                title=''
                enterDelay={1000}
                disabledTitle={buttonDisabledTooltipMessage}
                color={'default'}
                disabled={disableForm}
                withoutTooltipIfActive={true}
              >
                <PublicationActionButtonBtn
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={sendPublicationForReviewAction}
                  disabled={disableForm}
                >
                  {PUBLICATION_ACTION_BUTTON_CONSTS.forReviewMsg}
                </PublicationActionButtonBtn>
              </PublicationActionButtonControlTooltipWrapper>
            )}
          {publication?.status === 'PUBLISHED' && keycloak.hasResourceRole('unpublish', 'publications') && (
            <ControlTooltip
              title=''
              enterDelay={1000}
              disabledTitle={PUBLICATION_ACTION_BUTTON_CONSTS.removePublicationFromSiteMsg}
              color={'default'}
              withoutTooltipIfActive={true}
              style={{width: '100%', minWidth: '210px'}}
            >
              <PublicationActionButtonBtn
                variant='outlined'
                color='primary'
                size='small'
                onClick={unpublishPublicationAction}
                disabled={unpublishLoading}
              >
                {unpublishLoading ? (
                  <CircularProgress size='1rem' />
                ) : (
                  <span>{PUBLICATION_ACTION_BUTTON_CONSTS.removeFromSiteMsg}</span>
                )}
              </PublicationActionButtonBtn>
            </ControlTooltip>
          )}
          {publication?.status === 'PUBLISHED' && !keycloak.hasResourceRole('edit-published', 'publications') && (
            <PublicationActionButtonBtn
              variant='contained'
              color='primary'
              size='small'
              onClick={closePublicationAction}
            >
              {PUBLICATION_ACTION_BUTTON_CONSTS.closeMsg}
            </PublicationActionButtonBtn>
          )}
          {(publication?.status === 'PUBLISHED' || publication?.status === 'UNPUBLISHED') &&
            keycloak.hasResourceRole('edit-published', 'publications') && (
              <PublicationActionButtonControlTooltipWrapper
                title=''
                enterDelay={1000}
                disabledTitle={buttonDisabledTooltipMessage}
                color={'default'}
                disabled={disableForm || !isDirty}
                withoutTooltipIfActive={true}
              >
                <PublicationActionButtonBtn
                  type='submit'
                  variant={`${publication?.status === 'PUBLISHED' ? 'contained' : 'outlined'}`}
                  color='primary'
                  size='small'
                  disabled={disableForm || hasTitlesError || !isDirty}
                >
                  {loading ? <CircularProgress size='1rem' /> : <span>{PUBLICATION_ACTION_BUTTON_CONSTS.saveMsg}</span>}
                </PublicationActionButtonBtn>
              </PublicationActionButtonControlTooltipWrapper>
            )}
          {([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) ||
            publication?.status === PublicationStatus.Review ||
            publication?.status === PublicationStatus.Unpublished) &&
            keycloak.hasResourceRole('publish', 'publications') && (
              <ControlTooltip
                title=''
                disabledTitle={buttonDisabledTooltipMessage}
                enterDelay={500}
                disabled={disableForm}
                color={'default'}
                withoutTooltipIfActive={true}
              >
                <PublicationActionButtonBtn
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={publishPublicationAction}
                  disabled={disableForm || hasTitlesError || publishLoading || loading}
                >
                  {publishLoading ? (
                    <>
                      <span>{PUBLICATION_ACTION_BUTTON_CONSTS.savingMsg}</span>
                      <CircularProgress size='1rem' />
                    </>
                  ) : (
                    <span>{PUBLICATION_ACTION_BUTTON_CONSTS.publishMsg}</span>
                  )}
                </PublicationActionButtonBtn>
              </ControlTooltip>
            )}
        </PublicationActionButtonFieldsButtons>
      </PublicationActionButtonFields>
    </PublicationActionButtonWrapper>
  )
}

export default observer(PublicationActionButton)
