import React, {useEffect, useState} from 'react'
import {toast, ToastItem} from 'react-toastify'
import {ToastErrorCollapserWrapper} from './ToastErrorCollapser.styles'

export const ToastErrorCollapser = () => {
    const [showCollapser, setShowCollapser] = useState(false)
    const [toastErrors, setToastErrors] = useState<ToastItem[]>([])
    const collapseAllToast = () => {
        toast.clearWaitingQueue()
        toast.dismiss()
        const toastCollapser = document.getElementById('toastCollapser')
        if (toastCollapser) {
            toastCollapser.style.display = 'none'
        }
    }

    useEffect(() => {
        toast.onChange(currentToast => {
            if (currentToast.status === 'added') {
                setShowCollapser(true)
                setToastErrors(prev => [...prev, currentToast])
            } else {
                setToastErrors(prevState =>
                    prevState.filter(item => currentToast.id !== item.id)
                )
                if (toastErrors.length === 0) {
                    setShowCollapser(false)
                }
            }
        })
    }, [toast])

    return (
        <ToastErrorCollapserWrapper
            id='toastCollapser'
            onClick={collapseAllToast}
            style={{
                display: `${showCollapser === true ? 'block' : 'none'}`
            }}
        >
            Скрыть всё
        </ToastErrorCollapserWrapper>
    )
}
