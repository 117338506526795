import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Controller, FormProvider, useForm, useFormContext, useWatch} from 'react-hook-form'
import * as _ from 'lodash'
import {useKeycloak} from '@react-keycloak/web'
import {debounce} from 'lodash'
import {useStore} from '@stores/rootStoreContext'
import PopoverPopupState from '@components/UI/PopoverInfoButton/PopoverInfoButton'
import {HELP_INFO_YA_ZEN} from '@components/UI/PopoverInfoButton/TextInfo'
import {Destination, PublicationStatus, PublicationYandexNewsVideo, YandexZenCategory} from '@graphql/types'
import RHFCheckboxLabel from '../RHFCheckboxLabel/RHFCheckboxLabel'
import {useUpdatePublicationExportMutation} from '../gql/PublicationEditor.generated'
import {ETitlesField} from '../PublicationFormTitles/PublicationFormTitles.types'
import {DestinationPickerValues} from './DestinationPickerValues'
import {
  BoxWithInfo,
  DetailsRowSelects,
  PublicationDestinationPickerWrapper,
  YandexDzenTitleWrapper
} from './PublicationDestinationPicker.styles'
import {PUBLICATION_DESTINATION_PICKER_CONSTS} from './PublicationDestinationPicker.consts'
import YandexDzenTitle from './YandexDzenTitle/YandexDzenTitle'

export const exportSlugs = {
  pulseMail: 'mail-pulse',
  yandexNews: 'yandex-news',
  yandexZen: 'yandex-zen'
}

const PublicationDestinationPicker: FC = () => {
  const {publicationStore} = useStore()
  const {
    publication,
    destinationsDirty,
    setDestinationsDirty,
    setCheckYandexDzenTitleValidation,
    setPublicationWithPrevState
  } = publicationStore
  const {setValue, control: publicationControl, setError, clearErrors} = useFormContext()
  const [updateExport] = useUpdatePublicationExportMutation()
  const [destinationsCache, setDestinationsCache] = useState({
    yandexNews:
      publication.destinations?.length &&
      publication.destinations?.findIndex((item: any) => {
        if (item.__typename === 'Destination') {
          if (item?.slug === 'yandex-news') {
            return true
          }
        }
        return false
      }) > -1
        ? true
        : false,
    yandexZen:
      publication.destinations?.length &&
      publication.destinations?.findIndex((item: any) => {
        if (item.__typename === 'Destination') {
          if (item?.slug === 'yandex-zen') {
            return true
          }
        }
        return false
      }) > -1
        ? true
        : false
  })

  const {keycloak} = useKeycloak()
  const cantEditPublication = useMemo(
    () => publication?.status === 'PUBLISHED' && !keycloak.hasResourceRole('edit-published', 'publications'),

    [keycloak, publication?.status]
  )
  const cantEditInYandexDzen = useMemo(
    () => !keycloak.hasResourceRole('edit-published', 'publications'),

    [keycloak]
  )
  const defaultValues = useMemo<DestinationPickerValues>(
    () => ({
      yandexNews: !!publication?.destinations?.map(v => v?.slug).includes('yandex-news'),
      yandexZen: !!publication?.destinations?.map(v => v?.slug).includes('yandex-zen'),
      yandexZenCategories: publication?.yandexZenCategories?.map(v => v?.uid) || []
    }),
    [publication?.destinations, publication?.yandexZenCategories]
  )

  const {getValues, control, reset, ...methodsForm} = useForm({
    defaultValues: defaultValues
  })

  const allFields = useWatch({control})

  useEffect(() => {
    if (
      //   allFields?.pulseMail !== defaultValues?.pulseMail ||
      allFields?.yandexNews !== defaultValues?.yandexNews ||
      allFields?.yandexZen !== defaultValues?.yandexZen
    ) {
      setDestinationsDirty(true)
    } else {
      setDestinationsDirty(false)
    }

    setCheckYandexDzenTitleValidation(allFields.yandexZen === true ? true : false)
  }, [allFields, defaultValues, publicationControl, setDestinationsDirty, setCheckYandexDzenTitleValidation])

  const updateExportAction = useCallback(
    async (updateExportData: any) => {
      const updateExportRes = await updateExport({
        variables: {
          data: updateExportData
        }
      })

      if (!updateExportRes?.errors?.length) {
        setPublicationWithPrevState(prev => ({
          ...prev,
          destinations:
            (updateExportRes.data?.updatePublicationExport?.publication?.destinations as Destination[]) || [],
          yandexZenCategories:
            (updateExportRes.data?.updatePublicationExport?.publication?.yandexZenCategories as YandexZenCategory[]) ||
            [],
          yandexNewsVideos:
            (updateExportRes.data?.updatePublicationExport?.publication
              ?.yandexNewsVideos as PublicationYandexNewsVideo[]) || []
        }))
      }
    },
    [setPublicationWithPrevState]
  )
  const destinationUpdateExport = useCallback(() => {
    const destinationsCacheArray = Object.keys(_.pickBy(destinationsCache, v => v == true)).map(v => exportSlugs[v])

    const updateExportData = {
      publicationUid: publication.uid,
      destinationSlugs: destinationsCacheArray,
      yandexZenCategoryUids: []
    }

    const timer = setTimeout(() => {
      updateExportAction(updateExportData)
      setDestinationsDirty(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [publication.uid, updateExportAction, setDestinationsDirty, destinationsCache])

  useEffect(() => {
    if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) && destinationsDirty) {
      destinationUpdateExport()
    }
  }, [
    publication.uid,
    publication.status,
    setValue,
    updateExport,
    updateExportAction,
    destinationsCache,
    destinationsDirty,
    destinationUpdateExport
  ])

  const handleCheckbox = useCallback(
    (fieldName: string) => {
      if (Object.keys(destinationsCache).includes(fieldName)) {
        const newDestinationsCache = destinationsCache
        newDestinationsCache[fieldName] = !newDestinationsCache[fieldName]
        setDestinationsCache(newDestinationsCache)
      }
    },
    [destinationsCache]
  )

  useEffect(() => {
    window.addEventListener('publicationDestinationChange', () => destinationUpdateExport())

    return () => {
      window.removeEventListener('publicationDestinationChange', () => destinationUpdateExport())
    }
  }, [destinationUpdateExport])

  // const handleYandexDzenTitleChange = useCallback(
  //   (val: string, _fieldName) => {
  //     console.log('val', val)
  //     allFields.yandexDzenTitle = val
  //     setValue('yandexDzenTitle', val)
  //   },
  //   [allFields.yandexDzenTitle, setValue]
  // )

  useEffect(() => {
    setValue('yandexDzenTitle', publication.yandexDzenTitle)
  }, [publication.yandexDzenTitle, setValue])

  const setTextError = useCallback(
    (errorMessage: string) => {
      if (errorMessage) {
        setError(ETitlesField.YandexDzenTitle, {
          message: errorMessage
        })
        return
      }
      clearErrors(ETitlesField.YandexDzenTitle)
    },
    [setError, clearErrors]
  )

  return (
    <div
      style={{
        width: '100%',
        marginTop: '15px'
      }}
    >
      <FormProvider
        {...{
          control,
          getValues,
          reset,
          ...methodsForm
        }}
      >
        <PublicationDestinationPickerWrapper>
          {/* <BoxWithInfo>
          <RHFCheckboxLabel
            name='pulseMail'
            label='В Пульс Mail'
            style={{marginRight: '4px'}}
            disabled={cantEditPublication}
            handleClick={handleCheckbox}
          />
          <PopoverPopupState info={HELP_INFO_MAIL} />
        </BoxWithInfo> */}
          <DetailsRowSelects>
            <BoxWithInfo
              style={{
                width: '275px'
              }}
            >
              <RHFCheckboxLabel
                name='yandexNews'
                label={PUBLICATION_DESTINATION_PICKER_CONSTS.inZenNewsMsg}
                disabled={cantEditPublication}
                style={{marginRight: '6px'}}
                handleClick={handleCheckbox}
              />
              <PopoverPopupState
                info={{
                  text: PUBLICATION_DESTINATION_PICKER_CONSTS.onlyWithPhotoNotFromPhotoBankMsg,
                  url: ''
                }}
              />
            </BoxWithInfo>
          </DetailsRowSelects>
          <DetailsRowSelects>
            <BoxWithInfo
              style={{
                width: '275px'
              }}
            >
              <RHFCheckboxLabel
                name='yandexZen'
                label={PUBLICATION_DESTINATION_PICKER_CONSTS.inZenBlogsMsg}
                style={{marginRight: '6px'}}
                disabled={cantEditInYandexDzen}
                handleClick={handleCheckbox}
              />
              <PopoverPopupState info={HELP_INFO_YA_ZEN} />
            </BoxWithInfo>
          </DetailsRowSelects>
          <DetailsRowSelects>
            <Controller
              name='yandexDzenTitle'
              control={publicationControl}
              render={({field}) => (
                <YandexDzenTitleWrapper>
                  <YandexDzenTitle
                    titleLabel={PUBLICATION_DESTINATION_PICKER_CONSTS.yandexDzenTitle}
                    field={field}
                    maxErrorLength={PUBLICATION_DESTINATION_PICKER_CONSTS.yandexDzenTitleMaxErrorLength}
                    maxWarningLength={PUBLICATION_DESTINATION_PICKER_CONSTS.yandexDzenTitleMaxWarningLength}
                    setTextError={setTextError}
                  />
                </YandexDzenTitleWrapper>
              )}
            />
          </DetailsRowSelects>
        </PublicationDestinationPickerWrapper>
      </FormProvider>
    </div>
  )
}

export default observer(PublicationDestinationPicker)
