import ChevronIcon from '@components/UI/Icons/Chevron'
import NnIcon from '@components/UI/Icons/NnIcon'
import Tooltip from '@components/UI/Tooltip/Tooltip'
import {CircularProgress, MenuItem, Typography} from '@mui/material'
import {useStore} from '@stores/rootStoreContext'
import {observer} from 'mobx-react-lite'
import {useSnackbar} from 'notistack'
import type {RangeStatic} from 'quill'
import {FC, MouseEvent, useCallback, useState} from 'react'
import {Quill} from 'react-quill'
import {StyledToggleButton} from '../Toolbar.styles'
import {NnItem} from './NnItem'
import {TOOLBAR_SIZE_CONSTS} from './ToolbarNn.consts'
import {StyledMenu} from './ToolbarNn.styles'

const Delta = Quill.import('delta')

type ToolbarVideoProps = {
  editorRef: any
}

const ToolbarNn: FC<ToolbarVideoProps> = ({editorRef}) => {
  const [selectedSize, setSelectedSize] = useState<NnItem>({
    color: '',
    size: '',
    title: 'N'
  })
  const {publicationStore} = useStore()
  const {publication} = publicationStore

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const snackbar = useSnackbar()
  const open = Boolean(anchorElement)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }
  const [isLoading, setIsLoading] = useState(false)

  const setSizeCallback_alisa = useCallback(
    (data: any) => {
      let quill: Quill | undefined
      let range: RangeStatic | undefined

      if (editorRef.current !== null) {
        quill = editorRef.current.getEditor()
        range = quill?.getSelection(true)
      }

      if (quill && range) {
        // Получаем значение по ключу text
        const text = data.text

        if (text === 'Нет похожих публикаций') {
          snackbar.enqueueSnackbar('Нет похожих публикаций', {variant: 'error', autoHideDuration: 10000})
        }
        if (text === 'Такой рубрики нет') {
          snackbar.enqueueSnackbar('Такой рубрики нет', {variant: 'error', autoHideDuration: 10000})
        }
        if (text === 'Не смогли найти похожую публикации по выбранной рубрике и регионам') {
          snackbar.enqueueSnackbar('Не смогли найти похожую публикации по выбранной рубрике и регионам', {
            variant: 'error',
            autoHideDuration: 10000
          })
        } else {
          // Получаем текущую длину контента
          const currentLength = quill.getLength()

          // Получаем последний символ
          const lastChar = quill.getText(currentLength)

          // Определяем позицию вставки текста
          let insertPosition
          if (lastChar === '\n') {
            insertPosition = currentLength - 4
          } else {
            insertPosition = currentLength + 1
          }

          // Вставляем текст в указанную позицию
          quill.insertText(insertPosition, text.toString(), 'user')

          // Устанавливаем курсор в конец вставленного текста
          const insertedLength = text.length
          quill.setSelection(insertPosition + insertedLength, 0)

          // Вставка слова с ссылкой
          const word = data.word
          const url = data.url
          const index = text.indexOf(word) - 1

          if (index !== -1) {
            // Вставляем слово с ссылкой в нужную позицию
            quill.insertText(insertPosition + index, word, {link: url}, 'user')
            // Удаляем старое слово (после вставки, чтобы не сдвигать текст)
            quill.deleteText(insertPosition + index + word.length, word.length, 'user')
          }
        }

        setIsLoading(false)
      }
    },
    [editorRef, snackbar]
  )
  const setSizeCallback = useCallback(
    (data: any) => {
      let quill: Quill | undefined
      let range: RangeStatic | undefined

      if (editorRef.current !== null) {
        quill = editorRef.current.getEditor()
        range = quill?.getSelection(true)
      }

      if (quill && range) {
        // Получаем значение по ключу text
        const text = data.text

        if (text === 'Нет похожих публикаций') {
          snackbar.enqueueSnackbar('Нет похожих публикаций', {variant: 'error', autoHideDuration: 10000})
        }
        if (text === 'Публикация не найдена') {
          snackbar.enqueueSnackbar('Нет похожих публикаций', {variant: 'error', autoHideDuration: 10000})
        } else {
          // Получаем текущую длину контента
          const currentLength = quill.getLength()

          // Получаем последний символ
          const lastChar = quill.getText(currentLength)

          // Определяем позицию вставки текста
          let insertPosition
          if (lastChar === '\n') {
            insertPosition = currentLength - 4
          } else {
            insertPosition = currentLength + 1
          }

          // Вставляем текст в указанную позицию
          quill.insertText(insertPosition, text.toString(), 'user')

          // Устанавливаем курсор в конец вставленного текста
          const insertedLength = text.length
          quill.setSelection(insertPosition + insertedLength, 0)

          // Вставка слова с ссылкой
          const word = data.word
          const url = data.url
          const index = text.indexOf(word) - 1

          if (index !== -1) {
            // Вставляем слово с ссылкой в нужную позицию
            quill.insertText(insertPosition + index, word, {link: url}, 'user')
            // Удаляем старое слово (после вставки, чтобы не сдвигать текст)
            quill.deleteText(insertPosition + index + word.length, word.length, 'user')
          }
        }

        setIsLoading(false)
      }
    },
    [editorRef, snackbar]
  )

  const setSize = useCallback(
    async (_size: string, _color: string, title: string) => {
      let quill: Quill | undefined
      let range: RangeStatic | undefined

      if (editorRef.current !== null) {
        quill = editorRef.current.getEditor()
        range = quill?.getSelection(true)
      }

      if (quill && range) {
        let delta = quill.getContents()
        let requestText = ''

        setIsLoading(true)

        delta.forEach(item => {
          if (typeof item.insert === 'string') {
            requestText += item.insert
            requestText += ' '
          }
        })
        const publication_id = publicationStore.publication?.deprecatedId
        const publication_uid = publicationStore.publication?.id
        if (title === 'Тэгирование') {
          if (requestText.length <= 1) {
            snackbar.enqueueSnackbar('Сначала напишите текст', {
              variant: 'info',
              autoHideDuration: 10000
            })
            setIsLoading(false)
          } else {
            const data = JSON.stringify({
              params: requestText.replace(/\n/g, ' '),
              publicationId: publication_id,
              publicationUid: publication_uid
            })

            try {
              const response = await fetch('https://a.uran.news/person/', {
                // http://localhost:4399
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: data
              })
              if (response.ok) {
                const responseJson = await response.json()
                setIsLoading(false)

                if (responseJson['person'] === 'Нет статей о людях упомянутых в тексте') {
                  snackbar.enqueueSnackbar('Нет статей о людях упомянутых в тексте', {
                    variant: 'error',
                    autoHideDuration: 10000
                  })
                } else {
                  const persons = responseJson['person']
                  persons.forEach((person: {name: string; url: string}) => {
                    const index = quill.getText().indexOf(person.name)
                    if (index !== -1) {
                      quill.formatText(index, person.name.length, {link: person.url}, 'user')
                    }
                  })
                  snackbar.enqueueSnackbar(`Найдено в тексте персон: ${persons.length}`, {
                    variant: 'success',
                    autoHideDuration: 10000
                  })
                }
              } else {
                throw new Error(response.statusText)
              }
            } catch (error) {
              snackbar.enqueueSnackbar(`Error: ${error.message}`, {
                variant: 'error',
                autoHideDuration: 10000
              })
            } finally {
              setIsLoading(false)
            }
          }
        }
        if (title === 'Написать бэк') {
          if (requestText.length <= 1) {
            snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          } else {
            const regions = publicationStore.publication?.regions?.map(v => v?.uid)
            const rubric = publicationStore.publication?.rubric?.title
            const subrubric = publicationStore.publication?.subrubric?.title

            const data = JSON.stringify({
              text: requestText,
              regions,
              rubric,
              subrubric,
              publication_id,
              publication_uid
            })

            if (rubric === undefined) {
              snackbar.enqueueSnackbar('Сначала выберите рубрику', {variant: 'info', autoHideDuration: 10000})
              setIsLoading(false)
            } else {
              try {
                const getNNTextData = await fetch(`https://a.uran.news/back/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    params: data
                  })
                })

                const getNNTextDataJson = await getNNTextData.json()

                setSizeCallback(getNNTextDataJson)
              } catch (error) {
                snackbar.enqueueSnackbar(`Error: ${error}`, {variant: 'error', autoHideDuration: 10000})
                setIsLoading(false)
              }
            }
          }
        }

        // if (title === 'Написать бэк (вектор)') {
        //   if (requestText.length <= 1) {
        //     snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
        //     setIsLoading(false)
        //   } else {
        //     const regions = publicationStore.publication?.regions?.map(v => v?.uid)
        //     const rubric = publicationStore.publication?.rubric?.title
        //     const subrubric = publicationStore.publication?.subrubric?.title

        //     const data = JSON.stringify({
        //       text: requestText,
        //       regions,
        //       rubric,
        //       subrubric,
        //       publication_id,
        //       publication_uid
        //     })

        //     if (rubric === undefined) {
        //       snackbar.enqueueSnackbar('Сначала выберите рубрику', {variant: 'info', autoHideDuration: 10000})
        //       setIsLoading(false)
        //     } else {
        //       try {
        //         const getNNTextData = await fetch(`https://a.uran.news/back/vector`, {
        //           method: 'POST',
        //           headers: {
        //             'Content-Type': 'application/json'
        //           },
        //           body: JSON.stringify({
        //             params: data
        //           })
        //         })

        //         const getNNTextDataJson = await getNNTextData.json()

        //         setSizeCallback_alisa(getNNTextDataJson)
        //       } catch (error) {
        //         snackbar.enqueueSnackbar(`Error: ${error}`, {variant: 'error', autoHideDuration: 10000})
        //         setIsLoading(false)
        //       }
        //     }
        //   }
        // }

        // Вставляет сгенерированный текст после отправленного текста
        if (title === 'Сгенерировать текст по теме') {
          if (requestText.length <= 1) {
            snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          } else {
            const data = JSON.stringify({
              text: requestText,
              publicationId: publication_id,
              publicationUid: publication_uid
            })

            try {
              const generateText = await fetch(`https://a.uran.news/generate/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  params: data
                })
              })
              const generateTextJson = await generateText.json()
              const regExp = /\n\s*\n+/g
              const text = generateTextJson.text.replace(regExp, '\n')
              quill.setContents(
                new Delta({
                  ops: [
                    {
                      insert: text
                    }
                  ]
                }),
                'user'
              )
              setIsLoading(false)
            } catch (error) {
              snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
              setIsLoading(false)
            }
          }
        }

        // Переписывает выделенный текст
        const {index, length} = range
        delta = quill.getContents(index, length)
        const selectingText = delta.ops?.reduce((string, op) => string + op.insert, '')

        if (title === 'Переписать выделенный текст') {
          if (selectingText.length === 0) {
            snackbar.enqueueSnackbar('Сначала выделите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          } else {
            const data = JSON.stringify({
              text: selectingText,
              publicationId: publication_id,
              publicationUid: publication_uid
            })

            try {
              const rewriteText = await fetch(`https://a.uran.news/rewrite/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  params: data
                })
              })

              const rewriteTextJson = await rewriteText.json()
              const text = rewriteTextJson.text

              quill.updateContents(
                index !== 0
                  ? new Delta({
                      ops: [
                        {
                          retain: index
                        },
                        {
                          delete: length
                        },
                        {
                          insert: text
                        }
                      ]
                    })
                  : new Delta({
                      ops: [
                        {
                          delete: length
                        },
                        {
                          insert: text
                        }
                      ]
                    }),
                'user'
              )
              setIsLoading(false)
            } catch (error) {
              snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
              setIsLoading(false)
            }
          }
        }
        if (title === 'Проверить иноагентов и экстремистов') {
          if (requestText.length <= 1) {
            snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          } else {
            const data = JSON.stringify({
              params: requestText.replace(/\n/g, ' '),
              publicationId: publication_id,
              publicationUid: publication_uid
            })
            setIsLoading(true)
            try {
              const response = await fetch('https://a.uran.news/ner/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: data
              })
              if (response.ok) {
                const responseJson = await response.json()
                setIsLoading(false)
                if (responseJson['INOAGENT'].length === 0 && responseJson['EXTREMIST'] === 0) {
                  if (responseJson['INOAGENT MARKED']) {
                    snackbar.enqueueSnackbar('Все иноагенты и экстримисты помечены', {
                      variant: 'success',
                      autoHideDuration: 10000
                    })
                  } else {
                    snackbar.enqueueSnackbar('Найдено: 0 иноагентов и экстремистов', {
                      variant: 'success',
                      autoHideDuration: 10000
                    })
                  }
                } else {
                  let message =
                    'Кол-во пометок меньше, чем кол-во иноагентов на ' +
                    responseJson['INOAGENT'].length +
                    ' ' +
                    responseJson['INOAGENT'].toString()

                  if (responseJson['EXTREMIST']) {
                    message +=
                      ' и ' +
                      responseJson['EXTREMIST'].length +
                      ' экстремистов: ' +
                      responseJson['EXTREMIST'].toString()
                  }

                  snackbar.enqueueSnackbar(message, {variant: 'error', autoHideDuration: 10000})
                }
              } else {
                throw new Error(response.statusText)
              }
            } catch (error) {
              snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
            } finally {
              setIsLoading(false)
            }
          }
        }
        if (title === 'Рерайт') {
          if (selectingText.length === 0) {
            snackbar.enqueueSnackbar('Сначала выделите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          } else {
            const data = JSON.stringify({
              text: selectingText,
              publicationId: publication_id,
              publicationUid: publication_uid
            })

            try {
              const rewriteText = await fetch(`https://a.uran.news/test/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  params: data
                })
              })

              const rewriteTextJson = await rewriteText.json()
              const text = rewriteTextJson.text

              quill.updateContents(
                index !== 0
                  ? new Delta({
                      ops: [
                        {
                          retain: index
                        },
                        {
                          delete: length
                        },
                        {
                          insert: text
                        }
                      ]
                    })
                  : new Delta({
                      ops: [
                        {
                          delete: length
                        },
                        {
                          insert: text
                        }
                      ]
                    }),
                'user'
              )
              setIsLoading(false)
            } catch (error) {
              snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
              setIsLoading(false)
            }
          }
        }
        quill.setSelection(range.index + 1, 0)
        quill.getSelection(true)
        quill.focus()
      }
    },
    [editorRef, setSizeCallback, snackbar]
  )

  const selectSize = useCallback(
    (sizeItem: NnItem) => {
      if (sizeItem) {
        setSelectedSize(sizeItem)
        setSize(sizeItem.size || '', sizeItem.color || '', sizeItem.title)
      }
      handleClose()
    },
    [setSize, setSelectedSize]
  )

  return (
    <>
      <Tooltip title={isLoading ? TOOLBAR_SIZE_CONSTS.textLoading : TOOLBAR_SIZE_CONSTS.textSize}>
        <StyledToggleButton
          id='basic-button'
          value=''
          aria-controls={open ? 'text-size-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress disableShrink color='secondary' size='1rem' /> : <NnIcon></NnIcon>}
          <Typography
            color='textPrimary'
            variant={selectedSize?.title === 'T' ? 'h1' : 'inherit'}
            style={{
              display: 'contents',
              verticalAlign: 'middle'
            }}
          >
            <span>&nbsp;</span>
            <ChevronIcon></ChevronIcon>
          </Typography>
        </StyledToggleButton>
      </Tooltip>
      <StyledMenu
        id='text-size-menu'
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {TOOLBAR_SIZE_CONSTS.sizeItems.map(item => (
          <MenuItem onClick={() => selectSize(item)} key={item.title} value={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
export default observer(ToolbarNn)
