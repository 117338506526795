import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Switch,
  Tooltip
} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import {useKeycloak} from '@react-keycloak/web'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {CropInput, Figure} from '@graphql/types'
import {generateGuid} from '@utils/generateGuid'
import {InputStringWithValidation} from '@components/UI/InputStringWithValidation/InputStringWithValidation'
import DeleteIcon from '@components/UI/Icons/DeleteIcon'
import {useStore} from '@stores/rootStoreContext'
import ImageCropper from '../ImageCropper/ImageCropper'
import {useCreateFigureVersionMutation} from '../../../Publication/Form/gql/PublicationEditor.generated'
import {StyledFormControl} from '../../styled'
import {ImageBlotProps} from '../../../TextEditor/Blots/ImageBlot/ImageBlotProps'
import {CropperType} from '../../../../types/CropperType'
import {redColor} from './../../../../@theme/vars'
import {IMAGE_EDITOR_MODAL_CONSTS} from './ImageEditorModal.consts'
import {ImageEditorModalProps} from './ImageEditorModalProps'

const ImageEditorModal: FC<ImageEditorModalProps> = ({
  defaultFigure,
  modalId,
  open,
  onClose,
  title,
  text,
  okLabel,
  noLabel,
  ratio = 16 / 9,
  isCustomRatio = false,
  addFigure,
  needMoreDescription = false,
  setValueFormContext,
  figureTypeSlug,
  deleteFigure,
  fullWidth = false,
  updateCacheFigures
}) => {
  const [imageUrl, setImageUrl] = useState(defaultFigure?.src)
  const [id, setId] = useState(modalId || generateGuid())
  const [figure, setFigure] = useState<Figure>(defaultFigure as Figure)
  const {cropperStore, publicationStore} = useStore()
  const {cropper, cropperIsValid, isCropperChanged, setCropper, setIsCropperChanged} = cropperStore
  const {imageModalStore, setCheckingPhotoEditorCache} = publicationStore
  const [description, setDescription] = useState(defaultFigure?.description)
  const [descriptionDirty, setDescriptionDirty] = useState(false)
  const [descriptionErrorText, setDescriptionErrorText] = useState('')
  const [defaultAlt, setDefaultAlt] = useState(defaultFigure?.alt)
  const [alt, setAlt] = useState(defaultFigure?.alt)
  // const [altDirty, setAltDirty] = useState(false)
  const [altErrorText, setAltErrorText] = useState('')
  const [hasWatermark, setHasWatermark] = useState(!!defaultFigure?.hasWatermark)
  const [figureVersionId, setFigureVersionId] = useState(defaultFigure?.latestVersion?.id)
  const [hasDiagonalWatermark, setHasDiagonalWatermark] = useState(!!defaultFigure?.hasDiagonalWatermark)
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [createFigureVersion, {loading: createFigureVersionLoading}] = useCreateFigureVersionMutation()
  const {setValue} = useFormContext()
  const {keycloak} = useKeycloak()
  const [clearFieldEventCounter, setClearFieldEventCounter] = useState(0)
  const isAltInputVisible = false
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const {author, authorErrorText, isModalDirty, setAuthor, setAuthorErrorText, setIsModalDirty} = imageModalStore

  useEffect(() => {
    setId(modalId || generateGuid())
    setFigure(defaultFigure as Figure)
    setImageUrl(defaultFigure?.src || '')
    setHasDiagonalWatermark(!!defaultFigure?.hasDiagonalWatermark)
    setDefaultAlt(defaultFigure?.alt || '')
    setAuthor(defaultFigure?.author || '')
    setDescription(defaultFigure?.description || '')
    setAlt(defaultFigure?.alt || '')
    setIsFullWidth(fullWidth || false)
    if (defaultFigure?.latestVersion?.crop) {
      setCropper(defaultFigure?.latestVersion?.crop as CropInput)
    }
    setIsCropperChanged(false)
  }, [defaultFigure, modalId, fullWidth, setAuthor, setCropper, setIsCropperChanged])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalDirty(
        (author && author !== defaultFigure?.author) ||
          description !== defaultFigure?.description ||
          isCropperChanged ||
          // cropper?.width !== defaultFigure?.latestVersion?.crop.width ||
          // cropper?.height !== defaultFigure?.latestVersion?.crop.height ||
          // cropper?.left !== defaultFigure?.latestVersion?.crop.left ||
          // cropper?.top !== defaultFigure?.latestVersion?.crop.top ||
          imageUrl !== defaultFigure?.src ||
          hasDiagonalWatermark !== !!defaultFigure?.hasDiagonalWatermark ||
          isFullWidth !== fullWidth
      )
    }, 100)
    return () => clearTimeout(timer)
  }, [
    setIsModalDirty,
    author,
    description,
    hasDiagonalWatermark,
    isCropperChanged,
    // cropper,
    defaultFigure,
    id,
    figure?.uid,
    imageUrl,
    defaultAlt,
    isFullWidth,
    fullWidth
  ])

  useEffect(() => {
    if (
      description?.length &&
      (alt === '' ||
        description?.includes(alt || '', 0) ||
        description?.includes((alt || '').slice(0, (alt?.length || 0) > 0 ? (alt?.length || 0) - 1 : 0), 0))
    ) {
      setDefaultAlt(description)
    }
    if (description && description?.length > IMAGE_EDITOR_MODAL_CONSTS.maxDescriptionLength) {
      setDescriptionErrorText(IMAGE_EDITOR_MODAL_CONSTS.characterLimitReachedMsg)
    } else {
      setDescriptionErrorText('')
    }
    if (!description && descriptionDirty) {
      setDescriptionErrorText(IMAGE_EDITOR_MODAL_CONSTS.fieldIsRequiredMsg)
    }
  }, [description, setDefaultAlt, setDescriptionErrorText])

  const createFigureVersionAction = useCallback(async () => {
    if (addFigure) {
      const newFigure = await createFigureVersion({
        variables: {
          data: {
            figureId: figure?.id,
            hasWatermark: hasWatermark,
            hasDiagonalWatermark: hasDiagonalWatermark,
            crop: cropper
          }
        }
      })

      const imageBlot: ImageBlotProps = {
        id: id || generateGuid(),
        crop: cropper,
        figureId: newFigure.data?.createFigureVersion?.figureVersion?.figure?.id || figure?.id || '',
        figureUid: newFigure.data?.createFigureVersion?.figureVersion?.figure?.uid || figure?.uid || '',
        figureVersionId: newFigure.data?.createFigureVersion?.figureVersion?.id || figureVersionId || '',
        // hasWatermark:
        //     newFigure.data?.createFigureVersion?.figureVersion
        //         ?.hasWatermark || false,
        // hasDiagonalWatermark:
        //     newFigure.data?.createFigureVersion?.figureVersion
        //         ?.hasDiagonalWatermark || false,
        src: newFigure.data?.createFigureVersion?.figureVersion?.figure?.latestVersion.primaryProcessedImage?.url || '',
        cropperPreviewUrl:
          newFigure.data?.createFigureVersion?.figureVersion?.figure?.cropperPreview?.url ||
          figure?.cropperPreview?.url ||
          '',
        isFullWidth: isFullWidth || false,
        author: author || (keycloak?.tokenParsed && keycloak?.tokenParsed['name']) || '',
        description: description || '',
        alt: alt || '',
        isannouncingphoto: false,
        hasWatermark: hasWatermark || false,
        hasDiagonalWatermark: hasDiagonalWatermark || false
      }

      setFigureVersionId(newFigure.data?.createFigureVersion?.figureVersion?.id || '')

      addFigure(imageBlot)

      if (updateCacheFigures) {
        updateCacheFigures(imageBlot)
      }
    }
    setCheckingPhotoEditorCache(false)

    onClose()
    setId('')
  }, [
    createFigureVersion,
    hasWatermark,
    hasDiagonalWatermark,
    cropper,
    alt,
    author,
    description,
    figure?.uid,
    setFigureVersionId,
    addFigure,
    setValueFormContext,
    onClose,
    setValue,
    setCheckingPhotoEditorCache,
    id,
    isFullWidth,
    figureVersionId,
    imageUrl
  ])

  const closeModal = useCallback(() => {
    // setAltDirty(false)
    // setAuthorDirty(false)
    setDescriptionDirty(false)
    setIsOpenConfirmDialog(false)
    onClose()
  }, [onClose])

  const toggleIsOpenConfirmDialog = useCallback(() => {
    !isModalDirty ? closeModal() : setIsOpenConfirmDialog(!isOpenConfirmDialog)
  }, [isOpenConfirmDialog, isModalDirty, closeModal])

  const deleteFigureAction = useCallback(async () => {
    if (deleteFigure && id) {
      deleteFigure(id)
    }
    closeModal()
  }, [deleteFigure, closeModal, id])

  const clearFigure = useCallback(() => {
    setFigure({} as Figure)
    setImageUrl('')
    setHasDiagonalWatermark(false)
    // setAuthorDirty(false)
    setAuthorErrorText('')
    setDefaultAlt('')
    // setAuthor('')
    setDescription('')
    setDescriptionDirty(false)
    setAlt('')
    setIsFullWidth(false)
    setClearFieldEventCounter(prev => prev + 1)
  }, [])

  const [okButtonDisabled, setOkButtonDisabled] = useState(false)

  useEffect(() => {
    setOkButtonDisabled(
      needMoreDescription &&
        (!author ||
          !figure?.uid ||
          // !alt ||
          !description ||
          !cropperIsValid ||
          !!descriptionErrorText ||
          !!authorErrorText ||
          !!altErrorText ||
          createFigureVersionLoading === true ||
          !isModalDirty)
    )
  }, [
    needMoreDescription,
    author,
    figure?.uid,
    // alt,
    description,
    cropperIsValid,
    descriptionErrorText,
    authorErrorText,
    altErrorText,
    createFigureVersionLoading,
    isModalDirty
  ])

  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={toggleIsOpenConfirmDialog}
      title={title}
      text={text}
      onOk={createFigureVersionAction}
      onNo={deleteFigureAction}
      okButtonDisabled={okButtonDisabled}
      okButtonDisabledTooltip={
        isModalDirty ? IMAGE_EDITOR_MODAL_CONSTS.fillRequiredFields : IMAGE_EDITOR_MODAL_CONSTS.makeChangeMsg
      }
      noButtonDisabled={false}
      okLabel={okLabel}
      noLabel={noLabel}
      loading={createFigureVersionLoading}
    >
      <>
        <ImageCropper
          figure={figure}
          imageUrl={imageUrl}
          description={description || ''}
          hasWatermark={hasWatermark || false}
          hasDiagonalWatermark={hasDiagonalWatermark || false}
          setImageUrl={setImageUrl}
          setAuthor={setAuthor}
          setDescription={setDescription}
          setFigure={setFigure}
          setAlt={setDefaultAlt}
          setHasWatermark={setHasWatermark}
          setHasDiagonalWatermark={setHasDiagonalWatermark}
          ratio={ratio}
          figureTypeSlug={figureTypeSlug}
          cropperType={CropperType.isImageEditorModal}
          isCustomRatio={isCustomRatio}
          figureIsLoading={createFigureVersionLoading}
        />
        {figure?.src && (
          <div
            style={{
              display: 'block',
              position: 'absolute',
              top: '13px',
              right: '28px'
            }}
          >
            <Tooltip title={IMAGE_EDITOR_MODAL_CONSTS.setAnotherPhotoLabelMsg}>
              <IconButton edge='end' size='small' id='announce-image-modal-delete-photo' onClick={() => clearFigure()}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {needMoreDescription && (
          <Box display='flex' flexDirection='column' marginTop='28px'>
            <StyledFormControl width='100%' required error={!description && descriptionDirty}>
              <div style={{flexDirection: 'row'}}>
                <InputLabel
                  style={{
                    width: '100%'
                  }}
                >
                  {IMAGE_EDITOR_MODAL_CONSTS.descriptionLabelMsg}
                </InputLabel>
                <OutlinedInput
                  id={'image-editor-modal-description'}
                  label={IMAGE_EDITOR_MODAL_CONSTS.descriptionLabelMsg}
                  error={!!descriptionErrorText}
                  multiline
                  style={{
                    float: 'left',
                    width: '100%'
                  }}
                  onChange={e => {
                    setDescription(e.target.value)
                    setDescriptionDirty(true)
                  }}
                  value={description}
                  required
                />
              </div>
              {!!descriptionErrorText && (
                <FormHelperText
                  style={{
                    color: redColor
                  }}
                >
                  {descriptionErrorText}
                </FormHelperText>
              )}
            </StyledFormControl>
            <Box display='flex' justifyContent='space-between' marginTop='28px'>
              <InputStringWithValidation
                id={'image-editor-modal-author'}
                title={IMAGE_EDITOR_MODAL_CONSTS.authorLabelMsg}
                defaultValue={author}
                setValue={setAuthor}
                clearFieldEventCounter={clearFieldEventCounter}
                setErrorText={setAuthorErrorText}
                showTitleLength={false}
                maxLength={250}
              />
              {isAltInputVisible && (
                <InputStringWithValidation
                  id={'image-editor-modal-alt'}
                  title={IMAGE_EDITOR_MODAL_CONSTS.altLabelMsg}
                  defaultValue={defaultAlt}
                  setValue={setAlt}
                  setErrorText={setAltErrorText}
                  showTitleLength={false}
                  maxLength={777}
                />
              )}
            </Box>

            <Box display='flex' justifyContent='space-around' marginTop='10px'>
              <FormControlLabel
                label={IMAGE_EDITOR_MODAL_CONSTS.diagonalWatermarkLabelMsg}
                control={
                  <Switch
                    checked={hasDiagonalWatermark}
                    value={hasDiagonalWatermark}
                    onChange={() => setHasDiagonalWatermark(prevState => !prevState)}
                  />
                }
              />
              <FormControlLabel
                label={IMAGE_EDITOR_MODAL_CONSTS.fullWidthLabelMsg}
                control={
                  <Switch
                    checked={isFullWidth}
                    value={isFullWidth}
                    onChange={() => setIsFullWidth(prevState => !prevState)}
                  />
                }
              />
            </Box>
          </Box>
        )}
      </>
      <SimpleModal
        open={isOpenConfirmDialog}
        mode='alert'
        title={IMAGE_EDITOR_MODAL_CONSTS.warningMsg}
        okLabel={IMAGE_EDITOR_MODAL_CONSTS.closeConfirmBtn}
        onOk={closeModal}
        onYes={closeModal}
        onClose={toggleIsOpenConfirmDialog}
        onAbort={toggleIsOpenConfirmDialog}
        onNo={toggleIsOpenConfirmDialog}
        text={IMAGE_EDITOR_MODAL_CONSTS.closeConfirmationText}
      />
    </SimpleModal>
  )
}

export default observer(ImageEditorModal)
