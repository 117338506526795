import {FC} from 'react'
import {
  AdPublicationsPage,
  BannerPage,
  BildCheckListPage,
  CollectionEditorPage,
  CollectionsPage,
  DictionariesPage,
  HotNewsPage,
  InsidersPage,
  NotFound,
  OverviewPage,
  PublicationPage,
  PublicationsListPage,
  ReferencePage,
  ReportsPage,
  StaffPage,
  StoriesPage,
  StoryEditorPage,
  TagEditorPage,
  TagsPage,
  TopNewsBannerHistoryPage,
  TopNewsBannersEditPage,
  TopNewsBlockHistoryPage,
  TopNewsHistoryPage,
  TopNewsPage,
  TopNewsSelectionHistoryPage
} from '../pages'

type RouteItem = {
  name: string
  path: string
  component: FC
  exact?: boolean
  permission: string
  resource?: string
}

export const routes: RouteItem[] = [
  {
    name: 'News',
    path: '/news',
    component: PublicationsListPage,
    exact: true,
    permission: 'manage-publications',
    resource: 'publications'
  },
  {
    name: 'FastNews',
    path: '/news?fast-news=true',
    component: PublicationsListPage,
    exact: true,
    permission: 'manage-publications',
    resource: 'publications'
  },
  {
    name: 'Articles',
    path: '/articles',
    component: PublicationsListPage,
    exact: true,
    permission: 'manage-publications',
    resource: 'publications'
  },
  {
    path: '/stories',
    name: 'Stories',
    component: StoriesPage,
    exact: true,
    //TODO: поменять на нормальное
    permission: 'manage-stories',
    resource: 'publications'
  },
  {
    name: 'Publication editor',
    path: '/editor/:uid',
    component: PublicationPage,
    exact: true,
    permission: 'manage-publications',
    resource: 'publications'
  },
  {
    name: 'Edit story',
    path: '/story/editor/:uid',
    component: StoryEditorPage,
    exact: true,
    permission: 'manage-stories',
    resource: 'publications'
  },
  {
    name: 'Collections',
    path: '/collections',
    component: CollectionsPage,
    exact: true,
    permission: 'manage-collections',
    resource: 'publications'
  },
  {
    name: 'Top news blocks history',
    path: '/main-page/top-news/history/:regionId?',
    component: TopNewsHistoryPage, // страница истории изменений главное за день
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Top news blocks history',
    path: '/main-page/top-news/history/:regionId/:uid',
    component: TopNewsBannerHistoryPage,
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Top day',
    path: '/main-page/top-news/:regionId?',
    component: TopNewsPage, // страница главное за день
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Top day',
    path: '/main-page/top-news/:regionId/edit/:uid', // страница редактирования главной за день
    component: TopNewsBannersEditPage,
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Banner',
    path: '/main-page/banner',
    component: BannerPage,
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'HotNews',
    path: '/main-page/hot-news',
    component: HotNewsPage, // страница горячая новость
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Top day',
    path: '/main-page/top-news/history',
    component: TopNewsSelectionHistoryPage,
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Top day',
    path: '/main-page/top-news/history/block/:uid',
    component: TopNewsBlockHistoryPage,
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Overview',
    path: '/main-page/overview',
    component: OverviewPage,
    exact: true,
    permission: 'manage-main-page',
    resource: 'main-page'
  },
  {
    name: 'Collection editor',
    path: '/collections/editor/:uid?',
    component: CollectionEditorPage,
    exact: true,
    permission: 'manage-collections',
    resource: 'publications'
  },
  // {
  //   name: 'Tags',
  //   path: '/tags',
  //   component: TagsPage,
  //   exact: true,
  //   permission: 'manage-tags',
  //   resource: 'publications'
  // },
  // {
  //   name: 'Tag editor',
  //   path: '/tag/editor/:uid?',
  //   component: TagEditorPage,
  //   exact: true,
  //   permission: 'manage-tags',
  //   resource: 'publications'
  // },
  {
    name: 'Insider cards',
    path: '/insiders',
    component: InsidersPage,
    exact: true,
    permission: 'manage-inside-card-templates',
    resource: 'publications'
  },
  {
    name: 'Dictionaries',
    path: '/dictionaries',
    component: DictionariesPage,
    exact: true,
    permission: ''
  },
  {
    name: 'Staff',
    path: '/staff',
    component: StaffPage,
    exact: true,
    permission: 'manage-publications',
    resource: 'publications'
  },
  // {
  //   name: 'Ad publications',
  //   path: '/ad-publications',
  //   component: AdPublicationsPage,
  //   exact: true,
  //   permission: 'manage-publications',
  //   resource: 'publications'
  // },
  {
    name: 'Bild check list',
    path: '/bild-check-list',
    component: BildCheckListPage,
    exact: true,
    permission: 'check-bildeditor',
    resource: 'publications'
  },
  {
    name: 'Reports',
    path: '/reports',
    component: ReportsPage,
    exact: true,
    permission: 'manage-publications',
    resource: 'publications'
  },
  {
    name: 'Reference',
    path: '/reference',
    component: ReferencePage,
    exact: true,
    permission: ''
  },
  {
    name: 'NotFound',
    path: '**',
    component: NotFound,
    permission: 'admin_panel_access'
  }
]
