import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {Box, IconButton} from '@mui/material'
import {observer} from 'mobx-react-lite'
import Cropper, {ReactCropperElement} from 'react-cropper'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import {debounce} from 'lodash'
import {useStore} from '@stores/rootStoreContext'
import {CropperType} from '../../../../../types/CropperType'
import {IMAGE_CROPPER_CONSTS} from '../ImageCropper.consts'
import {TooltipWrapper} from '../ImageCropper.styles'
import useWindowResize from '../../../../../hooks/useWindowResize'
import {CropperContainerProps} from './CropperContainerProps'

const CropperContainer: FC<CropperContainerProps> = ({cropperType, figure, isCustomRatio, ratio, uploadFileClick}) => {
  const {cropperStore} = useStore()
  const {cropperSrc, setCropper, setIsCropperChanged} = cropperStore
  const [cropperWidth, setCropperWidth] = useState('100%')
  const [cropperHeight, setCropperHeight] = useState('auto')
  const [cropperMinHeight, setCropperMinHeight] = useState('180px')
  const [screenWidth] = useWindowResize()

  const cropperRef = useRef<ReactCropperElement>(null)

  const onCrop = debounce(() => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper.getData(true)

    setCropper({
      left: cropper?.x || 0,
      top: cropper?.y || 0,
      width: cropper?.width || 0,
      height: cropper?.height || 0
    })
  }, 300)

  const onCropChange = debounce((event: Cropper.CropEndEvent<HTMLImageElement>) => {
    event.detail.action !== ('none' as Cropper.Action) && setIsCropperChanged && setIsCropperChanged(true)
  }, 300)

  useEffect(() => {
    let newWidth,
      newHeight,
      newMinHeight = ''

    switch (cropperType) {
      case 'isYandexVideo': {
        newWidth = screenWidth <= 768 ? '320px' : '212px'
        newHeight = screenWidth <= 768 ? '182px' : '120px'
        newMinHeight = screenWidth <= 768 ? '182px' : '120px'
        break
      }
      case 'isAnnounce': {
        newWidth = screenWidth >= 655 ? '252px' : '100%'
        newHeight = '180px'
        newMinHeight = '180px'
        break
      }
      case 'isBreakingNews': {
        newWidth = screenWidth >= 655 ? '206px' : '100%'
        newHeight = '147px'
        newMinHeight = '147px'
        break
      }
      default: {
        newWidth = '100%'
        newHeight = 'auto'
        newMinHeight = '180px'
      }
    }

    setCropperWidth(newWidth)
    setCropperHeight(newHeight)
    setCropperMinHeight(newMinHeight)
  }, [screenWidth, cropperType])

  return (
    <Box display={'flex'} width={cropperWidth} minHeight={cropperMinHeight} height={cropperHeight}>
      <Cropper
        src={cropperSrc}
        aspectRatio={isCustomRatio ? undefined : ratio}
        movable={false}
        scalable={false}
        zoomable={false}
        viewMode={1}
        checkCrossOrigin={true}
        dragMode='move'
        guides={false}
        autoCropArea={1}
        ref={cropperRef}
        crop={onCrop}
        style={{
          width: cropperWidth,
          height: cropperHeight,
          minHeight: cropperMinHeight
        }}
        preview='#imagePreview'
        data={
          figure?.latestVersion?.crop
            ? {
                x: figure?.latestVersion?.crop.left as number,
                y: figure?.latestVersion?.crop.top as number,
                width: figure?.latestVersion?.crop.width as number,
                height: figure?.latestVersion?.crop.height as number
              }
            : {}
        }
        cropmove={event => onCropChange(event)}
      />
      {cropperType === CropperType.isBreakingNews && (
        <TooltipWrapper title={IMAGE_CROPPER_CONSTS.uploadPhotoMsg}>
          <IconButton key='uploadImage' edge='end' size='small' onClick={uploadFileClick}>
            <SystemUpdateAltIcon style={{fontSize: 15}} />
          </IconButton>
        </TooltipWrapper>
      )}
    </Box>
  )
}

export default observer(CropperContainer)
