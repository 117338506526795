import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type UpdatePublicationContentMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationContentInput
}>

export type UpdatePublicationContentMutation = {__typename?: 'Mutation'} & {
  updatePublicationContent?: Types.Maybe<
    {__typename?: 'UpdatePublicationContentPayload'} & Pick<Types.UpdatePublicationContentPayload, 'status'> & {
        content?: Types.Maybe<
          {__typename?: 'PublicationContent'} & {
            elements: Array<
              | ({__typename: 'CustomHTMLElement'} & Pick<Types.CustomHtmlElement, 'id' | 'uid'>)
              | ({__typename: 'GalleryElement'} & Pick<Types.GalleryElement, 'id' | 'uid'>)
              | ({__typename: 'IFrameElement'} & Pick<Types.IFrameElement, 'src' | 'id' | 'uid'>)
              | ({__typename: 'ImageElement'} & Pick<
                  Types.ImageElement,
                  'isFullWidth' | 'author' | 'alt' | 'description' | 'id' | 'uid'
                > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>})
              | ({__typename: 'OnlineBroadcastElement'} & Pick<Types.OnlineBroadcastElement, 'id' | 'uid'>)
              | ({__typename: 'TextElement'} & Pick<Types.TextElement, 'id' | 'uid' | 'content'>)
            >
          }
        >
      }
  >
}

export type GetFigureByUidQueryVariables = Types.Exact<{
  figureUid: Types.Scalars['UUID']
}>

export type GetFigureByUidQuery = {__typename?: 'Query'} & {
  figure?: Types.Maybe<{__typename?: 'Figure'} & FigureFragment>
}

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<Types.FigureType, 'id' | 'uid' | 'slug'>
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
        crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
        primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        processedImages: Array<{__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>>
      }
  }

export type FigureVersionFragmentFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'src' | 'uid'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
      }
  }

export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const UpdatePublicationContentDocument = gql`
  mutation updatePublicationContent($data: UpdatePublicationContentInput!) {
    updatePublicationContent(data: $data) {
      status
      content {
        elements {
          id
          uid
          __typename
          ... on ImageElement {
            isFullWidth
            author
            alt
            description
            figureVersion {
              ...FigureVersionFragment
            }
          }
          ... on TextElement {
            id
            uid
            content
          }
          ... on IFrameElement {
            src
          }
          ... on GalleryElement {
            id
            uid
          }
        }
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`
export type UpdatePublicationContentMutationFn = Apollo.MutationFunction<
  UpdatePublicationContentMutation,
  UpdatePublicationContentMutationVariables
>

/**
 * __useUpdatePublicationContentMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationContentMutation, { data, loading, error }] = useUpdatePublicationContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationContentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePublicationContentMutation, UpdatePublicationContentMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdatePublicationContentMutation, UpdatePublicationContentMutationVariables>(
    UpdatePublicationContentDocument,
    options
  )
}
export type UpdatePublicationContentMutationHookResult = ReturnType<typeof useUpdatePublicationContentMutation>
export type UpdatePublicationContentMutationResult = Apollo.MutationResult<UpdatePublicationContentMutation>
export type UpdatePublicationContentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationContentMutation,
  UpdatePublicationContentMutationVariables
>
export const GetFigureByUidDocument = gql`
  query getFigureByUid($figureUid: UUID!) {
    figure(uid: $figureUid) {
      ...Figure
    }
  }
  ${FigureFragmentDoc}
`

/**
 * __useGetFigureByUidQuery__
 *
 * To run a query within a React component, call `useGetFigureByUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFigureByUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFigureByUidQuery({
 *   variables: {
 *      figureUid: // value for 'figureUid'
 *   },
 * });
 */
export function useGetFigureByUidQuery(
  baseOptions: Apollo.QueryHookOptions<GetFigureByUidQuery, GetFigureByUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetFigureByUidQuery, GetFigureByUidQueryVariables>(GetFigureByUidDocument, options)
}
export function useGetFigureByUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFigureByUidQuery, GetFigureByUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetFigureByUidQuery, GetFigureByUidQueryVariables>(GetFigureByUidDocument, options)
}
export type GetFigureByUidQueryHookResult = ReturnType<typeof useGetFigureByUidQuery>
export type GetFigureByUidLazyQueryHookResult = ReturnType<typeof useGetFigureByUidLazyQuery>
export type GetFigureByUidQueryResult = Apollo.QueryResult<GetFigureByUidQuery, GetFigureByUidQueryVariables>
