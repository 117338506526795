export const IMAGE_CROPPER_CONSTS = {
  announceImageMsg: 'Анонсирующая картинка',
  errorMsg: 'Ошибка',
  getFromFileMsg: 'Взять из файла',
  imageTooltipMsg: 'Нажмите сюда для выбора фото',
  imageUrlLabel: 'Ссылка из фотобанка',
  incorrectLinkMsg: 'Некорректная ссылка',
  photoStoreHttp: 'http://ps.ura.news/',
  photoStoreHttps: 'https://ps.ura.news/',
  uploadPhotoMsg: 'Загрузить фото'
}
