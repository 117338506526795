import {
  useCreateRelatedLinkMutation,
  useDeleteRelatedLinkMutation,
  usePublishPublicationMutation,
  useUpdatePublicationExtAnnounceParamsMutation,
  useUpdatePublicationMainFieldsMutation,
  useUpdatePublicationMutation,
  useUpdatePublicationOverviewFigureMutation,
  useUpdatePublicationSocialImageOnlyMutation
} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {LinkType} from '@components/Publication/Form/Pickers/LinkPicker/LinkPicker'
import {PublicationFormTitles} from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitles'
import {SENTENCE_SEPARATOR_REG_EX} from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitles.consts'
import {PublicationInfo} from '@components/Publication/Form/PublicationInfo/PublicationInfo'
import {PublicationSendMenu} from '@components/Publication/Form/PublicationSendMenu/PublicationSendMenu'
import {UnpublishedNewsSignboard} from '@components/Publication/Form/UnpublishedNewsSignboard/UnpublishedNewsSignboard'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import Editor from '@components/TextEditor/Editor/Editor'
import {adaptQuillRequest} from '@components/TextEditor/Editor/QuillEditor/QuillEditor.adapters'
import {useUpdatePublicationContentMutation} from '@components/TextEditor/gql/TextEditor.generated'
import {
  ElementUnionInput,
  Figure,
  FigureVersion,
  Image,
  PublicationStatus,
  Region,
  SocialMediaImage,
  Story,
  UpdatePublicationExportInput,
  UpdatePublicationInput
} from '@graphql/types'
import {TypographReplacer} from '@hooks/typographReplacer'
import {useSetCheckingPhotoEditorMutation} from '@pages/BildCheckList/gql/BildCheckList.generated'
import {useKeycloak} from '@react-keycloak/web'
import {useStore} from '@stores/rootStoreContext'
import {compareOfTwoArraysWithPrimitives} from '@utils/compareOfTwoArraysWithPrimitives'
import {debounce} from 'lodash'
import {observer} from 'mobx-react-lite'
import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import type {UseFormReturn} from 'react-hook-form'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {toast} from 'react-toastify'
import {OverviewBlockCache} from 'types/OverviewBlockCache'
import {ExtAnnounceParams} from './ExtAnnounceParams'
import {FiguresCacheContext} from './FiguresCacheContext'
import {PublicationDebugInfo} from './PublicationDebugInfo/PublicationDebugInfo'
import {PUBLICATION_FORM_CONSTS} from './PublicationForm.consts'
import {
  PublicationFormContent,
  PublicationFormContentHeader,
  PublicationFormContentHeaderTitle,
  PublicationFormContentWrapper,
  PublicationFormWrapper
} from './PublicationForm.styles'
import {PublicationFormAnnounceImage} from './PublicationFormAnnounceImage/PublicationFormAnnounceImage'
import {PublicationFormOverviewBlocks} from './PublicationFormOverviewBlocks/PublicationFormOverviewBlocks'
import type {PublicationInputType} from './PublicationInputType'

/**
 * Общая форма для работы с публикацией(страница редактирования новости/статьи)
 * @returns
 */
const PublicationForm: FC = () => {
  const {publicationStore, editorStore, regionsWithOverviewBlocksStore} = useStore()
  const {
    publication,
    announceImageModalStore,
    checkingPhotoEditor,
    checkingPhotoEditorCache,
    rubricAndSubrubricStore,
    setPublicationLoading,
    setPublicationContentLoading,
    setPublicationWithPrevState,
    setCheckingPhotoEditor,
    setPublicationFormDirty
  } = publicationStore
  const {setImageModalLoading} = announceImageModalStore
  const {rubricId, rubricIdCache, subrubricId, subrubricIdCache, setRubricId, setSubrubricId} = rubricAndSubrubricStore
  const {editorRef, editorDirty, setEditorDirty} = editorStore
  const {overviewBlocksCache} = regionsWithOverviewBlocksStore
  const {figuresCache} = useContext(FiguresCacheContext)

  const [announceFigureCache, setAnnounceFigureCache] = useState<ImageBlotProps | undefined>()
  const [overviewBlockFigureCache, setOverviewBlockFigureCache] = useState<ImageBlotProps | undefined>()
  const [socialImageCache, setSocialImageCache] = useState<SocialMediaImage | undefined>()
  const [extAnnounceParamsCache, setExtAnnounceParamsCache] = useState<ExtAnnounceParams | undefined>()
  const [updatePublication, {loading: updatePubliationLoading}] = useUpdatePublicationMutation()
  const [publishPublication, {loading: publishLoading}] = usePublishPublicationMutation()
  const [updatePublicationMainFields, {loading: updatePublicationMainFieldsLoading}] =
    useUpdatePublicationMainFieldsMutation()
  const [setCheckingPhotoEditorAction, {loading: setCheckingPhotoEditorLoading}] = useSetCheckingPhotoEditorMutation()
  const [updatePublicationExtAnnounceParams, {loading: updatePublicationExtAnnounceParamsLoading}] =
    useUpdatePublicationExtAnnounceParamsMutation()
  const [updatePublicationOverviewFigure, {loading: updatePublicationOverviewFigureLoading}] =
    useUpdatePublicationOverviewFigureMutation()
  const [createRelatedLinkMutation, {loading: createRelatedLinkLoading}] = useCreateRelatedLinkMutation()
  const [deleteRelatedLinkMutation, {loading: deleteRelatedLinkLoading}] = useDeleteRelatedLinkMutation()
  const [updateContentElements, {loading: updateContentElementsLoading}] = useUpdatePublicationContentMutation()
  const [updateSocialImageOnly, {loading: updateSocialImageOnlyLoading}] = useUpdatePublicationSocialImageOnlyMutation()
  const {keycloak} = useKeycloak()
  const [mainFieldsEditorDirty, setMainFieldsEditorDirty] = useState(false)
  const [updatePublicationAnnounceFieldsLoading, setUpdatePublicationAnnounceFieldsLoading] = useState(false)
  const [defaultExtAnnounceParams, setDefaultExtAnnounceParams] = useState<ExtAnnounceParams>({
    ogTitle:
      publication?.ogTitle ||
      (publication?.title?.length ? publication?.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''),
    ogDescription:
      publication?.ogDescription ||
      (publication?.title?.length && publication?.title?.length > 1
        ? publication?.title.split(SENTENCE_SEPARATOR_REG_EX)[1]
        : '')
  } as ExtAnnounceParams)
  const publicationFormRef = useRef<HTMLFormElement>(null)

  const cantEditPublication = useMemo(
    () => publication?.status === 'PUBLISHED' && !keycloak.hasResourceRole('edit-published', 'publications'),

    [keycloak, publication?.status]
  )

  const [relatedLinks, setRelatedLinks] = useState<LinkType[]>()

  const defaultValues = useMemo<PublicationInputType>(
    () => ({
      uid: (publication?.uid as string) || '',
      title: (publication?.title as string) || '',
      subtitle: (publication?.subtitle as string) || '',
      yandexTitle: (publication?.yandexTitle as string) || '',
      yandexDzenTitle: publication?.yandexDzenTitle || '',
      seoTitle: (publication?.seoTitle as string) || '',
      subscriptionBlockUid: publication?.subscriptionBlock?.uid || '',
      storyUid: publication?.story?.uid || null,
      regionUids: publication?.regions?.map(v => v?.uid) || [],
      regions: publication?.regions || [],
      tagsUids: publication?.tags?.map(v => v?.uid) || [],
      collectionUids: publication?.collections?.map(v => v?.uid) || [],
      specProjectUrl: publication?.specProjectUrl || '',
      isInside: publication?.isInside || false,
      authors: publication.authors?.map(author => author.uid) || [],
      isCommentsDisabled: !(publication?.isCommentsEnabled as boolean),
      isAutomaticCommentsDisabled: publication?.isAutomaticCommentsDisabled as boolean,
      isImportant: publication?.typeSlug === 'articles' || publication?.isImportant === true ? true : false,
      publishedAt: publication.status === PublicationStatus.Published ? publication?.publishedAt : null,
      destinationExport: {
        destinationSlugs: (publication?.destinations?.map(v => v?.slug) as string[]) || [],
        yandexZenCategoryUids: (publication?.yandexZenCategories?.map(v => v?.id) as string[]) || []
      } as UpdatePublicationExportInput,
      imageUrl: '',
      relatedLinks: publication?.relatedLinks || [],
      yandexNewsVideos: publication?.yandexNewsVideos?.map(item => (item.title, item.uri, item.uid)) || [],
      overviewDescription: publication?.overviewDescription || '',
      overviewTitle: publication?.overviewTitle || publication.title || '',
      overviewFigure: publication?.overviewFigure,
      overviewFigureId: publication?.overviewFigure?.figure?.id || '',
      overviewFigureVersionId: publication?.overviewFigure?.id || '',
      cantEditPublication: cantEditPublication,
      elements: publication?.content?.elements || [],
      destinationIds: (publication?.destinations?.map(v => v?.id) as string[]) || [],
      keywords: publication?.keywords,
      announceImage: publication?.announceImage,
      ogTitle:
        publication?.ogTitle ||
        (publication?.title?.length ? publication?.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''),
      ogDescription: publication?.ogDescription || '',
      announceFigureVersionId: publication?.announceImage?.figureVersion?.id || '',
      badwords: publication.badwords || false,
      socialMediaFigureVersion: publication?.socialMediaImage?.socialMediaFigureVersion,
      alygnY: publication?.socialMediaImage?.socialMediaFigureVersion?.alignY || 0
    }),
    [publication, cantEditPublication]
  )

  const methodsForm: UseFormReturn<PublicationInputType> = useForm({
    defaultValues
  })

  const {control, handleSubmit} = methodsForm

  useEffect(() => {
    if (
      !updateSocialImageOnlyLoading &&
      !updatePubliationLoading &&
      !publishLoading &&
      !updatePublicationMainFieldsLoading &&
      !updatePublicationAnnounceFieldsLoading &&
      !updatePublicationExtAnnounceParamsLoading &&
      // !updatePublicationRegionsLoading &&
      // !updatePubliationRubricAndSubrubricLoading &&
      !setCheckingPhotoEditorLoading &&
      !createRelatedLinkLoading &&
      !deleteRelatedLinkLoading &&
      !updatePublicationOverviewFigureLoading
    ) {
      debounce(() => {
        // reset(defaultValues)
        setPublicationFormDirty(false)
        setMainFieldsEditorDirty(false)
      }, 300)
    }
  }, [
    defaultValues,
    updateSocialImageOnlyLoading,
    updatePubliationLoading,
    publishLoading,
    updatePublicationMainFieldsLoading,
    updatePublicationAnnounceFieldsLoading,
    updatePublicationExtAnnounceParamsLoading,
    // updatePublicationRegionsLoading,
    // updatePubliationRubricAndSubrubricLoading,
    setCheckingPhotoEditorLoading,
    createRelatedLinkLoading,
    deleteRelatedLinkLoading,
    updatePublicationOverviewFigureLoading
  ])

  const allFields = useWatch({control})

  /**
   * Отслеживание изменений основных параметров публикации
   */
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (
        // allFields?.announceImage?.isFullWidth !==
        //     defaultValues?.announceImage?.isFullWidth ||
        allFields?.title != defaultValues?.title ||
        allFields?.yandexTitle != defaultValues?.yandexTitle ||
        allFields?.yandexDzenTitle != defaultValues?.yandexDzenTitle ||
        allFields?.seoTitle != defaultValues?.seoTitle ||
        allFields?.subtitle != defaultValues?.subtitle ||
        allFields?.isCommentsDisabled !== defaultValues?.isCommentsDisabled ||
        allFields?.isAutomaticCommentsDisabled !== defaultValues?.isAutomaticCommentsDisabled ||
        allFields?.isImportant !== defaultValues?.isImportant ||
        allFields?.isInside !== defaultValues?.isInside ||
        allFields?.specProjectUrl != defaultValues?.specProjectUrl ||
        allFields?.keywords != defaultValues?.keywords ||
        allFields.storyUid != defaultValues.storyUid ||
        // TODO надо будет вернуть эту проверку
        // allFields.publishedAt != defaultValues.publishedAt ||
        allFields.overviewDescription != defaultValues.overviewDescription ||
        allFields.overviewTitle != defaultValues.overviewTitle ||
        checkingPhotoEditor !== checkingPhotoEditorCache ||
        // !compareOfTwoArraysWithPrimitives(
        //     allFields?.destinationIds || [],
        //     defaultValues?.destinationIds
        // ) ||
        // !compareOfTwoArraysWithPrimitives(
        //     allFields?.destinationExport?.destinationSlugs || [],
        //     (defaultValues?.destinationExport
        //         ?.destinationSlugs as string[]) || []
        // ) ||
        (defaultValues?.authors?.length &&
          defaultValues?.authors[0] &&
          !compareOfTwoArraysWithPrimitives(allFields?.authors || [], defaultValues?.authors || [])) ||
        // !compareOfTwoArraysWithPrimitives(
        //   allFields?.yandexNewsVideos?.map(item => (item.title, item.uri, item.uid)) || [],
        //   defaultValues?.yandexNewsVideos?.map(item => (item.title, item.uri, item.uid)) || []
        // ) ||
        !compareOfTwoArraysWithPrimitives(
          allFields?.destinationExport?.yandexZenCategoryUids || [],
          defaultValues?.destinationExport?.yandexZenCategoryUids || []
        ) ||
        // !compareOfTwoArraysWithPrimitives(allFields?.tagsUids || [], defaultValues?.tagsUids) ||
        !compareOfTwoArraysWithPrimitives(allFields?.collectionUids || [], defaultValues?.collectionUids)
      ) {
        setMainFieldsEditorDirty(true)
      } else {
        setMainFieldsEditorDirty(false)
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [
    allFields.authors,
    allFields.yandexNewsVideos,
    allFields.destinationExport,
    allFields.collectionUids,
    allFields.title,
    allFields.yandexTitle,
    allFields.yandexDzenTitle,
    allFields.seoTitle,
    allFields.subtitle,
    allFields.isCommentsDisabled,
    allFields.isAutomaticCommentsDisabled,
    allFields.isImportant,
    allFields.isInside,
    allFields.specProjectUrl,
    allFields.keywords,
    allFields.storyUid,
    allFields.publishedAt,
    allFields.overviewDescription,
    allFields.overviewTitle,
    defaultValues.authors,
    defaultValues.yandexNewsVideos,
    defaultValues.destinationExport,
    defaultValues.collectionUids,
    defaultValues.title,
    defaultValues.yandexTitle,
    defaultValues.yandexDzenTitle,
    defaultValues.seoTitle,
    defaultValues.subtitle,
    defaultValues.isCommentsDisabled,
    defaultValues.isAutomaticCommentsDisabled,
    defaultValues.isImportant,
    defaultValues.isInside,
    defaultValues.specProjectUrl,
    defaultValues.keywords,
    defaultValues.storyUid,
    defaultValues.publishedAt,
    defaultValues.overviewDescription,
    defaultValues.overviewTitle,
    defaultValues.ogTitle,
    defaultValues.ogDescription,
    checkingPhotoEditor,
    checkingPhotoEditorCache,
    publication.status
  ])

  useEffect(() => {
    if (
      mainFieldsEditorDirty ||
      announceFigureCache ||
      // allFields?.announceImage?.alt != defaultValues?.announceImage?.alt ||
      // allFields?.announceImage?.author != defaultValues?.announceImage?.author ||
      // allFields?.announceImage?.description != defaultValues?.announceImage?.description ||
      // allFields?.announceImage?.figureVersion?.id != defaultValues?.announceImage?.figureVersion?.id ||
      allFields.announceFigureVersionId != defaultValues.announceFigureVersionId ||
      rubricId != rubricIdCache ||
      subrubricId != subrubricIdCache ||
      // allFields?.ogTitle != defaultValues?.ogTitle ||
      // allFields?.ogDescription != defaultValues?.ogDescription ||
      allFields.publishedAt != defaultValues.publishedAt ||
      extAnnounceParamsCache ||
      allFields?.overviewFigureVersionId !== defaultValues?.overviewFigureVersionId ||
      !compareOfTwoArraysWithPrimitives(
        relatedLinks?.map(i => i.id) || [],
        defaultValues.relatedLinks?.map(i => i.id) || []
      ) ||
      !compareOfTwoArraysWithPrimitives(allFields?.regionUids || [], defaultValues?.regionUids) ||
      // (![PublicationStatus.Draft, PublicationStatus.Review].includes(publication.status) &&
      //   !compareTwoPublicationContent(defaultValues?.elements, allFields?.elements || [])) ||
      overviewBlocksCache.length > 0 ||
      figuresCache.length > 0
    ) {
      setPublicationFormDirty(true)
    } else {
      setPublicationFormDirty(false)
    }
  }, [
    mainFieldsEditorDirty,
    announceFigureCache,
    extAnnounceParamsCache,
    allFields?.announceImage,
    allFields.announceFigureVersionId,
    allFields.regionUids,
    allFields?.overviewFigureVersionId,
    allFields.publishedAt,
    defaultValues?.announceImage,
    defaultValues.announceFigureVersionId,
    defaultValues.regionUids,
    defaultValues?.ogTitle,
    defaultValues?.ogDescription,
    defaultValues?.overviewFigureVersionId,
    defaultValues.relatedLinks,
    defaultValues.publishedAt,
    overviewBlocksCache,
    rubricId,
    rubricIdCache,
    subrubricId,
    subrubricIdCache,
    relatedLinks
  ])

  const createRelatedLink = useCallback(
    (url, text) => {
      if (url) {
        createRelatedLinkMutation({
          variables: {
            data: {
              publicationId: publication?.id || '',
              url: url || '',
              text: text || ''
            }
          }
        }).then(data => {
          const newLinks = JSON.parse(JSON.stringify(defaultValues.relatedLinks))
          if (data.data?.createRelatedLink?.link) {
            newLinks.push(data.data?.createRelatedLink?.link)
            defaultValues.relatedLinks = newLinks

            setPublicationWithPrevState(prev => ({
              ...prev,
              relatedLinks: newLinks
            }))
          }
        })
      }
    },
    [publication?.id, defaultValues.relatedLinks, setPublicationWithPrevState]
  )

  const deleteRelatedLink = useCallback(
    linkId => {
      if (linkId) {
        deleteRelatedLinkMutation({
          variables: {
            data: {
              linkId: linkId || ''
            }
          }
        }).then(data => {
          const newLinks = JSON.parse(JSON.stringify(defaultValues.relatedLinks))

          if (data?.data?.deleteRelatedLink) {
            const resLinks = newLinks.filter(item => item.id !== linkId)
            defaultValues.relatedLinks = resLinks

            setPublicationWithPrevState(prev => ({
              ...prev,
              relatedLinks: resLinks
            }))
            setRelatedLinks(resLinks)
          }
        })
      }
    },
    [defaultValues?.relatedLinks, deleteRelatedLinkMutation, setPublicationWithPrevState]
  )

  const updateReleatedLinks = useCallback(() => {
    if (defaultValues?.relatedLinks?.length) {
      defaultValues?.relatedLinks?.forEach(ri => {
        if (!relatedLinks?.find(linkItem => linkItem.id === ri.id)) {
          deleteRelatedLink(ri.id || '')
        }
      })
    }
    if (relatedLinks?.length) {
      relatedLinks.forEach(linktItem => {
        if (!defaultValues.relatedLinks.find(ri => ri.id === linktItem.id)) {
          createRelatedLink(linktItem?.url || '', linktItem.text || '')
        }
      })
    }
  }, [relatedLinks, createRelatedLink, deleteRelatedLink, defaultValues.relatedLinks])

  /**
   * Сохранение основных параметров публикации в черновиках
   */
  useEffect(() => {
    const hasFormErrors = Object.keys(control._formState.errors).length > 0

    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      !hasFormErrors &&
      (allFields.title || allFields.yandexTitle || allFields.seoTitle || allFields.yandexDzenTitle) &&
      mainFieldsEditorDirty === true
    ) {
      const timer = setTimeout(async () => {
        const variablesData: UpdatePublicationInput = {
          publicationUid: publication.uid,
          title: allFields.title,
          yandexTitle: allFields.yandexTitle,
          yandexDzenTitle: allFields.yandexDzenTitle,
          subtitle: allFields.subtitle,
          isCommentsEnabled: !allFields.isCommentsDisabled,
          isAutomaticCommentsDisabled: allFields.isAutomaticCommentsDisabled,
          isImportant: allFields.isImportant,
          specProjectUrl: allFields.specProjectUrl || '',
          isInside: allFields.isInside,
          tagUids: allFields.tagsUids,
          storyUid: allFields.storyUid || null,
          collectionUids: allFields.collectionUids,
          subscriptionBlockUid: allFields.subscriptionBlockUid?.length ? allFields.subscriptionBlockUid : null,
          overviewTitle: allFields.overviewTitle,
          overviewDescription: allFields.overviewDescription,
          authors: allFields.authors || [],
          keywords: allFields.keywords
        }

        // if (defaultValues.yandexTitle !== allFields.yandexTitle) {
        //   variablesData['yandexTitle'] = allFields.yandexTitle
        // }

        if (allFields.seoTitle?.length) {
          variablesData.seoTitle = allFields.seoTitle
        }

        if (allFields.title !== defaultValues.title) {
          variablesData.overviewTitle = allFields.title || ''
          variablesData.yandexDzenTitle = allFields.title || ''
          // methodsForm.setValue('overviewTitle', allFields.title || '')
        }
        if (allFields.subtitle !== defaultValues.subtitle) {
          variablesData.overviewDescription = allFields.subtitle || ''
          // methodsForm.setValue('overviewDescription', allFields.subtitle || '')
        }

        // if (allFields.publishedAt) {
        //   variablesData['publishedAt'] = allFields.publishedAt
        // }

        /*
          Логика сохранения анонса для соц сетей:
          "Заголовок для соц. сетей" - текстовое поле с ограничением символов 100;
          Для новости: автоматически подставляет первое предложение из заголовка новости,
          Для статьи: автоматически подставляет все из заголовка статьи;
          Важно! Предзаполненное поле можно редактировать, что не влияет на основной заголовок публикации. 
          И если в основном заголовке публикации изменится текст, то он снова автоматически подтянется в "заголовок для соц. сетей", 
          заменив текущий текст.

          "Анонс для соц. сетей" - текстовое allFields.overviewTitleполе с ограничением символов 140;
          Для новости: автоматически подставляет второе предложение из заголовка новости (если второго предложения нет, то поле остается пустым),
          Для статей: поле автоматически заполняется из подзаголовка статьи;
          Важно! Предзаполненное поле можно редактировать, что не влияет на основной заголовок/подзаголовок публикации. 
          И если в основном заголовке/подзаголовке публикации изменится текст, то он снова автоматически подтянется в 
          "анонс для соц. сетей", заменив текущий текст.

          В опубликованной новости логика сохранения аналогичная.
        */

        let extAnnounceParamsChanged = false
        let newOgTitle = ''
        let newOgDescription = ''
        if (allFields.title !== defaultValues.title) {
          if (publication?.typeSlug === 'news') {
            newOgTitle = allFields.title?.length ? allFields.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''
            newOgDescription =
              (allFields?.title?.length || 0) > 1 ? allFields?.title?.split(SENTENCE_SEPARATOR_REG_EX)[1] || '' : ''
            extAnnounceParamsChanged = true
          } else {
            newOgTitle = allFields.title || ''
            newOgDescription = allFields.subtitle || ''
            extAnnounceParamsChanged = true
          }
        }

        if (allFields.subtitle !== defaultValues.subtitle && publication?.typeSlug === 'articles') {
          newOgDescription = allFields.subtitle || ''
          extAnnounceParamsChanged = true
        }

        if (extAnnounceParamsChanged) {
          setExtAnnounceParamsCache({
            ogTitle: newOgTitle,
            ogDescription: newOgDescription
          })
        }

        const updatePublicationData = await updatePublicationMainFields({
          variables: {
            data: variablesData
          }
        })

        if (!updatePublicationData?.errors?.length) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            title: updatePublicationData?.data?.updatePublication?.publication?.title,
            yandexTitle: updatePublicationData?.data?.updatePublication?.publication?.yandexTitle,
            yandexDzenTitle: updatePublicationData?.data?.updatePublication?.publication?.yandexDzenTitle,
            subtitle: updatePublicationData?.data?.updatePublication?.publication?.subtitle,
            seoTitle: updatePublicationData?.data?.updatePublication?.publication?.seoTitle,
            isAutomaticCommentsDisabled:
              updatePublicationData?.data?.updatePublication?.publication?.isAutomaticCommentsDisabled,
            isCommentsEnabled: updatePublicationData?.data?.updatePublication?.publication?.isCommentsEnabled,
            isImportant: updatePublicationData?.data?.updatePublication?.publication?.isImportant,
            specProjectUrl: updatePublicationData?.data?.updatePublication?.publication?.specProjectUrl,
            // @ts-ignore
            isInside: updatePublicationData?.data?.updatePublication?.publication?.isInside,
            // publishedAt: updatePublicationData?.data?.updatePublication?.publication?.publishedAt ? updatePublicationData?.data?.updatePublication?.publication?.publishedAt : null,
            // tagUids: updatePublicationData?.data?.updatePublication?.publication?.tagUids,
            // collectionUids: updatePublicationData?.data?.updatePublication?.publication?.collectionUids,
            story: updatePublicationData?.data?.updatePublication?.publication?.story
              ? ({uid: updatePublicationData?.data?.updatePublication?.publication?.story?.uid || ''} as Story)
              : null,
            // subscriptionBlockUid: updatePublicationData?.data?.updatePublication?.publication?.subscriptionBlockUid,
            overviewTitle: updatePublicationData?.data?.updatePublication?.publication?.overviewTitle,
            overviewDescription: updatePublicationData?.data?.updatePublication?.publication?.overviewDescription,
            authors: updatePublicationData?.data?.updatePublication?.publication?.authors?.map(a => a.uid) || [],
            // @ts-ignore
            keywords: updatePublicationData?.data?.updatePublication?.publication?.keywords
          }))

          methodsForm.setValue(
            'overviewTitle',
            updatePublicationData?.data?.updatePublication?.publication?.overviewTitle || ''
          )
          methodsForm.setValue(
            'yandexDzenTitle',
            updatePublicationData?.data?.updatePublication?.publication?.yandexDzenTitle || ''
          )
          defaultValues.title = updatePublicationData?.data?.updatePublication?.publication?.title || ''
          defaultValues.yandexTitle = updatePublicationData?.data?.updatePublication?.publication?.yandexTitle || ''
          defaultValues.yandexDzenTitle =
            updatePublicationData?.data?.updatePublication?.publication?.yandexDzenTitle || ''
          defaultValues.subtitle = updatePublicationData?.data?.updatePublication?.publication?.subtitle || ''
          defaultValues.seoTitle = updatePublicationData?.data?.updatePublication?.publication?.seoTitle || ''
          defaultValues.isCommentsDisabled =
            !updatePublicationData?.data?.updatePublication?.publication?.isCommentsEnabled
          defaultValues.isAutomaticCommentsDisabled =
            updatePublicationData?.data?.updatePublication?.publication?.isAutomaticCommentsDisabled || false
          defaultValues.isImportant = updatePublicationData?.data?.updatePublication?.publication?.isImportant || false
          defaultValues.specProjectUrl =
            updatePublicationData?.data?.updatePublication?.publication?.specProjectUrl || ''
          defaultValues.isInside = updatePublicationData?.data?.updatePublication?.publication?.isInside || false
          defaultValues.storyUid = updatePublicationData?.data?.updatePublication?.publication?.story?.uid || null
          defaultValues.overviewTitle = updatePublicationData?.data?.updatePublication?.publication?.overviewTitle || ''
          defaultValues.overviewDescription =
            updatePublicationData?.data?.updatePublication?.publication?.overviewDescription || ''
          defaultValues.authors =
            updatePublicationData?.data?.updatePublication?.publication?.authors?.map(a => a.uid) || []
          defaultValues.keywords = updatePublicationData?.data?.updatePublication?.publication?.keywords || ''
          // if (updatePublicationData?.data?.updatePublication?.publication?.publishedAt) {
          //   defaultValues.publishedAt = updatePublicationData?.data?.updatePublication?.publication?.publishedAt
          // }
        }
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [
    control._formState.errors,
    mainFieldsEditorDirty,
    publication.uid,
    allFields.title,
    allFields.yandexTitle,
    allFields.yandexDzenTitle,
    allFields.seoTitle,
    allFields.subtitle,
    allFields.isCommentsDisabled,
    allFields.isAutomaticCommentsDisabled,
    allFields.isImportant,
    allFields.specProjectUrl,
    allFields.overviewTitle,
    allFields.overviewDescription,
    allFields.overviewFigureId,
    allFields.overviewFigureVersionId,
    allFields.isInside,
    allFields.tagsUids,
    allFields.collectionUids,
    allFields.storyUid,
    allFields.subscriptionBlockUid,
    allFields.authors,
    allFields.keywords,
    // allFields.publishedAt,
    updatePublicationMainFields,
    setExtAnnounceParamsCache,
    setPublicationWithPrevState,
    publication.status,
    publication.typeSlug,
    defaultValues.authors,
    defaultValues.title,
    defaultValues.subtitle
  ])

  /**
   * Сохранение параметров для ссылок по теме в черновиках
   */
  useEffect(() => {
    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      defaultValues?.relatedLinks?.length !== relatedLinks?.length
    ) {
      const timer = setTimeout(async () => {
        updateReleatedLinks()
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [relatedLinks, defaultValues?.relatedLinks, publication.status, updateReleatedLinks])

  /**
   * Сохранение параметров для фото выноса на главную в черновиках
   */
  useEffect(() => {
    if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) && overviewBlockFigureCache) {
      const timer = setTimeout(async () => {
        const overviewFigureVersionId = overviewBlockFigureCache?.figureVersionId || allFields.overviewFigureVersionId
        const overviewFigureId = overviewBlockFigureCache?.figureId || allFields.overviewFigureId
        const variablesData = {
          publicationUid: publication.uid,
          overviewFigureVersionId,
          overviewFigureId
        }

        const updatePublicationData = await updatePublicationOverviewFigure({
          variables: {
            data: variablesData
          }
        })

        if (!updatePublicationData?.errors?.length) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            // @ts-ignore
            overviewFigure: updatePublicationData?.data?.updatePublication?.publication?.overviewFigure,
            overviewFigureId:
              updatePublicationData?.data?.updatePublication?.publication?.overviewFigure?.figure?.id || '',
            overviewFigureVersionId:
              updatePublicationData?.data?.updatePublication?.publication?.overviewFigure?.id || ''
          }))
        }
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [
    publication.status,
    publication.uid,
    overviewBlockFigureCache,
    setPublicationWithPrevState,
    allFields.overviewFigureVersionId,
    allFields.overviewFigureId,
    updatePublicationOverviewFigure
  ])

  /**
   * Сохранение параметров картинки для соц-сетей в черновиках
   */
  useEffect(() => {
    if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
      if (
        socialImageCache &&
        (socialImageCache?.socialMediaFigureVersion?.alignY || 0) != (defaultValues.alygnY || 0)
      ) {
        const timer = setTimeout(async () => {
          const variablesData = {
            publicationUid: publication.uid,
            socialMediaImage: {
              alignY: socialImageCache?.socialMediaFigureVersion?.alignY || defaultValues.alygnY || 0
            }
          }

          const updateAnnounceImageData = await updateSocialImageOnly({
            variables: {
              data: variablesData
            }
          })

          if (!updateAnnounceImageData?.errors?.length) {
            setPublicationWithPrevState(prev => ({
              ...prev,
              socialMediaImage: {
                // @ts-ignore
                alignY:
                  updateAnnounceImageData?.data?.updatePublication?.publication?.socialMediaImage
                    ?.socialMediaFigureVersion?.alignY
              }
            }))
          }

          setSocialImageCache(undefined)
        }, 1000)
        return () => clearTimeout(timer)
      }
    }
  }, [
    updateSocialImageOnly,
    setPublicationWithPrevState,
    publication.uid,
    publication.status,
    socialImageCache,
    allFields.alygnY,
    defaultValues.alygnY
  ])

  /**
   * Сохранение параметров ogTitle, ogDescription в черновиках
   */
  useEffect(() => {
    if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
      if (
        extAnnounceParamsCache &&
        (extAnnounceParamsCache?.ogTitle != defaultValues?.ogTitle ||
          extAnnounceParamsCache?.ogDescription != defaultValues?.ogDescription)
      ) {
        const timer = setTimeout(async () => {
          const variablesData = {
            publicationUid: publication.uid,
            ogTitle: extAnnounceParamsCache?.ogTitle || defaultValues?.ogTitle || '',
            ogDescription: extAnnounceParamsCache?.ogDescription || ''
          }

          const resultData = await updatePublicationExtAnnounceParams({
            variables: {
              data: variablesData
            }
          })

          if (!resultData?.errors?.length) {
            setExtAnnounceParamsCache(undefined)

            defaultValues.ogTitle = resultData?.data?.updatePublication?.publication?.ogTitle || ''
            defaultValues.ogDescription = resultData?.data?.updatePublication?.publication?.ogDescription || ''

            setPublicationWithPrevState(prev => ({
              ...prev,
              ogTitle: resultData?.data?.updatePublication?.publication?.ogTitle,
              ogDescription: resultData?.data?.updatePublication?.publication?.ogDescription
            }))

            setDefaultExtAnnounceParams({
              ogTitle: resultData?.data?.updatePublication?.publication?.ogTitle || '',
              ogDescription: resultData?.data?.updatePublication?.publication?.ogDescription || ''
            })
          }
        }, 1000)
        return () => clearTimeout(timer)
      }
    }
  }, [
    publication.uid,
    publication.status,
    defaultExtAnnounceParams,
    extAnnounceParamsCache,
    defaultValues.ogTitle,
    defaultValues.ogDescription,
    setPublicationWithPrevState,
    updatePublicationExtAnnounceParams
  ])

  const moveBlocks = useRef<(val: OverviewBlockCache[]) => void>(null)

  /**
   * Сохранение параметров публикации при нажатии на кнопку "Сохранить" или "Опубликовать"
   */
  const handleOnSubmit = handleSubmit(async data => {
    let quill
    let elements: ElementUnionInput[] = []
    if (editorRef.current !== null) {
      quill = editorRef.current.getEditor()
    }
    if (quill) {
      const quotesReplacer = new TypographReplacer()
      quotesReplacer.runTypograph(quill.container)
      elements = adaptQuillRequest({quillChildren: [...quill.root.children]})
    }

    const changeDestinationEvent = new CustomEvent('publicationDestinationChange', {detail: publication.uid})
    await window.dispatchEvent(changeDestinationEvent)
    const promises: Promise<any>[] = []

    promises.push(
      new Promise(async resolve => {
        const updateContentElementsResponse = await updateContentElements({
          variables: {
            data: {
              publicationUid: publication.uid,
              elements
            }
          }
        })
        resolve(updateContentElementsResponse)
      })
    )

    updateReleatedLinks()

    const savePublicationEvent = new CustomEvent('savePublicationEvent', {
      detail: publication,
      bubbles: true
    })
    window.dispatchEvent(savePublicationEvent)

    const variablesData: UpdatePublicationInput = {
      publicationUid: publication.uid,
      title: data.title,
      yandexTitle: data.yandexTitle,
      yandexDzenTitle: data.yandexDzenTitle,
      seoTitle: data.seoTitle,
      subtitle: data.subtitle,
      isCommentsEnabled: !data.isCommentsDisabled,
      isAutomaticCommentsDisabled: data.isAutomaticCommentsDisabled,
      isImportant: data.isImportant,
      specProjectUrl: data.specProjectUrl || '',
      regionUids: data.regionUids,
      overviewTitle: data.overviewTitle || '',
      overviewDescription: data.overviewDescription || '',
      isInside: data.isInside,
      tagUids: data.tagsUids,
      collectionUids: data.collectionUids,
      subscriptionBlockUid: data.subscriptionBlockUid?.length ? allFields.subscriptionBlockUid : null,
      storyUid: allFields.storyUid?.length ? allFields.storyUid : null,
      rubricId: rubricIdCache?.length ? rubricIdCache : null,
      subrubricId: subrubricIdCache?.length ? subrubricIdCache : null,
      authors: data.authors?.filter(author => author),
      keywords: data.keywords,
      announceImage: {
        figureVersionId:
          announceFigureCache?.figureVersionId ||
          allFields.announceImage?.figureVersion?.id ||
          publication?.announceImage?.figureVersion?.id,
        isFullWidth:
          announceFigureCache?.isFullWidth ??
          allFields.announceImage?.isFullWidth ??
          publication?.announceImage?.isFullWidth ??
          false,
        description:
          announceFigureCache?.description ||
          allFields.announceImage?.description ||
          publication?.announceImage?.description ||
          '',
        author:
          announceFigureCache?.author || allFields.announceImage?.author || publication?.announceImage?.author || '',
        alt: announceFigureCache?.alt || allFields.announceImage?.alt || publication?.announceImage?.alt || ''
      },
      socialMediaImage: {
        alignY: socialImageCache?.socialMediaFigureVersion
          ? socialImageCache?.socialMediaFigureVersion.alignY
          : allFields.alygnY || 0
      },
      overviewFigureVersionId:
        overviewBlockFigureCache?.figureVersionId ||
        allFields.overviewFigureVersionId ||
        publication?.overviewFigure?.id,
      overviewFigureId:
        overviewBlockFigureCache?.figureId || allFields.overviewFigureId || publication?.overviewFigureId
    }

    /*
    Логика сохранения анонса для соц сетей указана в черновиках
    */
    if (data.title !== defaultValues.title) {
      if (publication?.typeSlug === 'news') {
        variablesData.ogTitle = data.title?.length ? data.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''
        variablesData.ogDescription = data.title?.length > 1 ? data.title.split(SENTENCE_SEPARATOR_REG_EX)[1] : ''
      } else {
        variablesData.ogTitle = data.title
        variablesData.ogDescription = data.subtitle
      }
    } else if (extAnnounceParamsCache) {
      variablesData.ogTitle = extAnnounceParamsCache?.ogTitle || allFields.ogTitle || publication?.ogTitle
      variablesData.ogDescription =
        extAnnounceParamsCache?.ogDescription || allFields.ogDescription || publication?.ogDescription
    }

    promises.push(
      new Promise(async resolve => {
        const updatePublicationResponse = await updatePublication({
          variables: {
            data: variablesData
          }
        })

        resolve(updatePublicationResponse)
      })
    )

    await Promise.all(await promises)

    setRubricId(rubricIdCache)
    setSubrubricId(subrubricIdCache)

    if (publication.status !== PublicationStatus.Unpublished) {
      const publishPublicationData = await publishPublication({
        variables: {
          data: {
            publicationUid: publication?.uid,
            publishedAt: allFields.publishedAt
          }
        }
      })

      if (!publishPublicationData?.errors?.length) {
        methodsForm.setValue('publishedAt', publishPublicationData.data?.publishPublication?.publication?.publishedAt)
        methodsForm.setValue(
          'overviewTitle',
          publishPublicationData.data?.publishPublication?.publication?.overviewTitle || ''
        )
        methodsForm.setValue(
          'yandexDzenTitle',
          publishPublicationData.data?.publishPublication?.publication?.yandexDzenTitle || ''
        )
        methodsForm.setValue('ogTitle', publishPublicationData.data?.publishPublication?.publication?.ogTitle || '')
        methodsForm.setValue(
          'ogDescription',
          publishPublicationData.data?.publishPublication?.publication?.ogDescription || ''
        )
        figuresCache.length = 0
        setEditorDirty(false)
        setMainFieldsEditorDirty(false)
        setPublicationFormDirty(false)
        setAnnounceFigureCache(undefined)
        setExtAnnounceParamsCache(undefined)

        if (publishPublicationData?.data?.publishPublication?.publication)
          setPublicationWithPrevState(prev => ({
            ...prev,
            canonicalUrl: publishPublicationData.data?.publishPublication?.publication?.canonicalUrl || '',
            title: publishPublicationData?.data?.publishPublication?.publication?.title,
            yandexTitle: publishPublicationData?.data?.publishPublication?.publication?.yandexTitle,
            yandexDzenTitle: publishPublicationData?.data?.publishPublication?.publication?.yandexDzenTitle,
            subtitle: publishPublicationData?.data?.publishPublication?.publication?.subtitle,
            seoTitle: publishPublicationData?.data?.publishPublication?.publication?.seoTitle,
            isCommentsEnabled: publishPublicationData?.data?.publishPublication?.publication?.isCommentsEnabled,
            isImportant: publishPublicationData?.data?.publishPublication?.publication?.isImportant,
            specProjectUrl: publishPublicationData?.data?.publishPublication?.publication?.specProjectUrl,
            // @ts-ignore
            isInside: publishPublicationData?.data?.publishPublication?.publication?.isInside,
            // tagUids: publishPublicationData?.data?.publishPublication?.publication?.tagUids,
            story: publishPublicationData?.data?.publishPublication?.publication?.story
              ? ({uid: publishPublicationData?.data?.publishPublication?.publication?.story?.uid || ''} as Story)
              : null,
            overviewTitle: publishPublicationData?.data?.publishPublication?.publication?.overviewTitle,
            overviewDescription: publishPublicationData?.data?.publishPublication?.publication?.overviewDescription,
            authors: publishPublicationData?.data?.publishPublication?.publication?.authors?.map(a => a.uid) || [],
            // @ts-ignore
            keywords: publishPublicationData?.data?.publishPublication?.publication?.keywords,
            socialMediaFigureVersion:
              publishPublicationData?.data?.publishPublication?.publication?.socialMediaImage?.socialMediaFigureVersion,
            yandexNewsVideos:
              publishPublicationData?.data?.publishPublication?.publication?.yandexNewsVideos?.map(
                item => (item.title, item.uri, item.uid)
              ) || [],
            subscriptionBlockUid:
              publishPublicationData?.data?.publishPublication?.publication?.subscriptionBlock?.uid || '',
            storyUid: publishPublicationData?.data?.publishPublication?.publication?.story?.uid || null,
            regionUids: publishPublicationData?.data?.publishPublication?.publication?.regions?.map(v => v?.uid) || [],
            regions: (publishPublicationData?.data?.publishPublication?.publication?.regions as Region[]) || [],
            tagsUids: publishPublicationData?.data?.publishPublication?.publication?.tags?.map(v => v?.uid) || [],
            collectionUids:
              publishPublicationData?.data?.publishPublication?.publication?.collections?.map(v => v?.uid) || [],
            isCommentsDisabled: !(publishPublicationData?.data?.publishPublication?.publication
              ?.isCommentsEnabled as boolean),
            publishedAt:
              publishPublicationData?.data?.publishPublication?.publication?.status === PublicationStatus.Published
                ? publishPublicationData?.data?.publishPublication?.publication?.publishedAt
                : null,
            destinationExport: {
              destinationSlugs:
                (publishPublicationData?.data?.publishPublication?.publication?.destinations?.map(
                  v => v?.slug
                ) as string[]) || [],
              yandexZenCategoryUids:
                (publishPublicationData?.data?.publishPublication?.publication?.yandexZenCategories?.map(
                  v => v?.id
                ) as string[]) || []
            } as UpdatePublicationExportInput,
            imageUrl: '',
            relatedLinks: publishPublicationData?.data?.publishPublication?.publication?.relatedLinks || [],
            // overviewFigure: publishPublicationData?.data?.publishPublication?.publication?.overviewFigure as Figure,
            overviewFigureId: publishPublicationData?.data?.publishPublication?.publication?.overviewFigureId,
            overviewFigureVersionId:
              publishPublicationData?.data?.publishPublication?.publication?.overviewFigureVersionId,
            cantEditPublication: cantEditPublication,
            // elements: publishPublicationData?.data?.publishPublication?.publication?.content?.elements || [],
            destinationIds:
              (publishPublicationData?.data?.publishPublication?.publication?.destinations?.map(
                v => v?.id
              ) as string[]) || [],
            announceImage: {
              alt: publishPublicationData?.data?.publishPublication?.publication?.announceImage.alt || '',
              author: publishPublicationData?.data?.publishPublication?.publication?.announceImage.author || '',
              description:
                publishPublicationData?.data?.publishPublication?.publication?.announceImage.description || '',
              figureVersion: {
                id:
                  publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.id || '',
                uid:
                  publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.uid ||
                  '',
                hasWatermark:
                  publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion
                    ?.hasWatermark || false,
                hasDiagonalWatermark:
                  publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion
                    ?.hasDiagonalWatermark || false,
                primaryProcessedImage: {
                  url:
                    publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion
                      ?.primaryProcessedImage?.url || ''
                } as Image,
                crop: {
                  left: publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion
                    ?.crop?.left,
                  top: publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.crop
                    ?.top,
                  width:
                    publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.crop
                      ?.width,
                  height:
                    publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.crop
                      ?.height
                },
                figure: {
                  id:
                    publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.figure
                      ?.id || '',
                  src:
                    publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.figure
                      ?.src || '',
                  uid: publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion
                    ?.figure?.uid,
                  cropperPreview: {
                    url:
                      publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion
                        ?.figure?.cropperPreview?.url || ''
                  } as Image
                } as Figure
              } as FigureVersion,
              id: publishPublicationData?.data?.publishPublication?.publication?.announceImage.id || '',
              isFullWidth:
                publishPublicationData?.data?.publishPublication?.publication?.announceImage.isFullWidth || false
            },
            ogTitle:
              publishPublicationData?.data?.publishPublication?.publication?.ogTitle ||
              (publishPublicationData?.data?.publishPublication?.publication?.title?.length
                ? publishPublicationData?.data?.publishPublication?.publication?.title.split(
                    SENTENCE_SEPARATOR_REG_EX
                  )[0]
                : ''),
            ogDescription: publishPublicationData?.data?.publishPublication?.publication?.ogDescription || '',
            announceFigureVersionId:
              publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.id || '',
            badwords: publishPublicationData?.data?.publishPublication?.publication?.badwords || false,
            alygnY:
              publishPublicationData?.data?.publishPublication?.publication?.socialMediaImage?.socialMediaFigureVersion
                ?.alignY || 0
          }))

        defaultValues.title = publishPublicationData?.data?.publishPublication?.publication?.title || ''
        defaultValues.yandexTitle = publishPublicationData?.data?.publishPublication?.publication?.yandexTitle || ''
        defaultValues.yandexDzenTitle =
          publishPublicationData?.data?.publishPublication?.publication?.yandexDzenTitle || ''
        defaultValues.subtitle = publishPublicationData?.data?.publishPublication?.publication?.subtitle || ''
        defaultValues.seoTitle = publishPublicationData?.data?.publishPublication?.publication?.seoTitle || ''
        defaultValues.ogTitle = publishPublicationData?.data?.publishPublication?.publication?.ogTitle || ''
        defaultValues.ogDescription = publishPublicationData?.data?.publishPublication?.publication?.ogDescription || ''
        defaultValues.announceFigureVersionId =
          publishPublicationData?.data?.publishPublication?.publication?.announceImage?.figureVersion?.id || ''
        defaultValues.isCommentsDisabled =
          !publishPublicationData?.data?.publishPublication?.publication?.isCommentsEnabled
        defaultValues.isImportant = publishPublicationData?.data?.publishPublication?.publication?.isImportant || false
        defaultValues.specProjectUrl =
          publishPublicationData?.data?.publishPublication?.publication?.specProjectUrl || ''
        defaultValues.isInside = publishPublicationData?.data?.publishPublication?.publication?.isInside || false
        defaultValues.storyUid = publishPublicationData?.data?.publishPublication?.publication?.story?.uid || null
        defaultValues.overviewTitle = publishPublicationData?.data?.publishPublication?.publication?.overviewTitle || ''
        defaultValues.overviewDescription =
          publishPublicationData?.data?.publishPublication?.publication?.overviewDescription || ''
        defaultValues.authors =
          publishPublicationData?.data?.publishPublication?.publication?.authors?.map(a => a.uid) || []
        defaultValues.keywords = publishPublicationData?.data?.publishPublication?.publication?.keywords || ''
        defaultValues.overviewFigureId = publishPublicationData?.data?.publishPublication?.publication?.overviewFigureId
        defaultValues.overviewFigureVersionId =
          publishPublicationData?.data?.publishPublication?.publication?.overviewFigureVersionId
        defaultValues.announceImage = publishPublicationData?.data?.publishPublication?.publication?.announceImage
        defaultValues.yandexNewsVideos =
          publishPublicationData?.data?.publishPublication?.publication?.yandexNewsVideos || []

        defaultValues.subscriptionBlockUid =
          publishPublicationData?.data?.publishPublication?.publication?.subscriptionBlock?.uid || ''
        defaultValues.regionUids =
          publishPublicationData?.data?.publishPublication?.publication?.regions?.map(v => v?.uid) || []
        // defaultValues.regions = publishPublicationData?.data?.publishPublication?.publication?.regions || [],
        defaultValues.tagsUids =
          publishPublicationData?.data?.publishPublication?.publication?.tags?.map(v => v?.uid) || []
        defaultValues.collectionUids =
          publishPublicationData?.data?.publishPublication?.publication?.collections?.map(v => v?.uid) || []
        defaultValues.publishedAt = publishPublicationData?.data?.publishPublication?.publication?.publishedAt
        defaultValues.destinationExport = {
          destinationSlugs: (publication?.destinations?.map(v => v?.slug) as string[]) || [],
          yandexZenCategoryUids: (publication?.yandexZenCategories?.map(v => v?.id) as string[]) || []
        } as UpdatePublicationExportInput
        defaultValues.relatedLinks = publishPublicationData?.data?.publishPublication?.publication?.relatedLinks || []
        // (defaultValues.overviewFigure =
        //   publishPublicationData?.data?.publishPublication?.publication?.overviewFigure),
        // defaultValues.elements = publishPublicationData?.data?.publishPublication?.publication?.content?.elements || [],
        defaultValues.destinationIds =
          (publishPublicationData?.data?.publishPublication?.publication?.destinations?.map(v => v?.id) as string[]) ||
          []
        defaultValues.socialMediaFigureVersion =
          publishPublicationData?.data?.publishPublication?.publication?.socialMediaImage?.socialMediaFigureVersion
        defaultValues.alygnY =
          publishPublicationData?.data?.publishPublication?.publication?.socialMediaImage?.socialMediaFigureVersion
            ?.alignY || 0
      }
    }

    if (overviewBlocksCache.length) {
      moveBlocks?.current && moveBlocks?.current(overviewBlocksCache)
    }
    if (
      keycloak.hasResourceRole('check-bildeditor', 'publications') &&
      checkingPhotoEditor !== checkingPhotoEditorCache
    ) {
      const checkingPhotoEditorData = {
        publicationUid: publication.uid,
        status: checkingPhotoEditorCache || false
      }

      const checkingPhotoEditorResult = await setCheckingPhotoEditorAction({
        variables: {
          data: checkingPhotoEditorData
        }
      })

      if (!checkingPhotoEditorResult?.errors?.length) {
        setPublicationWithPrevState(prev => ({
          ...prev,
          checkingPhotoEditor: checkingPhotoEditorData.status
        }))

        setCheckingPhotoEditor(checkingPhotoEditorData.status || false)
      }
    }
    toast.success(PUBLICATION_FORM_CONSTS.savedMsg, {
      autoClose: false,
      hideProgressBar: true,
      containerId: 'success'
    })
  })

  useEffect(() => {
    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      overviewBlocksCache.length &&
      (allFields.title || allFields.yandexTitle || allFields.seoTitle || allFields.yandexDzenTitle)
    ) {
      const timer = setTimeout(async () => {
        moveBlocks?.current && moveBlocks?.current(overviewBlocksCache)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [
    overviewBlocksCache,
    publication.status,
    allFields.title,
    allFields.yandexTitle,
    allFields.yandexDzenTitle,
    allFields.seoTitle,
    editorDirty
  ])

  useEffect(() => {
    setImageModalLoading(
      updatePublicationMainFieldsLoading ||
        updatePublicationAnnounceFieldsLoading ||
        updatePublicationExtAnnounceParamsLoading ||
        updateSocialImageOnlyLoading
    )
  }, [
    updatePublicationMainFieldsLoading,
    updatePublicationAnnounceFieldsLoading,
    updatePublicationExtAnnounceParamsLoading,
    updateSocialImageOnlyLoading,
    setImageModalLoading
  ])

  useEffect(() => {
    setPublicationLoading(
      updateContentElementsLoading ||
        updateSocialImageOnlyLoading ||
        updatePubliationLoading ||
        publishLoading ||
        createRelatedLinkLoading ||
        deleteRelatedLinkLoading ||
        updatePublicationMainFieldsLoading ||
        updatePublicationAnnounceFieldsLoading ||
        // updatePubliationRubricAndSubrubricLoading ||
        setCheckingPhotoEditorLoading ||
        updatePublicationOverviewFigureLoading
    )
    setPublicationContentLoading(updateContentElementsLoading)
  }, [
    updateContentElementsLoading,
    updateSocialImageOnlyLoading,
    updatePubliationLoading,
    publishLoading,
    createRelatedLinkLoading,
    deleteRelatedLinkLoading,
    updatePublicationMainFieldsLoading,
    updatePublicationAnnounceFieldsLoading,
    // updatePubliationRubricAndSubrubricLoading ||
    setCheckingPhotoEditorLoading,
    updatePublicationOverviewFigureLoading,
    setPublicationLoading,
    setPublicationContentLoading
  ])

  return (
    <FormProvider {...methodsForm}>
      <PublicationFormWrapper noValidate onSubmit={handleOnSubmit}>
        <PublicationFormContent>
          <PublicationFormContentHeader>
            <PublicationFormContentHeaderTitle>
              <span>{PUBLICATION_FORM_CONSTS.editMsg}&nbsp;</span>
              {publication?.typeSlug === 'news' ? (
                <span>{PUBLICATION_FORM_CONSTS.newsMsg}</span>
              ) : (
                <span>{PUBLICATION_FORM_CONSTS.articleMsg}</span>
              )}
            </PublicationFormContentHeaderTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <PublicationSendMenu />
              <PublicationInfo
                publicationId={publication?.id}
                publicationAuthorUid={(publication?.authors?.length && publication?.authors[0].uid) || ''}
                publicationCreatedAt={publication?.createdAt || ''}
              />
              <PublicationDebugInfo
                id={publication?.id || ''}
                oldAdminUrl={publication?.oldAdminUrl || ''}
                url={publication?.url || ''}
              />
            </div>
          </PublicationFormContentHeader>
          {publication.status === 'UNPUBLISHED' && <UnpublishedNewsSignboard redirectUrl={publication?.redirectUrl} />}
          <PublicationFormContentWrapper ref={publicationFormRef}>
            <PublicationFormAnnounceImage
              publicationTitle={allFields?.title || ''}
              // publicationSubtitle={allFields?.subtitle || ''}
              defaultExtAnnounceParams={defaultExtAnnounceParams}
              announceFigureCache={announceFigureCache}
              extAnnounceParamsCache={extAnnounceParamsCache}
              socialImageCache={socialImageCache}
              setAnnounceFigureCache={setAnnounceFigureCache}
              setExtAnnounceParamsCache={setExtAnnounceParamsCache}
              setSocialImageCache={setSocialImageCache}
              setLoading={setUpdatePublicationAnnounceFieldsLoading}
            />
            <PublicationFormTitles methodsForm={methodsForm} cantEditPublication={cantEditPublication} />
          </PublicationFormContentWrapper>
          <Editor methodsForm={methodsForm} publicationHeaderHeight={publicationFormRef?.current?.offsetHeight || 0} />
        </PublicationFormContent>
        <PublicationFormOverviewBlocks
          moveBlocks={moveBlocks}
          setRelatedLinks={setRelatedLinks}
          setOverviewBlockFigureCache={setOverviewBlockFigureCache}
        />
      </PublicationFormWrapper>
    </FormProvider>
  )
}

export default observer(PublicationForm)
