import styled from 'styled-components'

export const DebugInfoWrapper = styled.div`
    margin-left: 32px;
    float: right;
`

export const EditorFooterWrapper = styled.div`
    /* width: calc(100% - 167px); */
    width: 100%;
    max-width: 1266px;
    display: flex;
    flex-direction: row;
`
