import {createTheme} from '@mui/material/styles'
import {blueColor, lightblueColor, lightgrayColor, redColor, secondTextColor, whiteColor} from './vars'

export const theme = createTheme({
  palette: {
    primary: {
      light: lightblueColor,
      main: blueColor,
      dark: blueColor,
      contrastText: whiteColor
    },
    secondary: {
      light: lightgrayColor,
      main: secondTextColor,
      dark: secondTextColor,
      contrastText: whiteColor
    },
    error: {
      main: redColor
    }
  },
  typography: {
    body1: {
      fontSize: '14px',
      fontWeight: 400
    },
    h1: {
      fontSize: '20px',
      fontWeight: 400
    },
    h2: {
      fontSize: '16px',
      fontWeight: 500
    },
    subtitle1: {
      fontSize: '12px',
      fontWeight: 400,
      color: secondTextColor
    },
    subtitle2: {
      fontSize: '14px',
      color: secondTextColor,
      fontWeight: 400,
      lineHeight: '16px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: '1.1876em',
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '16px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // height: '1.1876em',
          padding: 0,
          paddingLeft: 0,
          paddingTop: '8.5px',
          paddingBottom: '8.5px',
          marginTop: 0
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: 'auto',
          '.MuiOutlinedInput-root': {
            padding: 0,
            marginTop: 0,
            paddingTop: '1px',
            paddingBottom: '1px',
            paddingLeft: '10px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#fff',
          padding: '8.5px 14px',
          paddingTop: '8.5px',
          paddingBottom: '8.5px',
          '&.Mui-focused': {
            borderWidth: 1
          },
          '&.MuiOutlinedInput-adornedEnd': {
            paddingRight: '14px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          color: secondTextColor,
          margin: 0,
          marginLeft: 0,
          marginRight: 0
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'normal',
          fontSize: '14px',
          color: secondTextColor
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: lightgrayColor
        }
      }
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: 'p'
      }
    },
    MuiMenu: {
      disableScrollLock: true
    },
    MuiFormControl: {
      variant: 'outlined',
      size: 'small',
      styleOverrides: {}
    },
    MuiTextField: {
      variant: 'outlined',
      size: 'small'
    },
    MuiButton: {
      color: 'primary'
    },
    MuiSwitch: {
      color: 'primary'
    },
    MuiLinearProgress: {
      color: 'primary'
    }
  }
})
