/**
 * Функция удаления элемента из разметки по идентификатору
 * @param id идентификатор
 * @returns void
 */
export const deleteElementById = (id: string): void => {
  const currentElement = document.getElementById(id) as HTMLElement

  currentElement?.remove()
}
