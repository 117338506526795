import React, {FC, useCallback, useState} from 'react'
import {StyledFormControl} from '../../UI/styled'
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'
import Clear from '@mui/icons-material/Clear'

type KeyWordsProps = {
  defaultWords: string
  changeKeyWords: (value: string) => void
  width?: string
}

const KeyWords: FC<KeyWordsProps> = ({defaultWords, changeKeyWords, width}) => {
  const titleLabel = 'Ключевые слова'

  const [words, setKeyWords] = useState(defaultWords)

  const handleChange = useCallback(
    val => {
      setKeyWords(val)
      changeKeyWords(val)
    },
    [changeKeyWords]
  )

  return (
    <FormControl
      fullWidth
      style={{
        marginBottom: '10px',
        width: '100%',
        maxWidth: width || '1266px'
      }}
    >
      <StyledFormControl
        style={{
          width: '100%',
          maxWidth: width || '1266px'
        }}
      >
        <InputLabel>{titleLabel}</InputLabel>
        <OutlinedInput
          inputProps={{maxLength: 9999}}
          label={titleLabel}
          onChange={e => handleChange(e.target.value)}
          value={words}
          type='text'
          multiline={true}
          endAdornment={
            <InputAdornment position='end'>
              {words && (
                <IconButton edge='end' size='small' onClick={() => handleChange('')}>
                  <Clear fontSize='small' />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </StyledFormControl>
    </FormControl>
  )
}

export default KeyWords
