import {FC, useCallback} from 'react'
import {debounce} from 'lodash'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import KeyWords from '../../KeyWords/KeyWords'
import {EditorFooterWrapper} from './TextEditorFooter.styles'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {UseFormReturn} from 'react-hook-form'

type EditorFooterProps = {
  methodsForm: UseFormReturn<PublicationInputType>
  keywords: string
}

const EditorFooter: FC<EditorFooterProps> = ({methodsForm, keywords}: EditorFooterProps) => {
  const updatePublicationKeywordsAction = useCallback(value => {
    methodsForm.setValue('keywords', value)
  }, [])

  const changeKeyWords = useCallback(
    debounce(value => {
      updatePublicationKeywordsAction(value)
    }, 500),
    []
  )

  return (
    <EditorFooterWrapper
      style={{
        margin: 'auto',
        width: 'calc(100% - 20px)'
      }}
    >
      <KeyWords defaultWords={keywords} changeKeyWords={changeKeyWords} />
    </EditorFooterWrapper>
  )
}

export default observer(EditorFooter)
