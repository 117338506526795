import {ApolloProvider} from '@apollo/client'
import {LinearProgress} from '@mui/material'
import {useKeycloak} from '@react-keycloak/web'
import React, {FC} from 'react'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import {SnackbarOrigin, SnackbarProvider} from 'notistack'
import ruLocale from 'date-fns/locale/ru'
import {ToastContainer, Zoom} from 'react-toastify'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {ToastErrorCollapser} from '@components/UI/ToastifyComponents/ToastErrorCollapser/ToastErrorCollapser'
import rootStore from '@stores/rootStore'
import {RootStoreContext} from '@stores/rootStoreContext'
import useApollo from '@graphql/utils/apollo-client'
import {theme} from './@theme/theme'
import AppRouter from './routes/AppRouter'

type SettingsSnackbar = {
  autoHideDuration: number
  maxSnack: number
  anchorOrigin: SnackbarOrigin
}
/**
 * Основной модуль приложения
 * @module App
 */
const App: FC<any> = () => {
  const {initialized} = useKeycloak()
  const client = useApollo()

  const settingsSnackbar: SettingsSnackbar = {
    autoHideDuration: 2000,
    maxSnack: 3,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    }
  }

  if (!initialized) {
    return (
      <ThemeProvider theme={theme}>
        <LinearProgress color='primary' />
      </ThemeProvider>
    )
  }

  return (
    <React.StrictMode>
      <RootStoreContext.Provider value={rootStore}>
        <ApolloProvider client={client}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider {...settingsSnackbar} dense>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                  <ToastContainer
                    position='bottom-right'
                    containerId={'error'}
                    enableMultiContainer
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={true}
                    rtl={false}
                    pauseOnHover={true}
                    pauseOnFocusLoss={true}
                    closeOnClick={false}
                    limit={4}
                    transition={Zoom}
                    draggable={false}
                    theme='colored'
                  />
                  <ToastContainer
                    position='bottom-left'
                    containerId={'success'}
                    enableMultiContainer
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={true}
                    rtl={false}
                    pauseOnHover={true}
                    pauseOnFocusLoss={true}
                    closeOnClick={true}
                    limit={4}
                    transition={Zoom}
                    draggable={false}
                    theme='colored'
                  />
                  <ToastErrorCollapser />
                  <AppRouter />
                </LocalizationProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ApolloProvider>
      </RootStoreContext.Provider>
    </React.StrictMode>
  )
}

export default App
