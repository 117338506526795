import {CircularProgress, colors} from '@mui/material'
import {FC, MouseEvent, useCallback, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {MenuItem, Typography} from '@mui/material'
import {Quill} from 'react-quill'
import {useSnackbar} from 'notistack'
import {useStore} from '@stores/rootStoreContext'
import Tooltip from '@components/UI/Tooltip/Tooltip'
import NnIcon from '@components/UI/Icons/NnIcon'
import ChevronIcon from '@components/UI/Icons/Chevron'
import {StyledToggleButton} from '../Toolbar.styles'
import {StyledMenu} from './ToolbarNn.styles'
import {NnItem} from './NnItem'
import {TOOLBAR_SIZE_CONSTS} from './ToolbarNn.consts'
import type {RangeStatic} from 'quill'

const Delta = Quill.import('delta')

type ToolbarVideoProps = {
  editorRef: any
}

const ToolbarNn: FC<ToolbarVideoProps> = ({editorRef}) => {
  const [selectedSize, setSelectedSize] = useState<NnItem>({
    color: '',
    size: '',
    title: 'N'
  })
  const {publicationStore} = useStore()
  const {publication} = publicationStore

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const snackbar = useSnackbar()
  const open = Boolean(anchorElement)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }
  const [isLoading, setIsLoading] = useState(false)

  const setSizeCallback = useCallback(
    (data: any) => {
      let quill: Quill | undefined
      let range: RangeStatic | undefined

      if (editorRef.current !== null) {
        quill = editorRef.current.getEditor()
        range = quill?.getSelection(true)
      }

      if (quill && range) {
        // Получаем значение по ключу text
        const text = data.text

        if (text === 'Нет похожих публикаций') {
          snackbar.enqueueSnackbar('Нет похожих публикаций', {variant: 'error', autoHideDuration: 10000})
        } else {
          // Получаем текущую длину контента
          const currentLength = quill.getLength()

          // Получаем последний символ
          const lastChar = quill.getText(currentLength)

          // Определяем позицию вставки текста
          let insertPosition
          if (lastChar === '\n') {
            insertPosition = currentLength - 4
          } else {
            insertPosition = currentLength + 1
          }

          // Вставляем текст в указанную позицию
          quill.insertText(insertPosition, text.toString(), 'user')

          // Устанавливаем курсор в конец вставленного текста
          const insertedLength = text.length
          quill.setSelection(insertPosition + insertedLength, 0)

          // Вставка слова с ссылкой
          const word = data.word
          const url = data.url
          const index = text.indexOf(word) - 1

          if (index !== -1) {
            // Вставляем слово с ссылкой в нужную позицию
            quill.insertText(insertPosition + index, word, {link: url}, 'user')
            // Удаляем старое слово (после вставки, чтобы не сдвигать текст)
            quill.deleteText(insertPosition + index + word.length, word.length, 'user')
          }
        }

        setIsLoading(false)
      }
    },
    [editorRef, snackbar]
  )

  const setSize = useCallback(
    async (_size: string, _color: string, title: string) => {
      let quill: Quill | undefined
      let range: RangeStatic | undefined

      if (editorRef.current !== null) {
        quill = editorRef.current.getEditor()
        range = quill?.getSelection(true)
      }

      if (quill && range) {
        let delta = quill.getContents()
        let requestText = ''

        setIsLoading(true)

        delta.forEach(item => {
          if (typeof item.insert === 'string') {
            requestText += item.insert
            requestText += ' '
          }
        })

        if (title === 'Написать бэк') {
          if (requestText.length <= 1) {
            snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          } else {
            const regions = publicationStore.publication?.regions?.map(v => v?.uid)
            const rubric = publicationStore.publication?.rubric?.title
            const subrubric = publicationStore.publication?.subrubric?.title

            const data = JSON.stringify({text: requestText, regions, rubric, subrubric})

            if (rubric === undefined) {
              snackbar.enqueueSnackbar('Сначала выберите рубрику', {variant: 'info', autoHideDuration: 10000})
              setIsLoading(false)
            } else {
              try {
                const getNNTextData = await fetch(`https://a.uran.news/back/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    params: data
                  })
                })

                const getNNTextDataJson = await getNNTextData.json()

                setSizeCallback(getNNTextDataJson)
              } catch (error) {
                snackbar.enqueueSnackbar(`Error: ${error}`, {variant: 'error', autoHideDuration: 10000})
                setIsLoading(false)
              }
            }
          }
        }

        // Вставляет сгенерированный текст после отправленного текста
        if (title === 'Сгенерировать текст по теме') {
          if (requestText.length <= 1) {
            snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000});
            setIsLoading(false)
          } else {
            const data = JSON.stringify({ text: requestText })

            try {
              const generateText = await fetch(`https://a.uran.news/generate/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  params: data
                })
              })
              const generateTextJson = await generateText.json();
              const regExp = /\n\s*\n+/g
              const text = generateTextJson.text.replace(regExp, '\n')
              quill.setContents(
                new Delta({
                  ops: [
                    {
                      insert: text
                    }
                  ]
                }),
                'user'
              );
              setIsLoading(false);
            } catch (error) {
              snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000});
              setIsLoading(false);
            }
          }
        }

        // Переписывает выделенный текст
        const { index, length } = range;
        delta = quill.getContents(index, length);
        const selectingText = delta.ops?.reduce((string, op) => string + op.insert, '');

        if (title === 'Переписать выделенный текст') {
          if (selectingText.length === 0) {
            snackbar.enqueueSnackbar('Сначала выделите текст', {variant: 'info', autoHideDuration: 10000});
            setIsLoading(false);
          } else {
            const data = JSON.stringify({ text: selectingText });

            try {
              const rewriteText = await fetch(`https://a.uran.news/rewrite/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  params: data
                })
              });

              const rewriteTextJson = await rewriteText.json();
              const text = rewriteTextJson.text;

              quill.updateContents(
                index !== 0
                  ? new Delta({
                      ops: [
                        {
                          retain: index
                        },
                        {
                          delete: length
                        },
                        {
                          insert: text
                        }
                      ]
                    })
                  : new Delta({
                      ops: [
                        {
                          delete: length
                        },
                        {
                          insert: text
                        }
                      ]
                    }),
                'user'
              );
              setIsLoading(false);
            } catch (error) {
              snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000});
              setIsLoading(false);
            }
          }
        }


        if (title === 'Проверить иноагентов') {
          if (requestText.length <= 1) {
            snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          } else {
            fetch(`https://a.uran.news/ner/?params=${requestText.replace(/\n/g, ' ')}`)
              .then(res => {
                if (res.ok) {
                  return res.json()
                } else {
                  throw new Error(res.statusText)
                }
              })
              .then(response => {
                if (response['INOAGENT'].length === 0) {
                  snackbar.enqueueSnackbar('Найдено: 0 упоминаний иноагентов', {
                    variant: 'success',
                    autoHideDuration: 10000
                  })
                } else {
                  response['INOAGENT'].forEach(inoagent => {
                    //const index = requestText.indexOf(inoagent)
                    //const length = inoagent.length
                    //const highlight = {
                    //  insert: inoagent,
                    //  attributes: {
                    //    color: 'red' // Выделяем красным
                    //  }
                    //}
                    //insert => {
                    //  quill.updateContents(
                    //   index !== 0
                    //      ? new Delta({
                    //          ops: [
                    //            {
                    //              retain: index
                    //            },
                    //            {
                    //              delete: length
                    //            },
                    //            {
                    //              insert: highlight
                    //            }
                    //          ]
                    //        })
                    //      : new Delta({
                    //          ops: [
                    //            {
                    //              delete: length
                    //            },
                    //            {
                    //              insert: highlight
                    //            }
                    //          ]
                    //        }),
                    //    'user'
                    //  )
                    //}
                    //quill.formatText(index, length, {
                    //  bold: true,
                    //  color: '#d50000'
                    //})
                  })

                  snackbar.enqueueSnackbar(
                    'Кол-во пометок меньше, чем кол-во иноагентов на' +
                      ' ' +
                      response['INOAGENT'].length +
                      ' ' +
                      ' ' +
                      ' ' +
                      response['INOAGENT'].toString(),
                    {variant: 'error', autoHideDuration: 10000}
                  )
                }
                setIsLoading(false)
              })
              .catch(error => {
                snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
                setIsLoading(false)
              })
          }
        }
        if (title === 'Рерайт') {
          if (selectingText?.length !== 0) {
            //setIsLoading(true)
            fetch(`https://a.uran.news/test/?params=${selectingText}`)
              .then(res => {
                if (res.ok) {
                  return res.text()
                } else {
                  throw new Error(res.statusText)
                }
              })
              .then(insert => {
                quill.updateContents(
                  index !== 0
                    ? new Delta({
                        ops: [
                          {
                            retain: index
                          },
                          {
                            delete: length
                          },
                          {
                            insert
                          }
                        ]
                      })
                    : new Delta({
                        ops: [
                          {
                            delete: length
                          },
                          {
                            insert
                          }
                        ]
                      }),
                  'user'
                )
                setIsLoading(false)
              })
              .catch(error => {
                snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
                setIsLoading(false)
              })
          } else {
            snackbar.enqueueSnackbar('Сначала выделите текст', {variant: 'info', autoHideDuration: 10000})
            setIsLoading(false)
          }
        }
        quill.setSelection(range.index + 1, 0)
        quill.getSelection(true)
        quill.focus()
      }
    },
    [editorRef, setSizeCallback, snackbar]
  )

  const selectSize = useCallback(
    (sizeItem: NnItem) => {
      if (sizeItem) {
        setSelectedSize(sizeItem)
        setSize(sizeItem.size || '', sizeItem.color || '', sizeItem.title)
      }
      handleClose()
    },
    [setSize, setSelectedSize]
  )

  return (
    <>
      <Tooltip title={isLoading ? TOOLBAR_SIZE_CONSTS.textLoading : TOOLBAR_SIZE_CONSTS.textSize}>
        <StyledToggleButton
          id='basic-button'
          value=''
          aria-controls={open ? 'text-size-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress disableShrink color='secondary' size='1rem' /> : <NnIcon></NnIcon>}
          <Typography
            color='textPrimary'
            variant={selectedSize?.title === 'T' ? 'h1' : 'inherit'}
            style={{
              display: 'contents',
              verticalAlign: 'middle'
            }}
          >
            <span>&nbsp;</span>
            <ChevronIcon></ChevronIcon>
          </Typography>
        </StyledToggleButton>
      </Tooltip>
      <StyledMenu
        id='text-size-menu'
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {TOOLBAR_SIZE_CONSTS.sizeItems.map(item => (
          <MenuItem onClick={() => selectSize(item)} key={item.title} value={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
export default observer(ToolbarNn)
