import styled from 'styled-components'
import {Typography} from '@mui/material'

export const DetailsRowSelects = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`

export const BottomText = styled(Typography)`
  margin: 8px 0 0 12px;
  text-align: center;
`
