import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import React, {FC, ReactNode, useCallback} from 'react'
import styled, {css} from 'styled-components'
import CloseIcon from '../Icons/CloseIcon'
import WarningIcon from '../Icons/WarningIcon'
import ControlTooltip from './../../UI/Tooltip/ControlTooltip'

export type ModalMode = 'modal' | 'alert' | 'dialog'
export const MODAL_MODE: ModalMode = 'modal'
export const ALERT_MODE: ModalMode = 'alert'
export const DIALOG_MODE: ModalMode = 'dialog'

export type ModalStyle = 'default' | 'warning' | 'check' | 'error'
export const DEFAULT_STYLE: ModalStyle = 'default'
export const WARNING_STYLE: ModalStyle = 'warning'
export const CHECK_STYLE: ModalStyle = 'check'
export const ERROR_STYLE: ModalStyle = 'error'

const BUTTONS_MIN_WIDTH = 100

export interface SimpleModalStyleOptions {
  buttonsMinWidth?: number
  okButtonMinWidth?: number
  yesButtonMinWidth?: number
  noButtonMinWidth?: number
}

export interface SimpleModalProps extends DialogProps {
  /**
   * Текст заголовка
   */
  title?: string
  /**
   * Сообщение с дефолтным стилем
   */
  text?: string
  /**
   * Если сервер присылает сообщение в html формате
   */
  html?: string
  /**
   * Стиль модалки
   */
  variant?: ModalStyle
  /**
   * Режим работы, без кнопок, с одной "Ок", с двумя "Yes" и "No"
   */
  mode?: ModalMode
  /**
   * Показывает загрузчик и блокирует кнопки
   */
  loading?: boolean
  /**
   * Кастомный текст на кнопке Ok
   */
  okLabel?: string
  /**
   * Кастомный текст на кнопке Yes
   */
  yesLabel?: string
  /**
   * Кастомный текст на кнопке No
   */
  noLabel?: string
  /**
   * Деактивирует кнопку "Ок"
   */
  okButtonDisabled?: boolean
  /**
   * Деактивирует кнопку "Yes"
   */
  yesButtonDisabled?: boolean
  /**
   * Деактивирует кнопку "No"
   */
  noButtonDisabled?: boolean
  /**
   * Показывать иконку Закрыть (крестик)
   */
  showCloseButton?: boolean
  /**
   * Дополнительные параметры для внешнего оформления
   */
  stylesOptions?: SimpleModalStyleOptions
  /**
   * Клик по кнопке в режиме "alert"
   */
  onOk?: () => void
  /**
   * Клик по кнопке Yes в режиме "dialog"
   */
  onYes?: () => void
  /**
   * Клик по кнопке No в режиме "dialog"
   */
  onNo?: () => void
  /**
   * Добавляет кнопу в header заголовка
   */
  headerEndIcon?: ReactNode
  /**
   * Текст подсказки на кнопке при условии disable
   */
  okButtonDisabledTooltip?: string
}

const StyledDialogContent = styled(DialogContent)<{
  loading?: boolean
}>`
  position: relative;
  ${props =>
    props.loading &&
    css`
      pointer-events: none;
      user-select: none;
    `}
`

const ShortButton = styled(Button)`
  width: calc(32px * 2.4);

  &.large {
    width: calc(40px * 2.4);
  }

  &.small {
    width: calc(24px * 2.4);
  }
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const StyledDialogActions = styled(DialogActions)`
  //display: flex;
  justify-content: center;
  align-items: stretch;
`

const ModalTitle: FC<SimpleModalProps> = ({id, title, variant, showCloseButton = true, onClose, headerEndIcon}) => {
  const onCloseClick = useCallback(() => onClose && onClose({}, 'escapeKeyDown'), [onClose])

  if (!title) return null
  let icon: ReactNode = null
  let textColor = 'rgba(0, 0, 0, 0.87)'
  switch (variant) {
    case WARNING_STYLE:
      icon = <WarningIcon color='error' />
      textColor = '#FFC64C'
      break
    case ERROR_STYLE:
      icon = <WarningIcon color='error' />
      textColor = '#FF6268'
      break
    case CHECK_STYLE:
      textColor = '#00DA71'
      break
  }

  return (
    <DialogTitle id={`${id}_title`}>
      <Box color={textColor} display='flex' alignItems='center' justifyContent='center'>
        {icon && (
          <Box display='inline-flex' mr={3}>
            {icon}
          </Box>
        )}
        <Typography variant='h6' data-test='title_text'>
          {title}
        </Typography>
        {headerEndIcon && headerEndIcon}
        {showCloseButton && onClose && (
          <StyledCloseButton onClick={onCloseClick}>
            <CloseIcon color='secondary' />
          </StyledCloseButton>
        )}
      </Box>
    </DialogTitle>
  )
}

const ModalContent: FC<SimpleModalProps> = ({id, text, html, children, loading}) => {
  if (html) {
    return <StyledDialogContent id={`${id}_content`} dangerouslySetInnerHTML={{__html: html}} loading={loading} />
  }
  return (
    <StyledDialogContent id={`${id}_content`} loading={loading}>
      {text && <DialogContentText>{text}</DialogContentText>}
      {children}
    </StyledDialogContent>
  )
}

const ModalActions: FC<SimpleModalProps> = ({
  id,
  mode,
  okLabel,
  noLabel,
  yesLabel,
  onOk,
  onNo,
  onYes,
  okButtonDisabled,
  yesButtonDisabled,
  noButtonDisabled,
  stylesOptions,
  okButtonDisabledTooltip,
  loading
}) => {
  const getButtonMinWidth = useCallback(
    (type: 'ok' | 'yes' | 'no') => {
      switch (type) {
        case 'ok':
          return stylesOptions?.okButtonMinWidth || stylesOptions?.buttonsMinWidth || BUTTONS_MIN_WIDTH
        case 'yes':
          return stylesOptions?.yesButtonMinWidth || stylesOptions?.buttonsMinWidth || BUTTONS_MIN_WIDTH
        case 'no':
          return stylesOptions?.noButtonMinWidth || stylesOptions?.buttonsMinWidth || BUTTONS_MIN_WIDTH
      }
    },
    [stylesOptions]
  )

  const renderButton = useCallback(
    (
      short: boolean,
      id: string,
      variant: 'contained' | 'outlined',
      label: string,
      disabled: boolean,
      minWidth: number,
      onClick: () => void,
      okButtonDisabledTooltip?: string,
      loading = false
    ) => {
      const ButtonComponent = short ? ShortButton : Button
      return (
        <ControlTooltip
          title=''
          disabledTitle={okButtonDisabledTooltip || ''}
          disabled={disabled}
          color={'default'}
          withoutTooltipIfActive={true}
        >
          <ButtonComponent
            id={id}
            variant={variant}
            color='primary'
            size='large'
            disabled={disabled}
            onClick={onClick}
            style={{
              textTransform: 'none'
            }}
          >
            {loading && <CircularProgress size='1rem' />}
            {label}
          </ButtonComponent>
        </ControlTooltip>
      )
    },
    []
  )

  if (mode === MODAL_MODE) return null

  return (
    <StyledDialogActions>
      {noLabel != '' &&
        renderButton(
          false,
          `${id}_no_button`,
          'outlined',
          noLabel || 'Отменить',
          noButtonDisabled || false,
          getButtonMinWidth('no'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onNo ? onNo : () => {}
        )}
      {mode === ALERT_MODE &&
        renderButton(
          !okLabel,
          `${id}_ok_button`,
          'contained',
          okLabel || 'Хорошо',
          okButtonDisabled || false,
          getButtonMinWidth('ok'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onOk ? onOk : () => {},
          okButtonDisabledTooltip || '',
          loading
        )}
      {mode === DIALOG_MODE &&
        renderButton(
          !yesLabel,
          `${id}_yes_button`,
          'contained',
          yesLabel || 'Хорошо',
          yesButtonDisabled || false,
          getButtonMinWidth('yes'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onYes ? onYes : () => {},
          '',
          loading
        )}
    </StyledDialogActions>
  )
}

const StyledDialog: FC<SimpleModalProps> = styled(Dialog).attrs(() => ({
  classes: {
    paper: 'paper'
  }
}))`
  & .paper {
    padding-bottom: 15px;
    @media screen and (max-width: 960px) {
      margin: 0;
      width: 100%;
    }
  }

  & .paper > div {
    @media screen and (max-width: 960px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

const SimpleModal: FC<SimpleModalProps> = props => {
  const {id, title, text, html, mode, ...other} = props

  return (
    <StyledDialog
      id={id}
      aria-labelledby={title ? `${id}_title` : undefined}
      aria-describedby={text || html ? `${id}_content` : undefined}
      fullWidth
      {...other}
    >
      <ModalTitle {...props} />
      <Box mt={!title ? 8 : 0} />
      <ModalContent {...props} />
      <Box mb={mode === MODAL_MODE ? 8 : 0} />
      <ModalActions {...props} />
    </StyledDialog>
  )
}

SimpleModal.displayName = 'SimpleModal'

SimpleModal.defaultProps = {
  id: 'modal',
  mode: ALERT_MODE,
  variant: DEFAULT_STYLE,
  loading: false,
  okButtonDisabled: false,
  yesButtonDisabled: false,
  noButtonDisabled: false,
  stylesOptions: {},
  onOk: () => null,
  onYes: () => null,
  onNo: () => null
}

export default SimpleModal
