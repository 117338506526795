import React, {FC, useEffect, useState} from 'react'
import {Autocomplete, Checkbox, CircularProgress, Paper, Popper, TextField} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

type CustomAutocompleteProps = {
  label: string
  multiply?: boolean
  options: any[]
  value?: any
  onChange: (newValue: any) => void
  refetch: (search: string | null) => any
  fetchMore: () => any
  loading: boolean
  width: string
  disabled?: boolean
}

const PopperComponent = props => {
  return (
    <Popper {...props} style={{width: 350}} placement='bottom'>
      {props.children}
    </Popper>
  )
}

const PaperComponent = props => {
  return <Paper {...props} style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}} />
}

const RenderedTags = styled.div`
  position: absolute;
  left: 11px;
`

const CustomAutocomplete: FC<CustomAutocompleteProps> = ({
  label,
  multiply = false,
  options,
  onChange,
  value,
  refetch,
  fetchMore,
  loading,
  width,
  disabled
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(value || null)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (search !== selectedValue?.title) {
      refetch(search === selectedValue?.title ? null : search || null)
    }
  }, [refetch, search, selectedValue])

  const onScrollBottom = (target: any) => {
    const diff = Math.round(target.scrollHeight - (target?.scrollTop || 0))
    const isScrollBottom = diff <= target.clientHeight
    if (isScrollBottom) {
      fetchMore()
    }
  }

  const handleOnChange = newValue => {
    setSelectedValue(newValue)
    onChange(newValue)
  }

  return (
    <Autocomplete
      disabled={disabled}
      style={{width: width}}
      multiple={multiply}
      limitTags={1}
      open={open}
      clearIcon={<CloseIcon fontSize='small' />}
      value={selectedValue}
      onChange={(e, newValue) => handleOnChange(newValue)}
      inputValue={search}
      onInputChange={(e, val) => setSearch(val)}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionDisabled={option => option?.disabled}
      isOptionEqualToValue={(option, value) => option?.uid === value?.uid}
      getOptionLabel={option => option?.title}
      noOptionsText={'Значений не найдено'}
      options={options}
      loading={loading}
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
      ListboxProps={{
        onScroll: ({target}) => onScrollBottom(target),
        style: {height: '100%'}
      }}
      disableCloseOnSelect={multiply}
      renderTags={
        multiply
          ? selected => {
              if (selected.length === 1 && !search) {
                return <RenderedTags>{selected[0].title}</RenderedTags>
              } else if (!search) {
                return <RenderedTags>Выбрано {selected.length}</RenderedTags>
              }
            }
          : undefined
      }
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          {multiply && (
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
              style={{marginRight: 8}}
              checked={selected}
              color='primary'
            />
          )}
          {option?.title}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default CustomAutocomplete
