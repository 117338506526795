import styled from 'styled-components'
import {blueColor, errorFieldColor} from '../../../../@theme/vars'

export const PublicationFormTitlesWrapper = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: left;
    width: calc(100% - 270px);
    flex-direction: column;

    @media screen and (max-width: 768px) {
        margin-left: 0px;
        width: 100%;
    }
`

export const SeoTitleExampleWrapper = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 15px;
    margin-top: -5px;
    display: flex;
    align-items: center;
    color: ${blueColor};
`

export const YandexTitleDoesNotMatchWrapper = styled.div`
    color: ${errorFieldColor};
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-top: -12px;
    margin-bottom: 10px;
`
