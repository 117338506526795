import React, {FC} from 'react'
import {IconButton, Menu, Paper} from '@mui/material'
import styled from 'styled-components'
import {DebugInfoIcon} from '../../UI/Icons/DebugInfo'
import CopyToClipboard from '../../UI/Buttons/CopyToClipboard/CopyToClipboard'
import {mainTextColor, grayColor} from '../../../@theme/vars'

type DebugInfoProps = {
  id: string
  oldAdminUrl: string
  url: string
}

const Link = styled.a`
  display: block;
  margin-top: 8px;
  font-size: 18px;
  color: ${mainTextColor};
  text-decoration: underline;
`

const DebugInfo: FC<DebugInfoProps> = ({id, oldAdminUrl, url}) => {
  const titleLabel = 'Информация для отладки'
  const oldAdminUrlTitle = 'Публикация в старой админке'
  const urlTitle = 'Публикация на сайте'

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openLink = url => {
    window.open(url, '_blank')
  }

  return (
    <Paper elevation={0}>
      <IconButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          float: 'right',
          padding: '3px'
        }}
        disabled={false}
      >
        <DebugInfoIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Paper
          style={{
            padding: '12px',
            fontFamily: 'Roboto',
            fontStyle: 'SemiBold',
            fontSize: '18px',
            lineHeight: '24px'
          }}
        >
          <div
            style={{
              color: grayColor,
              fontWeight: 500,
              marginBottom: '8px'
            }}
          >
            {titleLabel}
          </div>
          <div>
            <span>ID&nbsp;-&nbsp;{id}&nbsp;</span>
            <span
              style={{
                float: 'right'
              }}
            >
              <CopyToClipboard data={id} />
            </span>
          </div>
          <Link onClick={() => openLink(oldAdminUrl)}>{oldAdminUrlTitle}</Link>
          <Link onClick={() => openLink(url)}>{urlTitle}</Link>
        </Paper>
      </Menu>
    </Paper>
  )
}

export default DebugInfo
