import React from 'react'
import styled, {css} from 'styled-components'
import {TooltipProps} from './Tooltip'
import {Tooltip} from '@mui/material'

export const StyledTooltip = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef(({className, trigger, ...props}: TooltipProps, ref) => (
    <Tooltip
      classes={{popper: className, tooltip: 'tooltip'}}
      disableFocusListener={trigger === 'hoverOrTouch' || trigger === 'hover'}
      disableHoverListener={trigger === 'focusOrTouch'}
      disableTouchListener={trigger === 'hover'}
      className={className}
      {...props}
      ref={ref as any}
    />
  ))
)`
  & .tooltip {
    max-width: 500px;
    background-color: white;
    padding: 16px;
    color: black;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    font-size: 14px;
    z-index: 1400;
  }
  ${({interactive}) =>
    interactive &&
    css`
      pointer-events: all;
    `};

  ${(props: TooltipProps) =>
    (props.placement === 'bottom-start' || props.placement === 'top-start') &&
    `
      & .tooltip::before {
        left: 16px !important;
        right: auto !important;
      }
    `}

  ${props =>
    (props.placement === 'bottom-end' || props.placement === 'top-end') &&
    `
        & .tooltip::before {
          left: auto !important;
          right: 16px !important;
        }
      `}

  ${({color}) =>
    color &&
    css`
      .tooltip {
        background-color: ${color};
        color: white;
        border: 1px solid #c4c4c4;
        box-shadow: 0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%),
          0px 1px 3px 0px rgb(255 255 255 / 12%);
        font-size: 14px;
      }
    `}
`
StyledTooltip.displayName = 'StyledTooltip'
