export const ANOUNCE_IMAGE_MODAL_CONSTS = {
  altLabelMsg: 'Описание Alt',
  announceImageMsg: 'Анонсирующая картинка',
  authorLabelMsg: 'Автор',
  authorPhotoLabelMsg: 'Автор фото',
  characterLimitReachedMsg: 'Достигнут лимит по кол-ву символов',
  descriptionLabelMsg: 'Описание',
  descriptionPhotoLabelMsg: 'Описание фото',
  diagonalWatermarkLabelMsg: 'Ватермарка на всё фото',
  fieldIsRequiredMsg: 'Поле обязательно к заполнению',
  fillRequiredFields: 'Заполните обязательные поля',
  fullWidthLabelMsg: 'На всю ширину',
  maxAltLength: 777,
  maxDescriptionLength: 300,
  maxLength: 250,
  maxOgDescriptionLength: 140,
  maxOgTitleLength: 100,
  setAnotherPhotoLabelMsg: 'Установить другое фото',
  changeCropLabelMsg: 'Изменить кроп',
  warningMsg: 'Внимание',
  closeConfirmationText: 'Внесенные изменения не сохранятся. Вы уверены, что хотите закрыть окно?',
  closeConfirmBtn: 'Да, закрыть',
  makeChangeMsg: 'Нет изменений для сохранения'
}
