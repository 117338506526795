import React, {FC, useEffect, useState} from 'react'
import {Author} from '@graphql/types'
import {useAuthorsPublicationQuery} from '../../Publication/Form/gql/PublicationEditor.generated'
import {Autocomplete, TextField} from '@mui/material'
import {useStaffQuery} from '../../Staff/gql/Staff.generated'
import CloseIcon from '@mui/icons-material/Close'

type AuthorAliasPickerProp = {
  author?: Author | null
  handleChangeAuthor: (newAuthor: any) => void
  selectedAuthorUid?: string
  disabled?: boolean
  isGetUsers?: boolean
  width?: string
  marginBottom?: string
  label?: string
}

const AuthorPicker: FC<AuthorAliasPickerProp> = ({
  author = null,
  handleChangeAuthor,
  selectedAuthorUid = '',
  disabled,
  isGetUsers = false,
  width = '315px',
  marginBottom,
  label
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(
    author?.uid
      ? {
          uid: author?.uid,
          title: `${author?.lastName} ${author?.firstName}`,
          disabled: selectedAuthorUid === author?.uid
        }
      : null
  )
  const [search, setSearch] = useState('')
  const {data: dataAuthors} = useAuthorsPublicationQuery()
  const {data: dataUsers} = useStaffQuery()
  const [fieldWidth, setFieldWidth] = useState(width || '315px')

  useEffect(() => {
    setSelectedValue(
      author?.uid
        ? {
            uid: author?.uid,
            title: author['title'] ? author['title'] : `${author?.lastName} ${author?.firstName}`,
            disabled: selectedAuthorUid === author?.uid
          }
        : null
    )
  }, [author])

  const handleOnChange = newValue => {
    setSelectedValue(newValue)
    handleChangeAuthor(newValue)
  }

  useEffect(() => {
    setFieldWidth(width)
  }, [width])

  return (
    <Autocomplete
      value={selectedValue}
      inputValue={search}
      id={`author-picker`}
      options={
        (isGetUsers === false &&
          dataAuthors &&
          dataAuthors?.authors?.map(author => ({
            uid: author?.uid,
            title: `${author?.lastName} ${author?.firstName}`,
            disabled: selectedAuthorUid === author?.uid
          }))) ||
        (isGetUsers === true &&
          dataUsers &&
          dataUsers.staff?.edges?.map(author => ({
            uid: author?.node?.uid,
            title: `${author?.node?.lastname} ${author?.node?.firstname}`,
            disabled: selectedAuthorUid === author?.node?.uid
          }))) ||
        []
      }
      clearIcon={<CloseIcon fontSize='small' />}
      getOptionDisabled={option => option?.disabled}
      isOptionEqualToValue={(option, value) => option?.uid === value?.uid}
      noOptionsText={'Значений не найдено'}
      getOptionLabel={option => option?.title}
      style={{width: fieldWidth, marginBottom: marginBottom || '0px', padding: 0}}
      onChange={(e, newValue) => handleOnChange(newValue)}
      onInputChange={(e, val) => setSearch(val)}
      renderInput={params => <TextField {...params} label={label || 'Автор'} variant='outlined' />}
      disabled={disabled}
    />
  )
}

export default AuthorPicker
